import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Menu from './Menu';
import GlobalSearch from './GlobalSearch';
import ShoppingCartSummary from './ShoppingCartSummary';
import { LOGIN_URL, LOGOUT_URL } from '../constants/Constants';
import { Link } from 'react-router-dom';
import { BsNutFill } from "react-icons/bs";
import { VscGlobe } from "react-icons/vsc";
import { FaUserCircle, FaMapMarkerAlt } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import useOnclickOutside from "react-cool-onclickoutside";
import {setfcAndJessionId, setJessionId} from '../helpers/CommonScripts';
import Language from './Languages'
import {useOutsideClick} from "../helpers/utils";
import { isRolePendingUser } from '../helpers/Helpers';
let timer;
function Navbar(props) {
	const label = props.labels;
	const categoryMenu = props.categoryMenu;
	const userSettingResponse = props.userSetting;
	const headerInformationData = props.headerInformationData;
	const activeNavLink = props.activeNavLink;
	const preventClickForMobile = props.preventClickForMobile;
	const branchInfo = (headerInformationData && headerInformationData.branchInformation !== undefined) ? headerInformationData.branchInformation:{};
	const myAccountInfo = (userSettingResponse && userSettingResponse.myAccountInfo !== undefined) ? userSettingResponse.myAccountInfo:[];
	const cartSummary = (headerInformationData && headerInformationData.shoppingCartSummary !== null) ? headerInformationData.shoppingCartSummary:{};
	const emailRef = useRef();
	const [toggle, setToggle] = useState(false);
	const liRef = useRef();
	const [showHide, setShowHide] = useState('hide-dd');
	const [isFocused, setIsFocused] = useState(false);
	const [emailErrorMessage, setEmailErrorMessage] = useState(null);

	useOutsideClick(liRef, ()=> {
		setIsFocused(false)
	})

	const handleFormSubmit  = (event) => {
		let emailValue = emailRef.current ? emailRef.current.value : "";
		if(!emailValue || !(/^[\w'-]+(\.[\w'-]+)*@([\w'-]+(\.[\w'-]{2,})+)$/.test(emailValue))){
			event.preventDefault();
			setEmailErrorMessage(emailValue ?  "Email Address is not a valid email address." : "Email Address is required.");
		} else{
			emailErrorMessage && setEmailErrorMessage(null);
		}
	};

	function mouseOver(hoverName) {
		if (window.innerWidth < 992) return false
		setToggle(hoverName === "myAccount")
		clearTimeout(timer)
	}
	function mouseOut(e) {
		if(window.innerWidth < 992) return false
		timer = setTimeout(()=>{
			if(!isFocused && toggle) setToggle(false)
		},100)
	}
	function handleClick(e) {
		if(window.innerWidth > 992) return false
		e.stopPropagation()
		setToggle(!toggle);
	}
	return (
		<div className={!window.isPunchOutUser ? "navbar navbar-expand-sm navbar-dark bg-dark-blue feco-navbar-main fcom-navbar" :
			"navbar navbar-expand-sm navbar-dark bg-dark-blue feco-navbar-main fcom-navbar replatformDisabled"} key={props}>
			<div className="flex-fill ie11-container-width cstm-navbar-primary">
				<ul className="navbar-nav collapse navbar-collapse d-flex align-items-center flex-row ie-nav-no-collapse">
					<li className="nav-item feco-category-flyout-menu position-relative" onMouseOver={() => {props.setToggle(false)}}>
						<Link className="nav-link d-flex flex-column flex-lg-row justify-content-center align-items-center" to={setJessionId("/product/all")} data-name="productMenu">
							<BsNutFill size="2em" className="nutIcon"/>
							<div className="desktop-only ecom-wrap-nav-text pl-1 m-0 fs-16" style={{paddingTop: "0.36rem"}}>
								{label?.allProducts}
							</div>
						</Link>
						<Link className="nav-link d-flex flex-column flex-lg-row d-block d-lg-none" to={setJessionId("/product/all")} data-name="productMenu"
									aria-controls="productMenu" aria-expanded="true" onClick={preventClickForMobile} >
							<BsNutFill size="2em" className="nutIcon"/>
							<div className="d-block d-lg-none text-center text-lg-left nav-link-mobile pl-1 pr-1">{label?.products}</div>
						</Link>
						<Menu categoryMenu={ categoryMenu }/>
					</li>
					<li className="nav-item ecom-header-search position-relative flex-fill" onMouseOver={() => {props.setToggle(false)}}>
						<GlobalSearch />
					</li>

					{!window.isPunchOutUser && (
					<li className={(userSettingResponse?.customerNumber !== undefined && userSettingResponse?.countryName !== 'Canada' ) ? 'nav-item position-relative feco-myaccount-flyout-menu noHover' : 'nav-item position-relative feco-myaccount-flyout-menu'} onMouseOver={() => {props.setToggle(false)}}>
						<a
							className="nav-link d-flex flex-column flex-lg-row justify-content-center"
							href="/catalog/country-locations"
							data-name="Language"
						>
							<VscGlobe  size="2.4em" className="mr-2 mt-1"/>
							<div className="desktop-only ecom-wrap-nav-text text-left m-0">
								{"Fastenal.com"}
								<div className="nav-link-sub">
									{ userSettingResponse?.countryName}{" "}
									- { userSettingResponse?.countryLanguage}
								</div>
							</div>
							<div className="d-block d-lg-none text-center text-lg-left pl-1 pr-1">
								{window.location.host.split(".").pop() === "ca"
									? "Canada"
									: "United States"}{" "}
								- English
							</div>
						</a>
						<div className={userSettingResponse?.countryName === "Canada" ? 'feco-header-dropdown ecom-region-dropdown border-top' : 'feco-header-dropdown'}>
							<ul className="list-unstyled p-2">
								<li className="pb-2">
									<a className="text-dark noHover" href="#">
										<span>Canada - English</span>
									</a>
								</li>
								<li className="pb-2">
									<a className="text-dark" href="https://www.fastenal.ca/fr/" target="_blank">
										<span style={{color: "#3498db"}}>Canada - Français</span>
									</a>
								</li>
							</ul>
						</div>
					</li>)}
					{ headerInformationData && headerInformationData?.userRole === 'GUEST' ?
						<li  className="nav-item feco-myaccount-flyout-menu position-relative">
							<a className="nav-link d-flex flex-column flex-lg-row justify-content-center" href="#" data-name="myAccount">
								<FaUserCircle size="2em" className="mr-2 mt-1"/>
								<div className="desktop-only ecom-wrap-nav-text text-left m-0">
									{label?.myAccount}
									<div className="nav-link-sub">
										{headerInformationData.customerDisplayName }
									</div>
								</div>
								<div className="d-block d-lg-none text-center text-lg-left pl-1 pr-1">{label?.account}</div>
							</a>
							<div className="loggedin-dropdown feco-header-dropdown ecom-myaccount-dropdown p-3">
								<form className="mt-1 mr-2" action={LOGOUT_URL} method="POST" noValidate="novalidate" style={{display: "inline"}}>
									<input type="submit" className="secondary blue border-0" value="Sign out of Guest"/>
								</form>
								<span>
								or
								<a href={setJessionId("/logon/sign-in?action=register")} className="text-button ml-2">{label?.register}</a></span>
							</div>
						</li>
						:
						(window.userLoggedIn && window.userLoggedIn !== undefined) ? (
							<li className="nav-item feco-myaccount-flyout-menu position-relative">
								<a className="nav-link d-flex flex-column flex-lg-row justify-content-center" href={isRolePendingUser()?setfcAndJessionId("#"):setfcAndJessionId("/my-account")} data-name="myAccount">
									<FaUserCircle size="2em" className="mr-2 mt-1"/>
									<div className="desktop-only ecom-wrap-nav-text text-left m-0">
										{label?.myAccount}
										<div className="nav-link-sub nav-sub-ellipse">
											{
												(headerInformationData && !headerInformationData.customerNumber) ? headerInformationData.customerDisplayName : headerInformationData?.customerNumber
											}
										</div>
									</div>
									<div className="d-block d-lg-none text-center text-lg-left nav-link-mobile pl-1 pr-1">{label?.account}</div>
								</a>

								<div className={isRolePendingUser()?"feco-header-dropdown ecom-myaccount-dropdown":"loggedin-dropdown feco-header-dropdown ecom-myaccount-dropdown"}>
									<div className="m-2">
										{
											myAccountInfo && !isRolePendingUser() && myAccountInfo.map((category) => {
												return (
													<span key={category.title}>
														<h6 className="pt-2" dangerouslySetInnerHTML={{ __html: category.title }}></h6>
														{
															category.items.map((item) => {
																return (
																	<span key={item.settingName}>
																		<span className="ml-2">
																			<a href={`${isRolePendingUser()?"#":item.urlPattern}`}>{item.settingName}</a>
																		</span><br/>
																	</span>
																)
															})
														}
													</span>
												)
											})
										}
									</div>
									{!window.isPunchOutUser && (
										<>
										{!isRolePendingUser() && (<hr/>)}
											<form className="m-2" action={LOGOUT_URL} method="POST" noValidate="novalidate">
												<input type="submit" className="blue border-0 feco-btn-text-sm-normal" value="Sign out"/>
											</form>
										</>
									)}
								</div>
							</li>
						) : (
							<li ref={liRef} onMouseOver={() => mouseOver("myAccount")} onMouseOut={() => mouseOut()} className={`nav-item feco-myaccount-flyout-menu position-relative  ${toggle ? 'show-dd' : 'hide-dd'}`}>
								<a className="nav-link d-flex flex-column flex-lg-row justify-content-center" href={setfcAndJessionId("/session/my-account")} data-name="myAccount">
									<FaUserCircle size="2em" className="mr-2 mt-1"/>
								<div className="desktop-only ecom-wrap-nav-text text-left m-0">
									{label?.myAccount}
									{(myAccountInfo?.length > 0 || headerInformationData?.userRole === 'GUEST') && <div className="nav-link-sub">{label?.signInorRegister}</div>}
								</div>
								<div className="d-block d-lg-none text-center text-lg-left pl-1 pr-1 nav-link-mobile">{label?.account}</div>
							</a>
							<form action={LOGIN_URL} method="post">
								<div className="feco-header-dropdown ecom-myaccount-dropdown p-4 ie-header-dropdown">
									<div className="form-group">
										<label htmlFor="mail" className="font-weight-600" id="emailLabel">
											<span className="ecom-mand">* </span>{label?.emailAddress}:
										</label>
										{emailErrorMessage && <span className="field-error-message" id="emailAddress.errors">{emailErrorMessage}</span>}
										<input onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} required type="email" name="emailAddress" autoComplete="on" className={"form-control form-control feco-w-sm-100" + (emailErrorMessage ? " ui-invalid-input": "")}
											id="mail" placeholder={label?.emailAddress} aria-describedby="emailLabel" ref={emailRef}/>
									</div>
									<div className="">
										<button type="submit" className="btn btn-primary feco-btn-text-sm-normal" onClick={handleFormSubmit}>{label?.signInorRegister}</button>
											<div className="mt-2">{label?.forgotYour} <a href={"/azurelogin/password-forgot"}>{label?.password}</a>?</div>
									</div>
								</div>
							</form>
						</li>
						)}

					<li className="nav-item position-relative feco-mybranch-flyout-menu" onMouseOver={() => {props.setToggle(false)}}>
						<a className="nav-link d-flex flex-column flex-lg-row justify-content-center" href={`/locations`} onClick={preventClickForMobile} data-name="myBranch">
							<FaMapMarkerAlt size="2em"  className="mr-2 mt-1"/>
							<div className="desktop-only ecom-wrap-nav-text m-0">
								{label?.myBranch}
								<div className="nav-link-sub nav-sub-ellipse">{(myAccountInfo?.length > 0 ||  headerInformationData?.userRole === 'GUEST') && (branchInfo && Object.keys(branchInfo).length > 0 ? branchInfo.city : label?.findBranch) } </div>
							</div>
							<div className="d-block d-lg-none text-center text-lg-left pl-1 pr-1 nav-link-mobile">
								{label?.branch}
							</div>
						</a>
						<div className="feco-header-dropdown ecom-mybranch-dropdown pb-2 ie-header-dropdown">
							{branchInfo && Object.keys(branchInfo).length > 0 ? (<div>
								<div className="p-2">
									<h6 className="p-0 m-0">
										<a href={`/locations/details/${branchInfo.store5Code}`}>
											<span id="branch-store5code">{`${branchInfo.store5Code}`}</span> -
											<span>{`${branchInfo.city} ${branchInfo.postal}`} </span>
										</a>
									</h6>
									{branchInfo.branchMessage &&
									<div style={{Color : "red"}}>
										<span>{branchInfo.branchMessage}</span>&nbsp;
										<i className="fa fa-info-circle pl-1 text-primary" aria-hidden="true" title={branchInfo.branchTooltip}></i>
									</div>
									}
									<span>{ branchInfo.address1 }</span><br />
									<span>{`${branchInfo.city}, ${branchInfo.stateAbbreviation} ${branchInfo.postal}`}</span><br/>
									<span>{label?.phone}: { branchInfo.phone }</span><br/>
									<span>{label?.email}: <a href={`mailto: ${branchInfo.email}`}>{ branchInfo.email }</a></span>
								</div>
								<hr/>
								<a className="p-2" href="/locations">{label?.findAnotherBranch}</a>
							</div>) : (<div className="row pt-3 pl-4 ie-inline-child">
								<div className="col-md-6">
									<p className="center">
									<a href={`/locations`} className="button action feco-btn-text-sm-normal">{label?.findBranch}</a></p>
								</div>
								<div className="col-md-5">
									<p>{label?.localBranchNotYetSelected}</p>
								</div>
							</div>)}
						</div>
					</li>
					<ShoppingCartSummary cartSummary={cartSummary} myAccountInfo={myAccountInfo} userRole={headerInformationData?.userRole} setToggle={props.setToggle}/>
					<li className="nav-item position-relative d-block d-lg-none" onMouseOver={() => {props.setToggle(false)}}>
						<a href="#" className="nav-link"  onClick={preventClickForMobile} data-name = "headerMenu">
							<FaShoppingCart size="2em" className="mr-2 mt-1"/>
							<div className="d-block d-lg-none pl-1 pr-1 text-center text-lg-left nav-link-mobile">{label?.menu}</div>
						</a>
					</li>
					{props.isMobileView && <Language locales={props.locales} activeNavLink={activeNavLink} setActiveNavLink={props.setActiveNavLink} preventClickForMobile={preventClickForMobile}/>}
					<li className="nav-item top-navbar-mobile d-block d-sm-none">
						<div className={`mobile-search mobile-search-container phone-only white ${activeNavLink === "search" ? 'js-mobile-search-icon':''}`} onClick={preventClickForMobile} data-name="search">
							<span className="no-padding search-span" data-name="search">
							<FaShoppingCart size="2em" className="mr-2 mt-1"/>
								<span className="text-white center search-span mobile-hdr-title">{label?.search}</span>
							</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
  return {
	labels: state.commonReducer.pageLabels,
	isMobileView: state.commonReducer.isMobileView
  }
};

export default connect( mapStateToProps, null )(Navbar);