import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { setJessionId } from "../helpers/CommonScripts";

const BreadCrumb = (props) => {
  const [bredcrumbWidth, setBredcrumbWidth] = useState("");
  const landingPageUrl = window.landingPageUrl;
  const label = props.labels;
  const staticPageUrl = props.parameters.split("/");
  var filtered = staticPageUrl.filter(function (el) {
    return (el !== "" && el !== "fast" && el !== "landing");
  });

  const breadCrumbsArray = filtered;
  const breadCrumbAnchors = [];
  let breadCrumb;

  for (let i = 0; i < breadCrumbsArray.length-1; i++) {
    let res = new Array(i + 1).fill(true).map((e, ind) => breadCrumbsArray[ind]);
    breadCrumb = <Link className="nav-link active pr-1 pl-2" to={setJessionId(`/fast/${res.join("/")}`)}>{breadCrumbsArray[i].split('-').join(' ').replace(/(^[a-z])|(\s+[a-z])/g, txt => txt.toUpperCase())}<i className="fas fa-chevron-right"></i></Link>
    breadCrumbAnchors.push(breadCrumb);
  }

  useEffect(() => {
		setBredcrumbWidth(document.getElementById("main-container")?.offsetWidth-35)
	}, [])

  return (
      <>
        <div className="feco-breadcrumb-row" style={{width: bredcrumbWidth}}>
          <div className="bg-white border ecom-breadcrumb mb-2 mt-2 ie-breadcrumb" id="static-pages-breadcrumb">
            <ul className="nav ml-1">
              <li className="nav-item">
                <Link className="nav-link" to={setJessionId(`${landingPageUrl !== undefined ? landingPageUrl : '/'}`)}>{label?.home} <i className="fas fa-chevron-right"></i></Link>
              </li>
              { breadCrumbsArray &&
                <>
                  {breadCrumbAnchors.map((breadCrumb, i) => {
                    return (
                      <li className="nav-item" key= {i}>
                        {breadCrumb}
                      </li>
                    )
                  })}
                  <li className="nav-item">
                    <Link className="nav-link disabled" to="/"> { breadCrumbsArray[breadCrumbsArray.length-1].split('-').join(' ').replace(/(^[a-z])|(\s+[a-z])/g, txt => txt.toUpperCase())}</Link>
                  </li>
                </>
              }
            </ul>
          </div>
        </div>
        <div className="feco-breadcrumb-white-space"></div>
      </>
  )
};

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};

export default connect( mapStateToProps, null )(BreadCrumb);