import React from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import DataProgressBar from "../DataProgressBar";
import CatalogNavigator from "../CatalogNavigator";
import Header from "./Home/Header";
import Navbar from "./Home/Navbar";
import NotFound from "../NotFound";
import Fast from "../Fast";
import Cookies from "../Cookies";
import Footer from "../Footer";
import ViewAs from "../ViewAs";
/*import Locations from "../LocationPage/Locations";*/

function MobileRoutes({
  cmsData,
  data, headerInformationData,
  showLoader,
  showProgressBar,
    isMobile
}) {
  return (
    <Router basename={window.baseUrl}>
      <header className="fixed-top">
        <Header
          userSettings={data}
          headerInformationData={headerInformationData}
          data={data}
        />
        <Navbar />
        <ViewAs userSetting={data} />
        {showProgressBar && <DataProgressBar />}
      </header>
      <Switch>
        <Route key="Fast" exact path="/fast/*"  render={(props) => (<Fast {...props} showLoader={showLoader} />)} />
        <Route key="Landing" exact path="/landing/*"  render={(props) => (<Fast {...props} showLoader={showLoader} />)}/>
        <Route key="categoryList" exact path="/*"
               render={(props) => (<CatalogNavigator {...props} showLoader={showLoader} isMobile={isMobile} />)} />
        <Route component={NotFound} />
      </Switch>
      {cmsData &&
      <>
        {data.cookieAgreement !== undefined && <Cookies cookieAgreement={data.cookieAgreement} />}
        <Footer data={ data } isMobile={isMobile}/>
      </>
      }
    </Router>
  );
}

export default MobileRoutes;
