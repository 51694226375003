import React from 'react';
import { Link } from 'react-router-dom';
import {setJessionId} from '../helpers/CommonScripts';

function NotFound(props) {
  const data = props.data !== undefined ? props.data:{};
  const state = data.state !== undefined ? data.state:404;
  const homeUrl = data.homUrl !== undefined ? data.homUrl : window.landingPageUrl;
  const page = data.request !== undefined ? 'request':'page';

  return (
    <div className="feco-container-fix feco-container-inner mt-5">
      <div className="text-center mt-5">
        <h1>{state} Error Page</h1>
        <p>Sorry, this {page} doesn't exist </p>
        <Link className="ml-5" to={setJessionId(homeUrl)}>Go Home</Link>
      </div>
      <div style={{margin: "180px 0 0 400px"}}></div>
    </div>
  )
}
export default NotFound;