import React, { useEffect,useState } from 'react';
import { useHistory,useLocation } from 'react-router-dom';
import Pagination from "react-js-pagination";
import queryString from 'query-string';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { addToCart } from '../actions/cartActions';
import { addToCompare, removeProductCompare } from '../actions/compareActions';
import CompareFooter from "./CompareFooter";
import CounterBook from "./counterbook/CounterBook";
import GridView from "./GridView";
import ListView from "./ListView";
import { AddToShoppingCart, sendViewPromotionAnalytics } from '../helpers/CommonScripts';
import ViewToggle from "./ViewToggle";
import { viewPreference } from '../actions/commonActions';
import { postRequest } from '../apiCalls/ApiCall';
import { VIEW_PREFERENCE_URL } from '../constants/Constants'
import {GA4viewListItem} from "../helpers/CommonScripts"

function ProductList(props) {
  const label = props.labels;
  const promotions = props.promotions;
  const history = useHistory();
  const location = useLocation();
  const responseData = props.responseData;
  const queryParams = queryString.parse(history.location.search, {
    ignoreQueryPrefix: true
  });
  const productViewPreference = ((responseData.productViewPreference === undefined && responseData.resultType === "productList")) ? "LIST" : responseData.productViewPreference === undefined ? "LIST" : responseData.productViewPreference;
  delete queryParams.fsi;
  const [viewPreference, setViewPreference] = useState("");
  const pagination = responseData.paging;
  const activePage = pagination.currentPage;
  const productListCount = pagination.totalCount;
  const [itemsCountPerPage, setItemsCountPerPage] = useState(pagination.pageSize);
  const [productList, setProductList] = useState(responseData.productList);
  const categoryList = responseData.categoryList;
  const counterbook = responseData.counterbook;
  const defaultItemSortBy = responseData.searchRequest?.sortBy;
  const[productChange, setProductChange] = useState(true);

  // Change page
  useEffect(() => {
    const prefView = props.preferenceView[queryParams.categoryId]
    const pageSize = queryParams.pageSize && [12,24,48].includes(parseInt(queryParams.pageSize)) ? queryParams.pageSize : 12;
    setViewPreference(prefView ? prefView : productViewPreference);
    setItemsCountPerPage(pageSize);
    setProductChange(false);
  }, [location, productViewPreference]);

  useEffect(() => {
    setProductList(responseData.productList);
    setProductChange(true);
  },[props.responseData]);

  const handlePageChange = (pageNumber) => {
    delete queryParams.productViewPreference;
    let searchParams = Object.assign(queryParams, { "page": `${pageNumber}` }, { "pageSize": `${itemsCountPerPage}` });
    history.push(`?${queryString.stringify(searchParams)}`);
  };
  function handleSelectChange(event) {
    delete queryParams.productViewPreference;
    let itemsPerPage = Number(event.target.value);
    setItemsCountPerPage(itemsPerPage);
    let searchParams = Object.assign(queryParams, { "page": "1" }, { "pageSize": `${itemsPerPage}` });
    history.push(`?${queryString.stringify(searchParams)}`);
  }

  function handleViewChange(val) {
    setViewPreference(val);
    setTimeout(() => {
      //if ((viewPreference === "LIST" && val === "GRID") || (viewPreference === "GRID" && val === "LIST") || responseData.isCached) {
        postRequest(VIEW_PREFERENCE_URL, { "categoryId": queryParams.categoryId, "productViewPreference": val});
      //}
    }, 500);
    props.viewPreference(queryParams.categoryId, val);
    if (val === "COUNTERBOOK") {
      const searchParams = Object.assign(queryParams, { "productViewPreference": "COUNTERBOOK" });
      history.push(`?${queryString.stringify(searchParams)}`);
    } else {
      const searchParams = Object.assign(queryParams, { "productViewPreference": val })
      if (viewPreference === "COUNTERBOOK") {
        history.replace(`?${queryString.stringify(searchParams)}`);
      }
    }
    GA4viewListItem.init(productList, responseData.resultType, val, productViewPreference, counterbook);
  }

  const onClickAddToCompare = (e, sku, img) => {
    if (!e.target.checked || props.compareProducts.length <= 4) {
      if (e.target.checked) {
        props.addToCompare(sku, img);
      } else {
        props.removeProductCompare(sku);
      }
    } else {
      e.target.checked = false;
      toast(label?.AddToCompareMaxQty5, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  }

  useEffect(() => {
    if (promotions && promotions.GAResponse && (promotions.GAResponse.length > 0)) {
      JSON.parse(promotions.GAResponse).map((promo) => {
        if ((productChange === true) && productList && (productList.length === itemsCountPerPage) && (responseData.resultType !== "counterBook" || productViewPreference !== "COUNTERBOOK")) {
          sendViewPromotionAnalytics(promo, responseData, productList);
        } else {
          if (responseData.resultType === "counterBook" && productViewPreference === "COUNTERBOOK") {
            sendViewPromotionAnalytics(promo, responseData, []);
          }
        }
      })
    }
  }, [promotions, productList]);

  useEffect(() => {
      GA4viewListItem.init(productList, responseData.resultType, viewPreference, productViewPreference, counterbook);
  },[productList || counterbook]);

  return (
    <>
      { (categoryList !== undefined && categoryList.length > 1) && <hr />  }
      <ViewToggle viewPreference = {viewPreference} productListCount = {productListCount}
        handleViewChange = {handleViewChange} itemsCountPerPage ={itemsCountPerPage}
        handleSelectChange= {handleSelectChange} defaultItemSortBy = {defaultItemSortBy} history={history}
        queryParams={queryParams} queryString={queryString}
        resultType={responseData.resultType} drawCounterBook = {responseData.drawCounterBook}/>

      {viewPreference === "LIST" && <ListView productList={productList} onClickAddToCart={AddToShoppingCart} addToCart={props.addToCart} onClickAddToCompare={onClickAddToCompare} resultTypeGA={responseData.resultType} />}
      { viewPreference === "GRID" && (<GridView productList={productList} compare={true} onClickAddToCart={AddToShoppingCart} addToCart={props.addToCart} onClickAddToCompare={ onClickAddToCompare} resultTypeGA={responseData.resultType} />)}
      { viewPreference === "COUNTERBOOK" && (<CounterBook counterbook={counterbook} onClickAddToCart={AddToShoppingCart} addToCart={props.addToCart} onClickAddToCompare={onClickAddToCompare} />) }
      {(viewPreference !== "COUNTERBOOK" && productList !== undefined) && <div className="d-flex justify-content-center ecom-product-view-pagination mb-4 ie-pagination ie-width-full ie-float-left" id={ itemsCountPerPage }>
        <Pagination
          activePage = { activePage }
          itemsCountPerPage = { itemsCountPerPage }
          totalItemsCount = { productListCount }
          pageRangeDisplayed = { 5 }
          onChange = {handlePageChange}
          linkClass = "page-link"
          itemClass = "page-item"
          firstPageText = "First"
          lastPageText="Last"
          disabledClass="d-none"
        />
      </div>
      }
      <CompareFooter />
    </>
  )
}


const mapStateToProps = (state) => {
  return {
    compareProducts: state.compareReducer.compareItems,
    labels: state.commonReducer.pageLabels,
    preferenceView: state.commonReducer.viewPreference
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (shoppingCartSummary) => { dispatch(addToCart(shoppingCartSummary)) },
    addToCompare: (sku, image) => { dispatch(addToCompare(sku, image)) },
    removeProductCompare: (sku) => { dispatch(removeProductCompare(sku)) },
    viewPreference: (categoryId, preferenceView) => { dispatch(viewPreference(categoryId, preferenceView))}
  }
};

export default connect( mapStateToProps, mapDispatchToProps )(ProductList);