import {useState, useEffect, useRef, useCallback} from 'react';

const useStickyHeader = () => {
	const [isSticky, setIsSticky] = useState(false);
	const [fixedHeaderTop, setFixedHeaderTop] = useState(null);
	const sectionRef = useRef(null);
	const headerRef = useRef(null);
	const minTopRef = useRef(0);
	const bottomDiffRef = useRef(0);
	const pageHeaderHeightRef = useRef(0);

	const toggleStickiness = useCallback(({ top}) => {
		 if (top <= minTopRef.current) {
				!isSticky && setIsSticky(true);
			} else {
				isSticky && setIsSticky(false);
			}
	}, [isSticky]);

	const toggleStickinessAndSetTop = useCallback((section, sectionHeader) => {
			if(section.top <= minTopRef.current && section.bottom > pageHeaderHeightRef.current){
				!isSticky && setIsSticky(true);
				if (section.bottom - sectionHeader.height - bottomDiffRef.current <= minTopRef.current) {
					setFixedHeaderTop(section.bottom - sectionHeader.height - bottomDiffRef.current);
				} else if (fixedHeaderTop !== minTopRef.current) {
					setFixedHeaderTop(minTopRef.current);
				}
			} else if(isSticky){
				setIsSticky(false);
			}
	}, [isSticky, fixedHeaderTop]);

	useEffect(() => {
		const handleScroll = () => {
			if(sectionRef.current && headerRef.current){
				toggleStickinessAndSetTop(sectionRef.current.getBoundingClientRect(), headerRef.current.getBoundingClientRect());
			} else if(sectionRef && sectionRef.current) {
				toggleStickiness(sectionRef.current.getBoundingClientRect());
			}
		};
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [toggleStickiness]);

	useEffect(() => {
		pageHeaderHeightRef.current = document.getElementById("ecom-header")?.offsetHeight +
			document.getElementById("view-toggle-button")?.offsetHeight + document.getElementById("ecom-breadcrumb")?.offsetHeight+2;
	}, []);

	return { sectionRef, isSticky, minTopRef,bottomDiffRef, headerRef, fixedHeaderTop, pageHeaderHeightRef};
};

export default useStickyHeader;