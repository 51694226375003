import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import ComplianceDetail from './ComplianceDetail';
import {connect} from "react-redux";
import AddToCartTemplate from './AddToCartTemplate';
import {GA4viewListItem, imgSrc, sendPDAnalyticsData, setJessionId} from '../helpers/CommonScripts';
import ProductAvailability from './ProductAvailability';
import CustomCatalog from "./CustomCatalog";
import { onChangeInputQuantity } from './OnchangeInputQuantity';
import {onkeydownInputQuantity} from "./OnkeydownInputQuantity";
import {FiRepeat} from "react-icons/fi";

function GridView(props) {
	const label = props.labels;
	const title = props?.title;
	const productList = props.productList;
	const [complianceDetail, setComplianceDetail] = useState([]);
	const [show, setShow] = useState(false);
	const compareProducts = props.compareProducts;
	const resultTypeGA = props.resultTypeGA;
	const comparelist_skus =  compareProducts && compareProducts.map(function (obj) {
		return obj.sku
	});

	const showComplianceDetails = (compliances) => {
		setComplianceDetail(compliances);
		setShow(true);
		document.body.className += 'modal-open';
	};
	const handleClose = () => setShow(false);

	const closeViewAllPopup = () => {
		if(props.closeViewAll) {
			props.closeViewAll()
		}
	};

	useEffect(() => {
		if(title){
			GA4viewListItem.init(productList, title, "GRID", "GRID", undefined);
		}
	},[productList]);

return (
		<>
			<div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 no-gutters mb-4 ie-grid-col ie-float-left ie-width-full">
			<input type="hidden" name="pageVal" value="Product Grid View" />
				{productList && productList.map((product) =>
					<div className="col" key={product.sku}>
						<div className="card h-100 rounded-0 ie-float-left ie-width-full">
							{
								product &&  product.isExpress !== undefined && product.isExpress === 'Y' &&
									<div className="row">
										<div className="col-xs-12">
											<img src={imgSrc("/static/images/express-logo-small.png")} className="ml-4" alt="..." style={{width: "75px"}}/>
										</div>
									</div>
							}
							<div className="row no-gutters h-100">
								<div className="col-xs-12 col-md-4 p-2 ie-float-left">
									<div className="border p-2 feco-thumbnail-size-100 d-flex mr-0 position-relative">
										<Link
											to={setJessionId(`/product/details/${product.sku}`)}
											className="ie-thumbnail" onClick={() => {
											if(title) {
												sendPDAnalyticsData(product,"select_item", title);
											}
										}}>
										<img src={imgSrc(product.thumbnail)} className="rounded card-img d-flex" alt="..."/>
										{ product.hasOwnProperty("previouslyPurchased") &&
											<div className="position-absolute l-0 mt-n3 d-inline-flex">
												<FiRepeat className=" p-1 border rounded-circle border-orange text-orange bg-white" style={{zIndex: 5}} size="1.8em"/>
												<div className="b-2 bg-orange text-white h6 ml-n2 pl-2 pr-1-2" style={{zIndex: 4, marginTop: '0.2rem'}}>{label?.reOrder}</div>
											</div>
										 }
										</Link>
									</div>
									{props.compare && <div className="form-check mt-1">
										<input className="form-check-input feco-form-check-input" type="checkbox" value="" id={product.sku} defaultChecked={comparelist_skus.includes(product.sku) ? "checked":""} onClick={(e) => {props.onClickAddToCompare(e, product.sku, imgSrc(product.thumbnail))} } onChange={(e) => { onChangeInputQuantity(e) }}/>
										<label className="form-check-label" htmlFor={product.sku}>
											{label?.compare}
										</label>
									</div>}
								</div>
								<input type="hidden" name="data-sku" value={product?.sku}/>
								<div className="col-xs-12 col-md-8 ml-n3 ml-md-0 ie-float-left feco-ml-0 feco-ml-sm-0 feco-pl-md-1 ie-feco-grid-mb">
									<div className="card-body">
										<div className="card-title">
											<div
												className="font-weight-600">{product.customerSku ? label?.yourSku : label?.fastenalPartNo}:
												<Link className="default-nav-link"
													  to={setJessionId(`/product/details/${product.sku}`)}
													  onClick={(e) => {
														  closeViewAllPopup();
														  if(title) {
															  sendPDAnalyticsData(product,"select_item", title);
														  }
													  }}> {product.customerSku !== undefined ? `${product.customerSku}(${product.sku})` : product.sku}</Link>
												<CustomCatalog productDetail={product} displayText={false}/>
											</div>
											<p className="card-text"><span dangerouslySetInnerHTML={{ __html: product.description}}></span></p>
										</div>
										{product.manufacturer &&
										<div className="card-text">
											<div className="font-weight-600">{label?.manufacturer}</div>
											<div className="card-text" dangerouslySetInnerHTML={{ __html: product.manufacturerLabel}}></div>
										</div>
										}
										{product.brandName &&
											<div className="card-text">
											<div className="font-weight-600">{label?.brand}</div>
												<div className="card-text" dangerouslySetInnerHTML={{ __html: product.brandLabel}}></div>
											</div>
										}
										<div className="ecom-card-icon-list-group mt-2 ie-card-icon-list-group">
											<ul className="nav ie-float-left ie-width-full">
												{
													product.compliances && product.compliances.map((compliance, i) => {
														if ((compliance.complianceName === "Restricted Product" && (compliance["Restriction Areas"] !== undefined)) || (compliance.complianceName !== 'Restricted Product')) return (
															<li className="nav-item cursor-pointer feco-border-sm" key={i} onClick={(e) => {showComplianceDetails(product.compliances)} }>
                                        						<img src={imgSrc(compliance.Image)} title={compliance["complianceName"]} alt={compliance["Alt Text"]} className={compliance.Image ? "" : "img-20x20"} className="img-20x20"/>
															</li>)
													})
												}
											</ul>
										</div>
										<div className="mt-2">
											{product.priceDisplayData && product.priceDisplayData.length > 0 ?
												product.priceDisplayData.map((price, index) => {
													return (
														<div key={index}>
															<div className={`text-${price.label === "Wholesale:" ? "black" :(
																price.label === "Unit Price:" && index - 1 >= 0 && product.priceDisplayData[index - 1].label === "Wholesale:" ? "black" : "primary"
															)} ${index > 0 ? "mt-1" : ""}`}>
																<span className="font-weight-600">{ price.label } </span><br />
																<span className="font-weight-bold">{ price.price } {price.uom ? "/" : ""} { price.uom }</span>
															</div>
														</div>
													)
												})
												:
												<span><span className="font-weight-bold">{label?.price}: </span>{label?.noPriceAvailable}</span>
											}
										</div>
									</div>
								</div>
							</div>
							<div className="card-footer ie-feco-cad-footer-position mt-2 d-flex align-items-center ie-float-left ie-width-full">
								{(product && product.productEda !== undefined) ? <ProductAvailability productEda={product.productEda} /> : "Loading..."}
								<div className="ml-auto d-flex ie-float-right">
									<input type="number" min="0" max="99999" className="form-control p-1 ecom-input-sm mr-2 ie-float-left" defaultValue="1" id={`selected_quantity_${product.sku}`} onChange={(e) => { onChangeInputQuantity(e) }} onKeyDown={(e) => { onkeydownInputQuantity(e) }} />
									<div className="btn-group">
										<button type="button" className="btn btn-primary ecom-button ecom-button-highlight" onClick={(e)=> {props.onClickAddToCart(e, product, props, (resultTypeGA === "categoryList" ? "Search Results" : (title || "Grid View")))}}>
											<i className="fa fa-shopping-cart" aria-hidden="true"></i>
										</button>
										<AddToCartTemplate sku={product.sku} />
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
				{ show && <ComplianceDetail compliances={complianceDetail} handleClose={handleClose} show={ show }/>}
			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		compareProducts: state.compareReducer.compareItems,
		labels: state.commonReducer.pageLabels
	}
};

export default connect(mapStateToProps, null)(GridView);