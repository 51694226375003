import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from "react-bootstrap/Modal";
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import { imgSrc, setJessionId } from '../helpers/CommonScripts';
import Spinner from './PopupSpinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

function BinStock(props) {
    const label = props.labels;
    const sku = props.sku;
    const binStockDetails = props.binStockList;
    const [popoverInfo, setPopoverInfo] = useState({});
    const [showPopoverInfo, setShowPopoverInfo] = useState(false);
    const [target, setTarget] = useState(null);
    const handlePopover = (event, cellInfo) => {
        setPopoverInfo(cellInfo);
        setShowPopoverInfo((popoverInfo === cellInfo) ? !showPopoverInfo : true);
        setTarget(event.target);
    }

    if (Object.keys(binStockDetails).length === 0) return <Spinner />

    const popoverBinImg = (
        <Popover style={{ maxWidth: 400}}>
            <Popover.Content className="border">
                <img src={imgSrc("/static/images/binstock-machine-info.jpg")} alt="..."/>
            </Popover.Content>
        </Popover>
    );

    return (
        <>
            <Modal show={props.show} animation={true} onHide={props.handleClose} className="feco-modal-fluid text-center modal-bin">
                <Modal.Header className="feco-modal-header-primary p-2"  closeButton>
                    <Modal.Title as="h6">
                        {`${binStockDetails.addressName} - ${binStockDetails.locationName} - ${binStockDetails.planogramName}`}
                        <OverlayTrigger placement="bottom-end" delay={{ show: 250, hide: 500 }} overlay={popoverBinImg}>
                            <span className="fa fa-question-circle pl-2 text-white" ></span>
                        </OverlayTrigger>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-width">
                    <div className="row">
                        <div className="col table-responsive feco-table-binstock-overflow">
                            <table className="table">
                                <tbody>
                                    {
                                        binStockDetails && binStockDetails.planogramCell && binStockDetails.planogramCell.map((planogramCell, rowIndex) => {
                                            return (
                                                <tr key={rowIndex}>
                                                    {
                                                        planogramCell && planogramCell.map((bins, cellIndex) => {
                                                            return (
                                                                <td scope="col" key={cellIndex} className={`planogram-cell bin-cell cursor-pointer ${bins.filter(Boolean).map((binSku) => {return binSku.sku }).includes(sku) ? "feco-planogram-bg-blue" : 'bg-white'}`}>
                                                                    <div className="row m-0 ml-n1">
                                                                        <div className="col-12 p-1">
                                                                            <div className="text-left ml-n1">POS: { `${rowIndex+1}-${cellIndex+1}`}</div>
                                                                            {bins && (bins.filter(Boolean).length > 0) && <div className="mb-n1 nav nav-tabs border-0">
                                                                                {bins.filter(Boolean).map((bin, binIndex) => {
                                                                                    return (
                                                                                        <div className="p-1 border float-left" key={binIndex} data-toggle="tab" href={`#bin${bin.sku}${cellIndex}${binIndex}`} role="tab" aria-controls={`#${bin.sku}-${cellIndex}-${binIndex}`} >
                                                                                            {bin && bin.displayOrderSequence}
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>}
                                                                        </div>
                                                                    </div>
                                                                    {bins && (bins.filter(Boolean).length > 0) && <div className="tab-content d-flex flex-column border w-100">
                                                                        {bins.filter(Boolean).map((bin, binIndex) => {
                                                                            return(
                                                                                <div className={`tab-pane fade ${binIndex === 0 ? 'show active' : ''}`} id={`bin${bin.sku}${cellIndex}${binIndex}`}>
                                                                                    <div className="text-primary font-weight-bold text-left ml-1" onClick={(e) => { handlePopover(e, bin) }}>
                                                                                        {(bin && bin.customerSku)?bin.customerSku:(bin && bin.sku)}
                                                                                    </div>
                                                                                    <div className="d-flex p-1">
                                                                                        <div className="feco-bin-img border p-1">
                                                                                            <img src={imgSrc(bin.thumbnailUrl)} className="image-fluid" alt="..." />
                                                                                        </div>
                                                                                        <div className="flex-fill">
                                                                                            <ul className="list-unstyled">
                                                                                                <li>{label?.min}: {bin?.minQty}</li>
                                                                                                <li>{label?.max}: {bin?.maxQty}</li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )})
                                                                        }
                                                                    </div>}
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Overlay show={showPopoverInfo} target={target} placement="bottom" containerPadding={1}>
                <Popover style={{ maxWidth: 350 }} className="feco-planogram-popover">
                    <Popover.Content>
                        { popoverInfo &&
                            <>
                                <div className="row ie-float-left ie-width-full">
                                    <div className="col-4 font-weight-bold">
                                        {label?.sku}
                                    </div>
                                    <div className="col-1">:</div>
                                    <div className="col-6">
                                        <Link className="default-nav-link" to={setJessionId(`/product/details/${ encodeURIComponent(popoverInfo.sku) }`)}>{popoverInfo.sku}</Link>
                                    </div>
                                </div>
                                <div className="row ie-float-left ie-width-full">
                                    <div className="col-4 font-weight-bold">
                                        {label?.desc}
                                    </div>
                                    <div className="col-1">:</div>
                                    <div className="col-6">
                                        {popoverInfo.skuDescription}
                                    </div>
                                </div>
                                <div className="row ie-float-left ie-width-full">
                                    <div className="col-4 font-weight-bold">
                                        {label?.minOrMax}
                                    </div>
                                    <div className="col-1">:</div>
                                    <div className="col-6">
                                        {`${popoverInfo.minQty ? popoverInfo.minQty : 0}/${popoverInfo.maxQty ? popoverInfo.maxQty : 0}`}
                                    </div>
                                </div>
                            </>
                        }
                    </Popover.Content>
                </Popover>
            </Overlay>
        </>
    )
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};

export default connect( mapStateToProps, null )(BinStock);