import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaChevronRight } from "react-icons/fa";
import { setJessionId } from '../../helpers/CommonScripts';

function Menu(props) {
	const [categoryMenu, setCategoryMenu] = useState([])

	useEffect(() => {
		setCategoryMenu(props.categoryMenu);
	}, [props])
	
	return (
		<div style={{width : 'max-content', fontSize: "0.8rem"}}>
			<div className="row flex-column flex-md-row">
                <div className="flex-wrap p-2 pr-3 m-0">
                    <ul className="list-unstyled nav flex-column" role="menu" aria-labelledby="dropdownMenu">
                        {
                            categoryMenu?.map((category, i) => {
                                return (
                                    <li className="nav-item border-bottom p-1 pt-2 pb-2" key={i} style={{borderColor: "#c8ccce!important"}}>
                                        <Link className="nav-link pt-1 pb-1 text-dark pl-1 pr-0" to={setJessionId(`/product/${category.categoryLevelOne}?fsi=1${category.categoryId !== undefined ? '&categoryId='+category.categoryId:""}`)} onClick={() => document.body.click()}>{ category.categoryLabelOne} <FaChevronRight className='float-right text-black'/></Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
			</div>
		</div>
	)
    
}

const mapStateToProps = (state) => {
	return {
    	categoryMenu: state.commonReducer.categoryMenuList
	}
};
export default connect( mapStateToProps, null )(Menu);