export function appliedAggregation(map, key) {
  var filterArray = [];
  var join_result;
  var obj = _objectWithoutProperties(map, [key]);

  for (const [key, value] of Object.entries(obj)) {
    filterArray.push(`${key}=${encodeURIComponent(value.join('->'))}`)
  }
  join_result = filterArray.join('&');

  return join_result;
}

export function isEmptyObject(obj){
  return JSON.stringify(obj) === '{}';
}

export function _objectWithoutProperties(obj, keys) {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
}

export function aggrigationHash(parsed_hash) {
  var excludeAggregations = ["aggregation", "allowGoogleRemarketing", "categoryId", "fsi", "level", "locale", "page", "pageSize", "productFamilyId", "productListOnly", "productViewPreference", "query", "searchTerms", "showAll", "skipSkuMatch", "sku", "sortBy", "squery", "toggle", "includeSuggestedFilters", "utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"] ;
  var obj = _objectWithoutProperties(parsed_hash, excludeAggregations);
  var agregation_hash = {};
  for (var i in obj) {
    var agregation_arr =  obj[i]?.split('->');
    if (agregation_arr && agregation_arr.length > 0) {
      agregation_hash[i] = obj[i]?.split('->');
    }
  }
  return agregation_hash;
}

export function globalSearchParameter(searchRequest) {
  let queryText = searchRequest["query"] || '';
  let sQueryText = searchRequest["squery"] || '';
  return (
    (queryText !== "" && sQueryText !== "") ? `query=${queryText}&squery=${sQueryText}` : queryText !== "" ? `query=${queryText}` : sQueryText !== "" ? `squery=${sQueryText}` : ''
  )
}

export function searchTermsParameter(searchRequest) {
  var searText = searchRequest["searchTerms"] || [];
  if (searText.length > 0){
    searText = `searchTerms=${searText.join(',')}`
  }else {
    searText = '';
  }
  return searText;
}

export function searchTermArray(parsedHash) {
    if (parsedHash["searchTerms"] !== '' && parsedHash["searchTerms"] !== undefined){
      return parsedHash["searchTerms"].split(',');
    }else {
      return [];
    }
}

export function showAllArray(parsedHash) {
  if (parsedHash["showAll"]){
    return parsedHash["showAll"].split(',');
  }
  return null;
}

export function showAllParam(showAllArray, remove, aggregationName) {
  let result='';
  if(showAllArray){
    if(remove){
      showAllArray = showAllArray.filter(function(e) { return e !== aggregationName})
    } else if(!showAllArray.includes(aggregationName)){
      showAllArray.push(aggregationName);
    }
    showAllArray.length > 0 && ( result = result + '&showAll=' + showAllArray.join());
  }
  return result;
}

export function constructBasicURLParam(searchRequest, aggregationName){
  var url = '';
  var globalSearch =  globalSearchParameter(searchRequest);
  var searchTerms =  searchTermsParameter(searchRequest);
  if(searchRequest["attributeFilters"] ) {
    //exclude particular aggregationName while constructing aggregation URL.
    var aggregation = appliedAggregation(searchRequest["attributeFilters"] , aggregationName);
  }

  globalSearch && (url = `?${globalSearch}`);
  //add showAll if it is not called for aggregation url construction.
  (!aggregationName && searchRequest["showAll"]) &&
  (url = `${appendQuerySeparator(url)}showAll=${encodeURIComponent(searchRequest["showAll"].join())}`);

  searchTerms && (url = `${appendQuerySeparator(url)}${searchTerms}`);
  aggregation && (url = `${appendQuerySeparator(url)}${aggregation}`);

  return url;
}

//==========Generic helper methods start==========
export function appendQuerySeparator(url) {
  url = url ? url :'';
  return url.includes("?") ? `${url}&` : `${url}?`;
}
//==========Generic helper methods end==========

export const CATEGORY_LEVELS = ["categoryLevelOne", "categoryLevelTwo", "categoryLevelThree", "categoryLevelFour"]

//==========Check Other Location Start==========

let excludeArray = {
  "mp_zipCode": ["city", "state", "storeCode", "isWebPosLive", "latitude", "longitude"],
  "mp_cityState": ["zipCode", "isWebPosLive", "latitude", "longitude", "storeCode"],
  "mp_storeCode": ["city", "state", "zipCode", "distance", "onlyIncludeInventory", "isWebPosLive", "latitude", "longitude"],
  "mp_gps": ["city", "state", "zipCode", "storeCode", "isWebPosLive"],
  "mp_remoteStockCheck": ["city", "state", "zipCode", "storeCode", "isWebPosLive", "latitude", "longitude", "distance", "onlyIncludeInventory"],
};

export function removeUnwantedKeysFromObject(obj) {
  var setOfExcludeKeys  = excludeArray[obj.searchType];
  var hash = _objectWithoutProperties(obj, setOfExcludeKeys)
  return hash
}

//==========Check Other Location End==========


//============ user login start ==============
//Defined global variable outside of function
var userLoggedIn = false;
var userRoles = [];

export function setUserLoggedIn(){
  userLoggedIn = true;
};

export function setUserLoggedOut(){
  userLoggedIn = false;
  setUserType([])
};

export function isUserLoggedIn(){
  return userLoggedIn;
};

export function setUserType(types){
  userRoles = types;
};

export function showAddToTemplate(){
  return !(userRoles.includes("ROLE_ANONYMOUS") || userRoles.includes("ROLE_GUEST_USER") || userRoles.includes("ROLE_PENDING_USER") || userRoles.includes("ROLE_GUEST_TRANSITION_USER"))
};
export function isRolePendingUser(){
  return userRoles.includes("ROLE_PENDING_USER")
};
//============ user login end ==============
//============ Local Storage start ==============
export function setLocalStorageItem(key, value, ttl) {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorageItem(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}
//============ Local Storage end ==============
export function  getUriWithParam(baseUrl, params){
  const Url = new URL(baseUrl.href);
  const urlParams = new URLSearchParams(Url.search);
  for (const key in params) {
    if (params[key] !== undefined) {
      urlParams.set(key, params[key]);
    }
  }
  delete urlParams.fsi;
  Url.search = urlParams.toString();
  let resultURL = Url.toString().split("product/")[1];
  resultURL = resultURL !== undefined ? "/" + resultURL : "";
  resultURL = (Url.search && !resultURL.toString().includes("?")) ? resultURL + Url.search : resultURL
  return resultURL;
};