import {connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {Tooltip} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {imgSrc, scriptSrc} from "../helpers/CommonScripts";

function CadModal(props) {
    const sku = props.sku;
    const des = props.des;
    const technicalSpec = props.technicalSpec;
    const label = props.labels;
    const env = props.env;
    const [isCadAvailable,setIsCadAvailable] = useState(false);
    const [show3DModal,setShow3DModal] = useState(false);
    const [scriptsLoaded, setScriptsLoaded] = useState(false);

    useEffect(()=>{
        if(sku && !scriptsLoaded){
            addScripts(env);
            setScriptsLoaded(true);
        } else if( scriptsLoaded ){
            cdsLoadFunction();
        }
    },[sku])

    const postCadLoadCallbackFunction = () => {
        // eslint-disable-next-line
        setIsCadAvailable(cdslib.cad.cadAvailable);
    }

    const cdsLoadFunction = () => {
        {/* eslint-disable-next-line*/}
        cdslib.cad.load({
            "domain": "fastenal", "product": sku,
            "endpoint": env,
            "downloadButtonElementId": "cds-cad-download-button",
            "view2DButtonElementId": "cds-cad-view-2D-button",
            "viewerContainerElementId": "cds-cad-viewer-container",
            "viewerIconsContainerElementId": "cds-cad-viewer-icons-container",
            "preInitCallback" : postCadLoadCallbackFunction,
            "loadFailedCallback" : ()=>{setIsCadAvailable(false)}
        });
    }

    const addScripts = async () => {
        try {
            await loadScript("https://www.product-config.net/catalog3/js/cds-cad.js");
            await loadScript(scriptSrc("/static/CAD/three.js"));
            await loadScript(scriptSrc("/static/CAD/tween.js"));
            await loadStylesheet("https://www.product-config.net/catalog3/css/cds-cad.css");

            loadScript("https://www.product-config.net/cadviewer/js/cds-cad-viewer.js").then(() => {
                // eslint-disable-next-line
                cdsLoadFunction();
            });
        } catch
            (error) {
        }
    };


// function for loading scripts
    const loadScript = (src) => {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = src;
            script.defer = true;
            script.onload = () => resolve();
            script.onerror = () => reject();
            document.body.appendChild(script);
        });
    };

// function for loading stylesheets
    const loadStylesheet = (href) => {
        return new Promise((resolve, reject) => {
            const link = document.createElement("link");
            link.href = href;
            link.rel = "stylesheet";
            link.onload = () => resolve();
            link.onerror = () => reject();
            document.body.appendChild(link);
        });
    };


    const renderTooltip = (text) => (
        <Tooltip id="cad-tooltip">
            {text}
        </Tooltip>
    );

    return (
        <>
            {isCadAvailable &&
                <a className="preview cursor-pointer" tabIndex="0" onClick={() => {
                    {/* eslint-disable-next-line*/}
                    cdslib.cad.load({
                        "domain": "fastenal", "product": sku,
                        "endpoint": env,
                        "downloadButtonElementId": "cds-cad-download-button",
                        "view2DButtonElementId": "cds-cad-view-2D-button",
                        "viewerContainerElementId": "cds-cad-viewer-container",
                        "viewerIconsContainerElementId": "cds-cad-viewer-icons-container",
                        "postInitCallback" : ()=>{
                            window.addEventListener("popstate",()=>{
                                const cdsModal = document.getElementById("cds-wait-dialog");
                                const cdsModalOverlay = document.getElementById("cds-modal-overlay");
                                cdsModal.style.display = "none";
                                cdsModalOverlay.style.display = "none";
                            });
                        }
                    });
                    setShow3DModal(true);
                    window.onpopstate = () => {
                        setShow3DModal(false);
                        window.onpopstate = () => {}
                    }
                }}>
                    <img src={imgSrc("/static/images/cad-icon.png")} className="product--thumbnail" alt="CAD"></img>
                </a>
            }

            <Modal show={show3DModal} onHide={() => setShow3DModal(false)} className="cad-modal" dialogClassName="shadow-none">
                <Modal.Header className="p-2 bg-primary text-white">
                    <div class="row w-100 mx-1">
                        <div className="col-11">
                            <p className="p-0 m-0">{des}</p>
                        </div>
                        <div className="col-1 pl-4" onClick={() => setShow3DModal(false)}>
                            <i className="fa fa-times cursor-pointer"></i>
                        </div>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <div className="row mx-2">
                        <div id="cds-cad-viewer-icons-container" className="w-100">
                            <div className="row mx-2 mb-1">

                                <div className="col-1 pl-0">
                                    <OverlayTrigger placement="right" trigger="hover"
                                                    overlay={renderTooltip("Left View")}>
                                        {/* eslint-disable-next-line*/}
                                        <img onClick={() => cds.cadViewer.setOrientation(cds.cadViewer.VIEW_LEFT)}
                                             src="https://www.product-config.net/cadviewer/images/icons/camera_left.png"/>
                                    </OverlayTrigger>
                                </div>
                                <div className="col-2"></div>
                                <div className="col-1 pr-0">
                                    {/* eslint-disable-next-line*/}
                                    <img onClick={() => cds.cadViewer.setRenderMode(cds.cadViewer.MODE_SHADED)}
                                         src="https://www.product-config.net/cadviewer/images/icons/solid.png"/>
                                </div>
                                <div className="col-2 px-0 text-primary">Shaded View</div>
                                <div className="col-1 pr-0">
                                    {/* eslint-disable-next-line*/}
                                    <img onClick={() => cds.cadViewer.setRenderMode(cds.cadViewer.MODE_TRANSPARENT)}
                                         src="https://www.product-config.net/cadviewer/images/icons/transparent.png"/>
                                </div>
                                <div className="col-2 pl-0 text-primary">Edges View</div>
                            </div>
                            <div className="row mx-2 mb-1">
                                <div className="col-2 pl-0">
                                    <OverlayTrigger placement="right" trigger="hover"
                                                    overlay={renderTooltip("Right View")}>
                                        {/* eslint-disable-next-line*/}
                                        <img onClick={() => cds.cadViewer.setOrientation(cds.cadViewer.VIEW_RIGHT)}
                                             src="https://www.product-config.net/cadviewer/images/icons/camera_right.png"/>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div className="row mx-2 mb-1">
                                <div className="col-2 pl-0">
                                    <OverlayTrigger placement="right" trigger="hover"
                                                    overlay={renderTooltip("Top View")}>
                                        {/* eslint-disable-next-line*/}
                                        <img onClick={() => cds.cadViewer.setOrientation(cds.cadViewer.VIEW_TOP)}
                                             src="https://www.product-config.net/cadviewer/images/icons/camera_top.png"/>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div className="row mx-2 mb-1">
                                <div className="col-2 pl-0">
                                    <OverlayTrigger placement="right" trigger="hover"
                                                    overlay={renderTooltip("Bottom View")}>
                                        {/* eslint-disable-next-line*/}
                                        <img onClick={() => cds.cadViewer.setOrientation(cds.cadViewer.VIEW_BOTTOM)}
                                             src="https://www.product-config.net/cadviewer/images/icons/camera_bottom.png"/>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div id="cds-cad-viewer-container" className="cds-cad-viewer-container cad-container"></div>
                    </div>
                    <div id="cds-cad-container"
                         className="row w-100 mx-2 my-2 d-flex justify-content-between align-items-center">
                        <div className="col-7 d-flex w-100 p-0">
                            <select id="cds-cad-download-formats"
                                    className="p-2 rounded w-50 border-default-gray mr-3"></select>
                            <button id="cds-cad-download-button" className="btn btn-primary">Download</button>
                        </div>
                        {technicalSpec &&
                            <div className="col-5">
                                <a className="btn btn-secondary p-2 w-100 feco-jsessionid-none" href={
                                    technicalSpec?.fastenalProductStandard
                                }
                                   target="_blank"
                                   data-ol-has-click-handler="">
                                    {label?.fnlProductStandard}</a>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        labels: state.commonReducer.pageLabels
    }
};

export default connect(mapStateToProps, null)(CadModal);