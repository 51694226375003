import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { imgSrc, setJessionId } from '../helpers/CommonScripts';

function VendingMachineView({ labels, vendingDetails, sku }) {
    const [cellInfo, setCellInfo] = useState(vendingDetails && vendingDetails.planogramCell && vendingDetails.planogramCell[0] && vendingDetails.planogramCell[0][0])
    const planogramCells = vendingDetails && vendingDetails.planogramCell;
    let motorPosition = [];
    const merged = [].concat.apply([], planogramCells).filter(Boolean).forEach(element => {
        if (cellInfo.sku === element.sku) {
            motorPosition.push(element.motorPosition)
        }
    });
    const onClickHandleCellInfo = (cellInfo) => {
        setCellInfo(cellInfo);
    }

    return (
        <div className="row bg-primary p-1 pr-4">
            <div className="col-xs-10 col-lg-10 pt-3 p-1 table-responsive">
                <table className="table">
                    <tbody>
                        {
                            planogramCells && planogramCells.map((planogramCell, index) => {
                                const cellsSku = planogramCell.filter(Boolean).map((k) => k.sku && k.sku).filter(Boolean)
                                return (
                                    <>
                                        { cellsSku.length > 0 &&
                                            <tr key={index}>
                                                { 
                                                    planogramCell && planogramCell.map((cell, i) => {
                                                        return (
                                                            <td scope="col" className={`planogram-cell ${cell && cell.sku ? (sku === cell.sku) ? "feco-planogram-bg-blue" : 'bg-white' : 'bg-gray'}`}>
                                                                {cell && cell.sku &&
                                                                    <div className="row w-100 mx-0 d-flex align-items-center">
                                                                        <div className="d-flex align-items-center w-100">
                                                                            <div className="float-left border rounded-circle p-1 pb-0 d-flex align-items-center bg-white">
                                                                                {cell.motorPosition}
                                                                            </div>
                                                                            <div className="text-primary cursor-pointer feco-text-ellipsis ml-1" onClick={() => onClickHandleCellInfo(cell)}>
                                                                                {(cell && cell.customerSku)?cell.customerSku:(cell && cell.sku)}
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex justify-content-center w-100">
                                                                            <div>{cell.qoh}</div>
                                                                        </div>
                                                                        <div className="d-flex w-100 p-3 ie-img-100">
                                                                            <img src={imgSrc(cell.thumbnailUrl)} className="image-fluid" alt="..."/>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        }
                                    </>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="col-xs-2 col-lg-2 pt-3 pl-3 table-responsive">
                <table className="table bg-white overflow-hidden feco-table-fixed-layout">
                    <tbody>
                        <tr className="bg-gray">
                            <td colSpan="2"></td>
                        </tr>
                        <tr className="bg-gray">
                            <td colSpan="2"></td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="text-center">
                                <img src={imgSrc(cellInfo && cellInfo.thumbnailUrl)} className="image-fluid pl-1" alt="..."/>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" className="font-weight-normal">{labels?.sku}:</th>
                            <td className="feco-text-ellipsis" title={cellInfo && cellInfo.sku}>{ cellInfo && <Link className="default-nav-link ml-1 font-weight-normal" to={setJessionId(`/product/details/${ encodeURIComponent(cellInfo.sku) }`)}>{cellInfo.sku }</Link>}</td>
                        </tr>
                        <tr>
                            <th scope="row" className="font-weight-normal">{labels?.custSku}:</th>
                            <td className="feco-text-ellipsis" title={cellInfo && cellInfo.customerSku}>{cellInfo && cellInfo.customerSku}</td>
                        </tr>
                        <tr>
                            <th scope="row" className="font-weight-normal">{labels?.desc}:</th>
                            <td className="feco-text-ellipsis" title={cellInfo && cellInfo.skuDescription}>{ cellInfo && cellInfo.skuDescription }</td>
                        </tr>
                        <tr>
                            <th scope="row" className="font-weight-normal">QOH:</th>
                            <td>{ cellInfo && cellInfo.qoh }</td>
                        </tr>
                        <tr>
                            <th scope="row" className="font-weight-normal">{labels?.Positions}:</th>
                             <td>{ motorPosition.join(",")  }</td>
                        </tr>
                        <tr>
                            <th scope="row" className="font-weight-normal">{labels?.inventoryTurns}:</th>
                            <td>{ cellInfo && cellInfo.inventoryTurns }</td>
                        </tr>
                        <tr>
                            <th scope="row" className="font-weight-normal">{labels?.skuValue}:</th>
                            <td>{ cellInfo && parseFloat(cellInfo.inventoryValue).toFixed(2) }</td>
                        </tr>
                        <tr>
                            <th scope="row" className="font-weight-normal">{labels?.minOrMax}:</th>
                            <td>{ cellInfo && cellInfo.minQty ? cellInfo.minQty : 0} / { cellInfo && cellInfo.maxQty ? cellInfo.maxQty : 0}</td>
                        </tr>
                        <tr className="bg-gray">
                            <td colSpan="2"></td>
                        </tr>
                        <tr className="bg-gray">
                            <td colSpan="2"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};

export default connect( mapStateToProps, null )(VendingMachineView);