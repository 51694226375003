import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { postRequest } from '../apiCalls/ApiCall';
import { setJessionId } from '../helpers/CommonScripts';

function Footer(props) {
   const label = props.labels;
   const cmsdata = JSON.parse(sessionStorage.getItem("cmsData"));
   const footerData = cmsdata?.footer;
   const footerCategoryInfo = footerData?.footerLists;
   const mediaIcons = footerData?.mediaIcons;
   const location = useLocation();

   const punchoutUser = async () => {
      let formData = new FormData();
      formData.append("documentMode", document.documentMode === undefined ? "" : document.documentMode);
      formData.append("userAgent",navigator.userAgent);
      postRequest(`/punchout/log-browser-information`, formData, {}).then((response) => {
        return response
      })
   }

   useEffect(() => {
      if (window.isPunchOutUser) {
         punchoutUser();
      }
   }, [location]);

   function punchoutFooter() {
      return <footer className="ecom-footer bg-primary navbar-dark ie-footer-container" id="ecom-footer">
         <div className="container-fluid p-0">
            <div className="row flex-nowrap text-center">
               <div className="col-sm-12">
                  <div className="feco-trademark">
                     <p className="ecom-copyrights text-white mb-0" dangerouslySetInnerHTML={{__html: footerData?.copyrightNotice}}/>
                  </div>
               </div>
            </div>
         </div>
      </footer>
   }

   function mobileFooter() {
      return <footer class="footer bg-primary mt-auto position-relative text-white">
         <a href="/subscribe"><p className="bg-default-gray text-primary text-center p-2"><small
             className="font-weight-bold">Sign In The Know - Sign up for the latest info and our free magazine</small>
         </p>
         </a>
         <div className="pr-3 pb-3 pl-3" id="accordionFooter">
            <hr className="bg-white"/>
            {footerCategoryInfo && footerCategoryInfo.map((footerCategory, index) => {
               return (
                   <>
                      <button data-toggle="collapse" data-target={'#' + footerCategory.title?.replaceAll(" ","_")}
                              aria-expanded="false" aria-controls={footerCategory.title?.replaceAll(" ","_")}
                              className="btn btn-link text-white w-100 text-left text-decoration-none p-0 font-weight-bold mb-1 collapsed">
                                 {footerCategory.title}<i className="fa fa-chevron-up rotate-icon float-right" aria-hidden="true"></i>
                      </button>
                      <div id={footerCategory.title?.replaceAll(" ","_")} className="collapse" aria-labelledby="headingOne" data-parent="#accordionFooter">
                        <ul className="list-unstyled pl-2">
                           {
                              footerCategory.items && footerCategory.items.map((item, key) => {
                                 return (
                                     <li key={key}><a class="nav-link text-white px-1 pb-1 pt-0" rel="noreferrer"
                                                      href={setJessionId(item.href)} target={item.target}>{ item.title }</a></li>
                                 )
                              })
                           }
                        </ul>
                     </div>
                      <hr className="bg-white"/>
                   </>

               )
            })}
            <div className="ecom-footer-heading pt-1">{footerData?.mediaIconsTitle}</div>
            <nav className="nav ecom-footer-social">
               <ul className="nav ecom-footer-social">
                  {
                     mediaIcons && mediaIcons.map((icon, index) => {
                        return (
                            <li className="nav-item" key={`media-icon-title-${index}`}>
                               <a href={icon.url} target="_blank" rel="noreferrer"
                                  className="nav-link feco-jsessionid-none" id={icon.type}></a>
                            </li>
                        )
                     })
                  }
               </ul>
            </nav>
            <div className="ecom-footer-row mt-4 d-flex">
               <div className="ecom-slogan">
                  <img src={footerData?.slogan} height="40"/>
                  <p className="ecom-copyrights text-white"><small dangerouslySetInnerHTML={{__html: footerData?.copyrightNotice}}></small></p>
               </div>
            </div>
         </div>
      </footer>
   }

   function defaultFooter() {
      return <>
         <div className="d-view">
            <footer className="ecom-footer bg-primary navbar-dark ie-footer-container" id="ecom-footer">
               <div className="container-fluid p-0">
                  <div className="row">
                     {
                         footerCategoryInfo && footerCategoryInfo.map((footerCategory, index) => {
                            return (
                                <div className="col-6 col-md-3 col-lg-2" key={index}>
                                   <div className="ecom-footer-heading">{footerCategory.title}</div>
                                   <ul className="navbar-nav flex-sm-column pl-2">
                                      {
                                          footerCategory.items && footerCategory.items.map((item, key) => {
                                             return (
                                                 <li className="nav-item" key={key}>
                                                    <a id="cardinal_fastener" href={setJessionId(item.href)} target={item.target} className="nav-link" rel="noreferrer">{ item.title }</a>
                                                 </li>
                                             )
                                          })
                                      }
                                   </ul>
                                </div>
                            )
                         })
                     }
                     <div className="col-6 col-md-3 col-lg-2">
                        <div className="clear text-white ie-width-full ie-float-left">
                           <div className="exclusive-label font-weight-bold ecom-footer-heading">{footerData?.stayInTheKnow}</div>
                           <div className="text-white pt-1">{footerData?.signUpDescription}</div>
                           <a href={footerData?.buttonURL}>
                              <div className="btn button action mt-2">{footerData?.signUpLabel}</div>
                           </a>
                        </div>
                        <p className="mb-0 pull-left text-md-left text-sm-left text-white p-0 ie-width-full ie-float-left">
                           {footerData?.contactInformation}
                        </p>
                     </div>
                     <div>
                        <div className="ecom-footer-heading">{ footerData?.mediaIconsTitle}</div>
                        <ul className="nav ecom-footer-social">
                           {
                               mediaIcons && mediaIcons.map((icon, index) => {
                                  return (
                                      <li className="nav-item"  key={`media-icon-title-${index}`}>
                                         <a href={icon.url} target="_blank" rel="noreferrer" className="nav-link feco-jsessionid-none" id ={icon.type} area-label={icon.type}></a>
                                      </li>
                                  )
                               })
                           }
                        </ul>
                     </div>
                  </div>

                  <div className="row">
                     <div className="col-sm-12 col-lg-8">
                        <div className="feco-trademark">
                           <img src={footerData?.slogan} width="45%" height="30" alt="content slogan" />
                           <p className="ecom-copyrights text-white mb-0 p-0 pt-2" dangerouslySetInnerHTML={{ __html: footerData?.copyrightNotice }}></p>
                        </div>
                     </div>
                  </div>
               </div>
            </footer>
         </div>
      </>
   }

   useEffect(() => {
      var acc = document.getElementsByClassName("mb_cust_accordion");
      for (var i = 0; i < acc.length; i++) {
         acc[i].addEventListener("click", function() {
            var panel = this.nextElementSibling;
            if (panel.style.display === "block" && this.getElementsByClassName("fa-chevron-down")[0]) {
               panel.style.display = "none";
               this.getElementsByClassName("fa-chevron-down")[0].classList.add('fa-chevron-right');
               this.getElementsByClassName("fa-chevron-down")[0].classList.remove('fa-chevron-down');
            } else if(this.getElementsByClassName("fa-chevron-right")[0]) {
               panel.style.display = "block";
               this.getElementsByClassName("fa-chevron-right")[0].classList.add('fa-chevron-down');
               this.getElementsByClassName("fa-chevron-right")[0].classList.remove('fa-chevron-right');
            }
         });
      }
   },[props]);

   if (window.isPunchOutUser)
      return punchoutFooter();
   else if (props.isMobile)
      return mobileFooter();
   else if (footerCategoryInfo !== undefined)
      return defaultFooter();
   else
      return "";
}

const mapStateToProps = (state) => {
   return {
      labels: state.commonReducer.pageLabels
   }
};

export default connect( mapStateToProps, null )(Footer);