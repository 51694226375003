import React from "react";
import { connect } from "react-redux";

function StockData(props) {
  const label = props.labels;
  const stockData = props.stockData;

  return (
    <div className="mt-4">
      <table className="table">
        <thead>
        <tr>
          <th className="font-weight-600">{label?.businessUnit}</th>
          <th className="font-weight-600">{label?.availability}</th>
          <th className="font-weight-600">{label?.quantity}</th>
          <th colspan="2">{label?.distanceAndMiles}</th>
        </tr>
        </thead>

        <tbody>
        {
          stockData.map((data, index) => {
            return (
              <tr key={`data-${index}`}>
                <td>
                  <a href={`/locations/details/${data.buCode}`}>{data.buCode}</a>
                </td>

                <td>
                  {
                    data.availability !== 1 ?
                      <span className="border item-availability text-muted border-secondary">
                        <i className="fas fa-times item-availability-icon bg-secondary mr-4" aria-hidden="true"></i>
                        {label?.outOfStock}
                      </span>
                      :
                      <span>
                        <i className="fas fa-sqare bg-success mr-1" aria-hidden="true" style={{padding: "0.28rem"}}></i>
                        {label?.inStock}
                      </span>
                  }
                </td>

                <td>
                  {data.qtyAvailable}
                </td>

                <td>
                  {(data.distanceInMiles).toFixed(2)}
                </td>
              </tr>
            )
          })
        }
        </tbody>
      </table>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};

export default connect( mapStateToProps, null )(StockData);