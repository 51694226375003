import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { postRequest } from '../apiCalls/ApiCall';
import { AGGREGATIONS_URL } from '../constants/Constants';
import AggregationValue from "./AggregationValue";
import cx from 'classnames';
import { useLocation } from "react-router-dom";
import {PAGE_LABELS as labels} from "../constants/Labels";

function Aggregations(props) {
  const label = props.labels;
  const [aggregation, setAggregation] = useState({});
  const [aggregationValues, setAggregationValues] = useState([]);
  const [search, setSearch] = useState("");
  const [showAll, setShowAll] = useState(false);
  delete props.responseData.searchRequest.fsi;
  const searchRequest = props.responseData.searchRequest;
  const suggestedAttributes = props.responseData.suggestedAttributes;
  const attributeFilters = {...searchRequest["attributeFilters"],...props.responseData?.suggestedAttributes} || {};
  const showAllArray = !aggregation.showAll ? searchRequest["showAll"] || [] : null;
  const expressData = props.responseData.expressMessage || "";
  const cache = useRef({});
  const isMounted = useRef(true);
  const [toggleIcon, setToggleIcon] = useState(([0, 1].includes(props.aggregationIndex) || ["Green Certifications", "Supplier Diversity", "Recycling Program", "Green Building Qualifier"].includes(aggregation.displayName)));
  const location = useLocation();

  useEffect(() => {
    setSearch("");
    delete cache.current[location.pathname + props.aggregation?.aggregationName];
    setShowAll(false);
    setAggregation(props.aggregation);
    setAggregationValues(props.aggregation?.aggregationValues);
    return () => {
      isMounted.current = false;
      const divID = document.getElementById(`scroll_${props.aggregationIndex}`);
      divID && (divID.scrollTop = 0);
    }
  }, [location, props.aggregation]);

  useEffect(() => {
    if (search === "") {
      const aggValues = aggregation.aggregationValues;
      setAggregationValues(aggValues ? aggValues : props.aggregation.aggregationValues);
    }
    return () => {
      isMounted.current = false;
      const divID = document.getElementById(`scroll_${props.aggregationIndex}`);
      divID && (divID.scrollTop = 0);
    }
  }, [search]);

  const handleClickShowAll = (aggregationName) => {
    const bodyContent = Object.assign({ "name": `${aggregationName}` }, searchRequest);
    const fetchData = async () => {
      let aggregations;
      if (cache.current[location.pathname + aggregationName]) {
        aggregations = cache.current[location.pathname + aggregationName]
      } else if (aggregation.showAll) {
        const result = await postRequest(AGGREGATIONS_URL, bodyContent);
        if (isMounted.current) {
          aggregations = result.aggregations && result.aggregations[0];
          cache.current[location.pathname + aggregationName] = aggregations;
        }
      }
      aggregations = aggregations ? aggregations : props.aggregation;
      if (aggregations && isMounted.current) {
        setAggregation(aggregations);
        if (search === "") {
          setAggregationValues(aggregations.aggregationValues);
        } else {
          const filteredAggregations = aggregations.aggregationValues.filter(aggregation => {
            return aggregation.displayName.split("&trade").join('').split("&reg").join('').toLowerCase().includes(search.toLowerCase());
          });
          setAggregationValues(filteredAggregations);
        }
        setShowAll(false);
      }
    };
    fetchData();
  }
  const searchAggregation = () => {
    isMounted.current = true;
    handleClickShowAll(aggregation.aggregationName);
    setTimeout(() => {
      const divID = document.getElementById(`scroll_${props.aggregationIndex}`);
      divID && (divID.scrollTop = 0);
    }, 150);
  }

  const aggregationExpand = aggregationValues.map(function (el) {
    return Object.values(attributeFilters).join().split(',').includes(el.filterValue)
  });

  const aggregationShow = (["Green Certifications", "Supplier Diversity", "Recycling Program", "Green Building Qualifier"].includes(aggregation.displayName) && !aggregationExpand.includes(true)) ? 'd-none' : '';
  let scrollBusy = false;
  const handleScroll = () => {
    if (!scrollBusy) {
      scrollBusy = true;
      return (document.getElementById(`showAll_${props.aggregationIndex}`)?.click());
    }
  };
  const setSearchVal = (event) => {
    if (event.target.value) {
      setSearch(event.target.value);
    } else {
      setSearch("");
    }
    if (event.target.value === "") {
      handleClickShowAll(aggregation.aggregationName);
    }
  }

  const setMounted = () => {
    isMounted.current = true;
    setShowAll(true);
  }
  return (
    <div className={`card ${aggregationShow}`} id={aggregation.displayName}>
      <div className="bg-primary text-sm-left text-white p-1 pl-2 pr-2" aria-expanded="true" data-toggle="collapse"
        data-target={`#aggregationId_${props.aggregationIndex}`} aria-controls={`aggregationId_${props.aggregationIndex}`}
        onClick={() => { setToggleIcon(!toggleIcon) }} role="button">
        <i className={cx('fas justify-content-end float-right', {
          ['fa-chevron-up']: toggleIcon,
          ['fa-chevron-down']: !toggleIcon
        })} />
        {aggregation.displayLabel}
      </div>
      <div className={`collapse ${([0, 1].includes(props.aggregationIndex) || (aggregationValues[0] && attributeFilters[aggregationValues[0].filterName])
        || ["Green Certifications", "Supplier Diversity", "Recycling Program", "Green Building Qualifier"].includes(aggregation.displayName)) ? "show" : ""}`} id={`aggregationId_${props.aggregationIndex}`}>
        {props.aggregation.aggregationValues && props.aggregation.aggregationValues.length > 5 &&
          <div className="input-group ecom-searchbar ecom-searchbar-small ie-left-aggr-search">
            <input type="text" className="form-control ecom-search-input" placeholder={aggregation.displayName} onChange={e => { setSearchVal(e) }} onKeyDown={(e) => { if (e.code === "Enter") { searchAggregation(`aggregationId_${props.aggregationIndex}`) } }} />
            <div className="input-group-append disabled">
              <button className="btn ecom-button-search ecom-button-plain ecom-button-inn" aria-label={labels?.search} type="button" onClick={e => { searchAggregation(`aggregationId_${props.aggregationIndex}`) }}>
                <i className="fas fa-search" aria-hidden="true" />
              </button>
            </div>
          </div>
        }
        {aggregationValues.length > 0 && <>
          <div className="card-body feco-left-aggr-item-scroll px-0 py-1" id={`scroll_${props.aggregationIndex}`} onScroll={(e) => { handleScroll(e); setMounted(); }}>
            {
              aggregationValues && aggregationValues.map((aggregationValue, i) => {
                return (
                  <AggregationValue key={i} aggregationValue={aggregationValue} index={i} expressData={expressData} searchRequest={searchRequest} showAllArray={showAllArray} attributeFilters={attributeFilters} suggestedAttributes={suggestedAttributes} appendCategoryAndReplace={props.appendCategoryAndReplace} aggName={aggregation.displayName}
                  />
                )
              })
            }
          </div>
          {
            (aggregation.showAll && (props.aggregation.count === aggregation.count)) && <div className="card-footer show-all-footer pointer" id={`showAll_${props.aggregationIndex}`} onClick={() => { handleClickShowAll(aggregation.aggregationName); setMounted(); }}>
              {showAll ? label?.Loading : label?.showAll}
            </div>
          }
        </>
        }
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};

export default connect(mapStateToProps, null)(Aggregations);