import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { imgSrc } from '../helpers/CommonScripts';

function ComplianceDetail(props) {
	const label = props.labels;
	const compliances = props.compliances;
	const show = props.show;
    return (
        <Modal show={show} onHide={props.handleClose} animation={false} className="feco-modal-compliance">
            <Modal.Header className="feco-modal-header-primary p-2"  closeButton>
                <Modal.Title as="h6">{label?.compliance}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="compliance-body-content">
                    <div className="row ie-10-d-block">
                        <div className="feco-compliance-item">
                            <ul className="nav-item pl-0 mb-0">
                            {
                                compliances && compliances.map((compliance, i) => {
                                    return (
                                        <>{ ((compliance.complianceName === "Restricted Product" && (compliance["Restriction Areas"] !== undefined)) || (compliance.complianceName !== 'Restricted Product')) &&
                                            <li className="nav-link d-flex pt-0 ie-10-d-block " key={i}>
                                                <span className="feco-compliance-icon-pdp mr-2 feco-w-30 text-center">
                                                    <img src={imgSrc(compliance.Image)} alt={compliance["Alt Text"]} />
                                                </span>
                                                <div>
                                                    <div className="mb-0 font-weight-bold">{compliance.complianceNameLabel}</div>
                                                    <div className="prdcompliance-desc">
                                                        {compliance.Description}
                                                        <ul>
                                                            {
                                                                compliance.Values && compliance.Values.map((value, index) => {
                                                                    return (
                                                                        <li key={`value_${index}`}>{value}</li>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                              compliance.URLs && compliance.URLs.map((value, index) => {
                                                                return (
                                                                  value && value["File Name"] && value["URL"] &&
                                                                  <li key={`value_${index}`}>
                                                                    <a href={value["URL"]}
                                                                       target="_blank"  rel="noreferrer" className="feco-jsessionid-none">{value["File Name"]} &nbsp;
                                                                      <i className="fas fa-external-link-alt"></i>
                                                                    </a>
                                                                  </li>
                                                                )
                                                              })
                                                            }
                                                            {
                                                                compliance["Restriction Areas"] && compliance["Restriction Areas"].map((value, index) => {
                                                                    return (
                                                                        <li key={`area_${index}`}>{value}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>}
                                        </>
                            )
                        })
                    }
                                        </ul>
                                    </div>
                                </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};

export default connect( mapStateToProps, null )(ComplianceDetail);