import React, { useState } from 'react';
import { connect } from 'react-redux';
import Compare from './Compare'
import { removeProductCompare, removeAllProductsFromCompare } from '../actions/compareActions';
import { imgSrc } from '../helpers/CommonScripts';
import { sendCompareProductsAnalytics } from '../helpers/CommonScripts';

function CompareFooter(props) {
	const label = props.labels;
	const compareProducts = props.compareProducts;
	const [showComparePopup, setShowComparePopup] = useState(false);
	const sku =  compareProducts && compareProducts.map(function (obj) {
        return obj.sku
	});

	const removeProductCompareList = (sku) => {
	  if(document.getElementById(sku) !== null) {
      document.getElementById(sku).checked = false;
    }
		props.removeProductCompare(sku);
	};

	const removeAllProductsFromCompareList = () => {
		sku.map(function (sku_no) {
      if(document.getElementById(sku_no) !== null) {
        document.getElementById(sku_no).checked = false;
      }
		});
		props.removeAllProductsFromCompare(sku);
	};

	const compareMenuItems = [];
	for (var i=0; i < (5-compareProducts.length); i++) {
		compareMenuItems.push(i)
	}

	const onClickCompare = (clickedCompare) =>{
		if (sku.length > 1 && clickedCompare){
			setShowComparePopup(clickedCompare);
		}else{
			setShowComparePopup(clickedCompare);
		}
		sendCompareProductsAnalytics(sku);
	};

	if (compareProducts <= 0) return (<> </>);
	return (
		<div className="col feco-compare bg-light-gray position-fixed fixed-bottom" id="compare_footer">
					<div className="feco-container-fix align-items-center">
						<div className="d-flex align-items-center">
						<div className="flex-grow-1 feco-compare-sm-scroll">
							<div className="row mx-0">
								{compareProducts && compareProducts.map((product, i) => {
									return (
										<div className="col pl-0 p-1 feco-compare-col-sm ie9-compare-item" key={`compare_${i}`}>
											<div className="d-flex justify-content-center bg-white h-100">
												<div className="d-flex flex-row justify-content-center align-items-center p-2 no-gutters rounded-0 w-100">
													<div className="col border p-1 feco-compare-thumbnail ie-float-left">
														<img src={ product.image} className="rounded" alt="..." />
													</div>
													<div className="col ml-2">
														<div className="card-text text-primary">{label?.sku}</div>
														<div className="card-text">{ product.sku }</div>
													</div>
													<span className="text-danger mt-n4 ie-compare-close cursor-pointer" onClick={()=> {removeProductCompareList(product.sku)}}><i aria-hidden="true" className="far fa-times-circle text-danger" onClick={(e) => {onClickCompare(false)}}></i></span>
												</div>
											</div>
										</div>

									)})
								}
								{compareMenuItems.map((item, i) => {
									return (
										<div className="col pl-0 p-1 feco-compare-col-sm ie9-compare-item" key={i}>
											<div className="d-flex justify-content-center bg-white h-100">
												<div className="d-flex flex-row justify-content-center align-items-center p-2 no-gutters rounded-0 w-100">
													<i className="fa fa-2x fa-balance-scale-left ecom-icon-size-xlg text-center text-light-gray"
													 aria-hidden="true"></i>
												</div>
											</div>
										</div>
									)
								})
								}
							</div>
						</div>
						<div className="ie-compare-button ie-float-right feco-ml-md-1 ml-2">
							<button type="button" className="btn btn-primary mr-2" data-toggle="modal" data-target="#compareProducts" onClick={(e) => {onClickCompare(true)}}>{label?.compare}</button>
							<button type="button" className="btn btn-link"  style={{width: "80px"}} onClick={()=> {removeAllProductsFromCompareList()}}>{label?.clearAll}</button>
						</div>
					</div>
						<div className="col-sm">
							<div className="modal fade compare" id="compareProducts" tabIndex="-1" role="dialog" aria-labelledby="compareModalLabel" aria-hidden="true">
								<div className={`modal-dialog ${ sku.length > 3 ? 'modal-xl' : 'modal-lg'}`} role="document">
									<div className="modal-content">
										<div className="modal-header card-header bg-primary text-white rounded-0 p-2">
											<h6 className="modal-title" id="compareModalLabel">{label?.productCompare} </h6>
											<button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true" onClick={(e) => {onClickCompare(false)}}>x</span>
											</button>
										</div>
										<div className="modal-body">
											{ (showComparePopup && sku.length > 1) ? <Compare sku={ sku } />  : <center>{label?.AddToCompareAtLeastOne}</center>}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
	)
}

const mapStateToProps = state => {
    return{
		compareProducts: state.compareReducer.compareItems,
		labels: state.commonReducer.pageLabels
    }
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeProductCompare: (sku) => { dispatch(removeProductCompare(sku)) },
		removeAllProductsFromCompare: (skus) => { dispatch(removeAllProductsFromCompare(skus)) }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareFooter)