export function handleResponse(response) {
  if ("results" in response) {
      let responseData = JSON.stringify(response.results).split("mp_").join("").split("[TM]").join("&trade;").split("[REG]").join("&reg;").split("[DEG]").join("&deg;");
      return responseData
  }
  if ("data" in response) {
      let responseData = JSON.stringify(response.data).split("mp_").join("").split("[TM]").join("&trade;").split("[REG]").join("&reg;").split("[DEG]").join("&deg;");
      return JSON.parse(responseData);
  }
  return JSON.parse(JSON.stringify(response).split("mp_").join("").split("[TM]").join("&trade;").split("[REG]").join("&reg;").split("[DEG]").join("&deg;"));
}

export function handleError(error) {
  if (error.response) {
    return error.response;
  } else if (error.name === "Error") {
    error["data"] = {status: 500, error: error.message}
    return error["data"]
  }
  return error;
}