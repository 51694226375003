import React, {useEffect} from "react";
import SubSectionTable from "./SubSectionTable";
import {useRect} from "./useRect";
import useStickyHeader from "./UseStickyHeader";
import { imgSrc } from '../../helpers/CommonScripts';

function SubSection(props) {
	const{rect, rectRef} = useRect();
	const {sectionRef, isSticky, minTopRef, bottomDiffRef, headerRef, fixedHeaderTop} = useStickyHeader();

	useEffect(() => {
		headerRef.current = rectRef.current;
		minTopRef.current = props.calculatedTop;
	}, [rectRef,props.calculatedTop]);

	useEffect(() => {
		props.setBottomDiffRef(rect ? rect.height + bottomDiffRef.current : 0);
	}, [isSticky]);

	function setBottomDiffRef(value) {
		bottomDiffRef.current = value;
	}

	return (
		<div ref={sectionRef} className="col-md-12 p-0">
			<div className={isSticky ? "p-1 frozen pl-2" : "p-1 bg-gray pl-2"} ref={rectRef}
								 style={rect ? {marginBottom : -rect.height, width : rect.width, top : fixedHeaderTop ? fixedHeaderTop : ""} : {}}>
				{
					props.subsection.rowSubHeader && props.subsection.rowSubHeader.map((rowSubHeader, rowSubHeaderIndex) => {
						return (
							<span key={rowSubHeaderIndex}>
								<span dangerouslySetInnerHTML={{ __html: rowSubHeader.name}}></span> : <span dangerouslySetInnerHTML={{ __html: rowSubHeader.value }}></span> <br />
							</span>
						)
					})
				}
			</div>
			<div style={rect ? {paddingTop : rect.height} : {}}></div>
			{(props.subsection.img || props.subsection.desc) &&
			<div className="row p-1">
				{props.subsection.img &&
				<div className="col-md-1">
					<img src={imgSrc(props.subsection.img)} alt="..." className="img-responsive"/>
				</div>
				}
				{props.subsection.desc &&
				<div className={props.subsection.img ? "col-md-11":"col"}>
					<span dangerouslySetInnerHTML={{ __html: props.subsection.desc}}></span>
				</div>
				}
			</div>
			}
			<SubSectionTable subsection ={props.subsection}
											 columnHeaders = {props.columnHeaders}
											 handleClickShowProductDetails ={props.handleClickShowProductDetails}
											 calculatedTop = {rect ? props.calculatedTop + rect.height : 0}
											 setBottomDiffRef ={setBottomDiffRef}/>
		</div>
	)
}

export default SubSection;