export const onChangeInputQuantity = (event) => {
  var max = parseInt(event.target.getAttribute("max"));
  var min = parseInt(event.target.getAttribute("min"));
  if (event.target.value > max)
  {
    event.target.value = max
  }
  else if (event.target.value < min)
  {
    event.target.value = min
  }
};