import React, { useState, useEffect } from 'react';
import { Link,useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { addToCart } from '../actions/cartActions';
import { getResponse, postRequest } from '../apiCalls/ApiCall';
import {
  AddToShoppingCart, errorMessage,
  imgSrc,
  sendPDAnalyticsData,
  setfcAndJessionId,
  setJessionId
} from '../helpers/CommonScripts';
import { onChangeInputQuantity } from './OnchangeInputQuantity';
import SimilarProducts from "./SimilarProducts";
import ComplianceDetail from './ComplianceDetail';
import CheckOtherLocations from './CheckOtherLocations';
import {
  ADD_TEMPLATE_URL, ADD_TO_CART_RETURN_TO_REQUISITION,
  BIN_VENDING_STOCK_CHECK_URL,
  CHECK_ADDITIONAL_RESULTS, COMPLETE_CHECKOUT,
  GET_INVENTORY_LOCATIONS_URL,
  XREF_PRODUCTS_URL
} from '../constants/Constants';
import VendingMachine from './VendingMachine';
import BinStock from './BinStock';
import AddToCartTemplate from './AddToCartTemplate';
import ViewAll from './ViewAll';
import ProductAvailability from './ProductAvailability';
import cx from 'classnames';
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {PAGE_LABELS as label} from "../constants/Labels";
import {onkeydownInputQuantity} from "./OnkeydownInputQuantity";
import ReactDOM from 'react-dom';
import PunchoutForm from "./PunchoutForm";
import CadModal from "./CadModal";

function ProductDetail(props) {
  const label = props.labels;
  const location = useLocation();
  const isLevelParam = queryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [productDetail, setProductDetail] = useState({});
  const [exactCustomerSkuMatch, setExactCustomerSkuMatch] = useState(null);
  const [exactFastenalSkuMatch, setExactFastenalSkuMatch] = useState(null);
  const [customerSku, setCustomerSku] = useState("");
  const [complianceDetail, setComplianceDetail] = useState([]);
  const [show, setShow] = useState(false);
  const [showLocations, setShowLocations] = useState(false);
  const [productEda, setProductEda] = useState("");
  const [priceDisplayData, setPriceDisplayData] = useState([]);
  const [inventoryList, setInventoryList] = useState({});
  const [showVendingSelection, setShowVendingSelection] = useState(false);
  const [showBinSelection, setShowBinSelection] = useState(false);
  const [showVendingMachine, setShowVendingMachine] = useState(false);
  const [vendingMachineDetails, setVendingMachineDetails] = useState({});
  const [showBinStock, setShowBinStock] = useState(false);
  const [binStockList, setBinStockList] = useState({});
  const [vendingDeviceList, setVendingDeviceList] = useState([]);
  const [binDeviceList, setBinDeviceList] = useState([]);
  const [exactCustomerSkuBanner, setExactCustomerSkuBanner] = useState(true);
  const [toggleIcon, setToggleIcon] = useState(false)
  const [viewAllPopup, setViewAllPopup] = useState(false);
  const [altOrRelatedTitle, setAltOrRelatedTitle] = useState("");
  const [additionalResults, setAdditionalResults] = useState(false);
  const [xrefProducts, setXrefProducts] = useState("");
  const [productImage, setProductImage] = useState("");
  const [xmlData, setXmlData] = useState("");
  const env =  window.location.host.startsWith("fastconnect.fastenal") ? "prod" : "qa";

  useEffect(() => {
    setProductImage("");
    setInventoryList("");
    setShowBinSelection(false);
    const result = props.responseData;
    if(exactCustomerSkuBanner) {
      setAllResults(result);
    }else {
      delete result.exactCustomerSkuMatch;
      setAllResults(result);
    }
    setViewAllPopup(false);
    setAltOrRelatedTitle(null);
    if (window.innerWidth > 992) { setPdpHeight();}
  }, [props, exactCustomerSkuBanner]);

  useEffect(() => {
    setXrefProducts("");
    fetchData();
  }, [productDetail, window?.userLoggedIn])

  const setPdpHeight = () => {
    let siteHeaderHeight = document.getElementById("ecom-header")?.getBoundingClientRect()?.height;
    let breadcrumbHeight = document.getElementById("ecom-breadcrumb")?.getBoundingClientRect()?.height;
    let footerHeight = document.getElementById("ecom-footer")?.getBoundingClientRect()?.height;
    let pdpDiv = document.getElementById("pdp-details");
    if (pdpDiv) {
      pdpDiv.style.minHeight = (window.innerHeight - (siteHeaderHeight + breadcrumbHeight + footerHeight)) + "px";
    }
  }

  const setAllResults = (res) => {
    if(res.exactCustomerSkuMatch && res.exactFastenalSkuMatch) {
      setProductDetail(res.exactCustomerSkuMatch);
      setExactCustomerSkuMatch(res.exactCustomerSkuMatch);
      setExactFastenalSkuMatch(res.exactFastenalSkuMatch);
      setProductEda(res.exactCustomerSkuMatch.productEda ? res.exactCustomerSkuMatch.productEda : null);
      setCustomerSku((res.exactCustomerSkuMatch.customerSku) || "");
      setPriceDisplayData((res.exactCustomerSkuMatch.priceDisplayData) || []);
    }else if ((res.exactCustomerSkuMatch !== undefined && res.exactFastenalSkuMatch === undefined)) {
      setProductDetail(res.exactCustomerSkuMatch);
      setExactCustomerSkuMatch(res.exactCustomerSkuMatch);
      setExactFastenalSkuMatch(null);
      setProductEda(res.exactCustomerSkuMatch.productEda ? res.exactCustomerSkuMatch.productEda : null);
      setCustomerSku((res.exactCustomerSkuMatch.customerSku) || "");
      setPriceDisplayData((res.exactCustomerSkuMatch.priceDisplayData) || []);
    }else if((res.exactCustomerSkuMatch === undefined && res.exactFastenalSkuMatch !== undefined)){
      setProductDetail(res.exactFastenalSkuMatch);
      setExactCustomerSkuMatch(null);
      setExactFastenalSkuMatch(res.exactFastenalSkuMatch);
      setProductEda(res.exactFastenalSkuMatch.productEda ? res.exactFastenalSkuMatch.productEda : null);
      setCustomerSku((res.exactFastenalSkuMatch.customerSku) || "");
      setPriceDisplayData((res.exactFastenalSkuMatch.priceDisplayData) || []);
    }else{
      setProductDetail(res.productDetail);
      setExactCustomerSkuMatch(null);
      setExactFastenalSkuMatch(null);
      setProductEda(res.productDetail && res.productDetail.productEda);
      setCustomerSku((res.productDetail && res.productDetail.customerSku) || "");
      setPriceDisplayData((res.productDetail && res.productDetail.priceDisplayData) || []);
    }
  };

  const setFastenalSkuBanner = () => {
    setExactCustomerSkuBanner(false);
  };


  const fetchData = async () => {
    if (productDetail?.sku && ((window?.userLoggedIn &&  !window.isPunchOutUser) || (window.isPunchOutUser && window.myInventoryLocationsEnabled))) {
        const inventoryLocations = await getResponse(`${GET_INVENTORY_LOCATIONS_URL}?sku=${productDetail.sku}`, {});
        setInventoryList(inventoryLocations);
    }
    if (productDetail?.sku && (exactCustomerSkuMatch !== null || exactFastenalSkuMatch !== null)) {
      const additionalResults = await getResponse(`${CHECK_ADDITIONAL_RESULTS}?query=${productDetail.sku}&fsi=1`, {});
      setAdditionalResults(additionalResults);
    }
    if (productDetail?.alternativeItems || productDetail?.relatedItems) {
      const xrefProductList = await postRequest(XREF_PRODUCTS_URL, { "alternateItems": productDetail?.alternativeItems, "relatedItems": productDetail?.relatedItems });
      xrefProductList["resultType"] = productDetail?.alternativeItems ? "Alternative Items" : "Related Items"
      setXrefProducts(xrefProductList);
    }
  };

  useEffect(() => {
    if (xrefProducts.alternateItem) {
      sendPDAnalyticsData(productDetail, "view_item", "Alternative Item");
    }
    if (xrefProducts.relatedItem) {
      sendPDAnalyticsData(productDetail,"view_item", "Related Item");
    }
  }, [xrefProducts]);


  const showComplianceDetails = (compliances) => {
    setComplianceDetail(compliances);
    setShow(true);
    document.body.className += 'modal-open';
  }
  const handleClose = () => {
    setShow(false);
    setShowLocations(false);
    setShowVendingMachine(false);
    setShowBinStock(false);
    document.body.className = '';
  };
  const showOtherLocations = () => {
    setShowLocations(true);
    document.body.className += 'modal-open';
  }

  const showVendingDropDown = () => {
    setShowVendingSelection(!showVendingSelection);
    setVendingDeviceList([]);
  }
  const showBinDropDown = () => {
    setShowBinSelection(!showBinSelection);
    setBinDeviceList([]);
  }

  const openVendingMachinePopup = (deviceDetails) => {
    postRequest(BIN_VENDING_STOCK_CHECK_URL, deviceDetails).then((res) => {
      setVendingMachineDetails(res);
      document.body.className += 'modal-open';
    });
    setShowVendingMachine(true);
  }

  const openBinStockPopup = (deviceDetails) => {
    postRequest(BIN_VENDING_STOCK_CHECK_URL, deviceDetails).then((res) => {
      setBinStockList(res);
      document.body.className += 'modal-open';
    });
    setShowBinStock(true);
  }

  const handleChangeVending = (event) => {
    let obj = JSON.parse(event.target.value);
    setVendingDeviceList(obj);
  };

  const handleChangeBin = (event) => {
    let obj = JSON.parse(event.target.value);
    setBinDeviceList(obj);
  };

  const handleViewAll = (view_all = false, title=null) => {
    if(view_all === true) {
      document.body.className += 'modal-open';
    } else {
      document.body.className = '';
    }
    setViewAllPopup(view_all);
    setAltOrRelatedTitle(title);
  };
  const checkKeysInObject = (object, key) => {
    return (key in object) && Object.keys(object).length > 1 || !(key in object) && Object.keys(object).length > 0;
  }

  const checkErrorInInventoryResponse = (object) => {
    var key = "statusText";
    if (key in object){
      if(object[key]=="Internal Server Error"){
        return false
      }
      else{
        return true
      }
    }
    else{
      return true
    }
  }

  const compliancesExists = productDetail?.compliances !== undefined ? productDetail?.compliances?.length === 1 ? (productDetail?.compliances[0].complianceName === "Restricted Product" ? (productDetail?.compliances[0]["Restriction Areas"] !== undefined || productDetail?.compliances[0].Areas !== undefined) : true) : productDetail?.compliances.length > 0 : false;

  return (
    <>
      <input type="hidden" name="pageVal" value="Product Details"/>
      <input type="hidden" name="data-sku" value={productDetail?.sku}/>
      { showBinStock && < BinStock handleClose={handleClose} show={showBinStock} binStockList={ binStockList } sku={productDetail?.sku}/>}
      { showVendingMachine && <VendingMachine handleClose={handleClose} show={showVendingMachine} vendingMachineDetails={ vendingMachineDetails } sku={productDetail.sku}/>}
      { show && <ComplianceDetail compliances={complianceDetail} handleClose={handleClose} show={show} />}
      { showLocations && <CheckOtherLocations handleClose={handleClose} showLocations={showLocations} sku={productDetail?.sku} />}
      { viewAllPopup && <ViewAll handleViewAll={handleViewAll} title={altOrRelatedTitle} skus={altOrRelatedTitle === "Alternative Items" ? xrefProducts?.alternativeItems : xrefProducts?.relatedItems} onClickAddToCart={ AddToShoppingCart } addToCart={ props.addToCart} />}
      { (!productDetail?.discontinued && additionalResults) && (exactCustomerSkuMatch !== null && exactFastenalSkuMatch !== null ?
        (<div className="alert alert-success" role="alert">
          {label?.exactCustomerProductMatch} <a href="#" className="p-2" onClick={(e) => { setFastenalSkuBanner()}}>{label?.clickHere}</a>{label?.forAdditionalSearchResults}
        </div>)
      : (exactCustomerSkuMatch !== null && exactFastenalSkuMatch === null) ?
        (<div className="alert alert-success" role="alert">
          {label?.exactCustomerProductMatch}  <Link to={setJessionId(`/product?query=${productDetail?.sku}&skipSkuMatch=Y`)} data-ol-has-click-handler="">{label?.clickHere}</Link> {label?.forAdditionalSearchResults}
          </div>)
      : (exactCustomerSkuMatch === null && exactFastenalSkuMatch !== null) ?
                  (<div className="alert alert-warning ie-float-left ie-width-full" role="alert">
                    {label?.exactCustomerProductMatch}  <Link to={setJessionId(`/product?query=${productDetail?.sku}&skipSkuMatch=Y`)}  data-ol-has-click-handler="">{label?.clickHere}</Link> {label?.forAdditionalSearchResults}
                  </div>) : "")
      }
      <div className="row mb-2 ie-width-full ie-float-left" id="pdp-details">
        <div className="col-xs-12 co-sm-4 col-md-12 col-lg-4 pr-0 ie-float-left ie-col-3 px-sm-3 p-3 py-lg-0 py-xl-0 pr-lg-0 pr-xl-0">
          <div className="card ecom-card rounded-0 text-center p-2">
            {(productDetail?.isExpress !== undefined && productDetail?.isExpress === 'Y') &&
              <div className="express-badge"><img src={imgSrc("/static/images/express-badge.png")} className="" alt="..." /></div>
            }
            <div className="ie-text-center">
              <img src={productImage? imgSrc(productImage) : imgSrc(productDetail?.imgOne)} className="image-fluid" alt={productDetail?.imageAltText}/>
            </div>
            </div>
            <div id="alternate-images-section" className="product--thumbnail-wrap">
              {(productDetail?.detailsImages !== undefined && productDetail?.detailsImages?.length > 1) &&
               productDetail?.detailsImages?.map((image, index) => {
                 return (
                     <a className="preview cursor--pointer" tabIndex="0" onKeyPress={(event) => {if(event.key=="Enter")setProductImage(image)}}>
                       <img src={imgSrc(image)} onClick={() => {setProductImage(image)}} className="product--thumbnail" alt={productDetail?.imageAltText}/>
                     </a>
                 )
               })
              }
              <CadModal
                  sku={productDetail?.sku}
                  des={productDetail?.description}
                  technicalSpec={productDetail?.technicalSpec}
                  labels={label}
                  env={env}
              />
            </div>
            <div className="card ecom-card mt-4">
              <div className="card-header bg-gainsboro-grey text-bokara-grey  rounded-0 feco-font-weight-600"> {label?.compliance}</div>
              <div className="card-body px-2 py-1">
                <div className="row no-gutters">
                  <div className="col">
                    <div className="ecom-card-icon-list-group ie-card-icon-list-group">
                      <ul className="nav">
                        { (compliancesExists) ? (
                            productDetail?.compliances?.map((compliance, index) => {
                              return (
                                <li className="nav-item cursor-pointer feco-border-sm" key={index} onClick={(e) => {showComplianceDetails(productDetail?.compliances)} }>
                                  <img src={imgSrc(compliance.Image)} title={compliance["complianceNameLabel"]} alt={compliance["Alt Text"]} className={compliance.Image ? "" : "img-20x20"} className="img-20x20"/>
                                </li>
                              )
                            })
                          ) :
                          label?.noRecordsFound
                        }
                      </ul>
                      { productDetail?.notes?.complianceNotes &&
                        <>
                          <span className='font-weight-bold'>{label?.notes}</span>
                          <span className='compliance-notes' dangerouslySetInnerHTML={{__html: productDetail?.notes?.complianceNotes}}></span>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          { (productDetail?.technicalSpec && productDetail?.technicalSpec.fastenalProductStandard) &&
              <div className="card mt-2 border-top-0">
                <ul className="nav nav-tabs nav-justified bg-gainsboro-grey">
                  {(productDetail?.technicalSpec ) ?
                      <li className="nav-item border-bottom text-left">
                        <a href="#ProductStandards" data-toggle="tab" className={`nav-link feco-font-weight-600 border-left-0 border-right-0 text-bokara-grey  bg-gainsboro-grey}`}>{label?.productStandards}</a>
                      </li>
                      : <div className="card-header bg-gainsboro-grey text-bokara-grey  rounded-0 feco-font-weight-600"> {label?.productStandards}</div>
                  }
                </ul>
                <div className="tab-content">
                  { productDetail?.technicalSpec &&
                      <div className={`tab-pane active`} id="ProductStandards">
                        <ul className="list-unstyled">
                          <li className="m-3">
                            <a className="feco-jsessionid-none" href={productDetail?.technicalSpec.fastenalProductStandard} target="_blank" data-ol-has-click-handler="">
                              <i className="far fa-file-pdf mr-1 text-danger"></i> {label?.fastenalProductStandard}
                            </a>
                          </li>
                        </ul>
                      </div>
                  }
                </div>
              </div>
          }
          {xrefProducts?.alternateItem && <SimilarProducts skus={xrefProducts?.alternativeItems} productDetail={xrefProducts?.alternateItem} title={"Alternative Items"} addToCart={props.addToCart} onClickViewAll={handleViewAll} /> }
        </div>
        <div className="col-xs-12 co-sm-12 col-md-12 col-lg-4 pr-0 ie-float-left ie-col-3 px-sm-3 p-3 py-lg-0 py-xl-0 pr-lg-0 pr-xl-0">
            <table className="table table-borderless pdp-sku-info ml-sm-n2 ml-lg-n2 ml-xl-n2 my-lg-0 my-xl-0">
              <tbody>
              <tr>
                <td className="pt-0" colSpan="2">
                  <h1 className="font-weight-bolder feco-seo-title ecom-proddetail-title">
                    <span dangerouslySetInnerHTML={{ __html: productDetail?.description }}></span>
                  </h1>
                </td>
              </tr>
              <tr>
                <td className="font-weight-600">{customerSku ? 'Your Sku':'Fastenal Part No. (SKU)'}</td>
                <td>{ customerSku ?  `${customerSku} (${productDetail?.sku})`:productDetail?.sku  }</td>
              </tr>
              {
                productDetail?.manufacturerPartNo &&
                <tr>
                  <td className="font-weight-600">{label?.manufacturerPartNo}</td>
                  <td>
                    {productDetail?.manufacturerPartNo}
                  </td>
                </tr>
              }
              {productDetail?.unspscCode && <tr>
                <td className="font-weight-600">UNSPSC</td>
                <td>
                  <a href={`//www.unspsc.org/search-code/default.aspx?CSS=${productDetail?.unspscCode}`} target="_blank" rel="noopener noreferrer" className="feco-jsessionid-none">
                    {productDetail?.unspscCode}
                  </a>
                </td>
              </tr>}
              {productDetail?.manufacturer &&
              <tr>
                <td className="font-weight-600">{label?.manufacturer}</td>
                <td><Link to={setJessionId(`/product?Manufacturer=${encodeURIComponent(productDetail?.manufacturer)}&showAll=Manufacturer`)} dangerouslySetInnerHTML={{ __html: productDetail?.manufacturerLabel}}></Link></td>
              </tr>
              }
              { productDetail?.brandName &&
                <tr>
                  <td className="font-weight-600">{label?.brand}</td>
                  <td><Link to={setJessionId(`/product?Brand Name=${encodeURIComponent(productDetail?.brandName)}&showAll=Brand Name`)} dangerouslySetInnerHTML={{ __html: productDetail?.brandLabel}}></Link></td>
                </tr>
              }
              <tr >
                <td colSpan="2">
                  { (productDetail?.pdpMessage && !productDetail?.discontinued) &&
                  <div className="ecom-icon-default d-flex align-items-center ie-float-left">
                    <div className="ecom-pd-ctlg-icon ecom-icon-default-size ecom-icon-mr ie-float-left">
                      <img src={imgSrc(`/static/images/${productDetail?.pdpMessage && productDetail?.pdpMessage.imageName}`)} className="ecom-icon" alt=""/>
                    </div>
                    <div className="ecom-icon-text ie-pt-10">{ productDetail?.pdpMessage && productDetail?.pdpMessage.message}</div>
                    { (window.isNonAccountUser && productDetail?.pdpMessage.message.includes("Expanded Catalog")) && <div title="This item is available for On-Account customers only.">
                        <i className="fa fa-exclamation-circle exclamation-icon"></i>
                    </div>
                    }
                  </div>
                  }
                </td>
              </tr>
              </tbody>
          </table>
          {productDetail?.discontinued && <span className="bg-discontinued p-1 d-flex text-white mt-n3 mb-2">{label?.discontinuedItem}</span>}
            <div className="card ecom-card">
              <div className="card-header bg-gainsboro-grey text-bokara-grey  feco-font-weight-600">{label?.productAttributes}</div>
              <div className="card-body">
                <div className="row">
                  <div className="col m-0 p-2 mt-n3">
                    <table className="table feco-table-modif">
                      <tbody>
                      {  productDetail?.categoryAttributes && productDetail?.categoryAttributes.map((categoryAttribute, i)=> {
                        return (
                          <tr key={i}>
                            <td className="font-weight-bold">{categoryAttribute.name}</td>
                            <td><span dangerouslySetInnerHTML={{ __html: categoryAttribute.value  }}></span></td>
                          </tr>
                        )
                      })
                      }
                      {(productDetail?.weight) &&
                        <tr>
                          <td className="font-weight-bold">{label?.productWeight}</td>
                          <td>{productDetail?.weight}</td>
                        </tr>
                      }
                      <tr>
                        <td className="font-weight-bold">{label?.uom}</td>
                        <td>{ productDetail?.uom && productDetail?.uom.standardUOMSingular }</td>
                      </tr>

                      {productDetail?.bigBlueCatalogs && productDetail?.bigBlueCatalogs.length > 0 &&
                      <tr>
                        <td className="font-weight-bold">{label?.catalogPage}</td>
                        <td>
                          {productDetail?.bigBlueCatalogs.map((catalog, index) => {
                            return (
                                <div key={index}>
                                  <a href={catalog.url} target="_blank" className="feco-jsessionid-none">
                                    {catalog.fileName}
                                  </a>
                                </div>)})
                          }
                        </td>
                      </tr>}
                      {productDetail?.notes && (productDetail?.notes?.publicNotes || productDetail?.notes?.bulletPoints || productDetail?.notes?.applicationsUse) &&
                      <tr>
                        <td colSpan="2" className="no-padding">
                          <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true">
                            <div className="panel panel-default">
                              <div role="tab" className="collapsed panel-heading cursor-pointer " data-toggle="collapse" data-parent="#productDetailsNotes" href="#productDetailsNotes" aria-expanded="true" aria-controls="productDetailsNotes" onClick={(e) => {setToggleIcon(!toggleIcon)}}>
                                <h6>{label?.notes}<i className={cx("fas fa-chevron-up float-right fa-1x", {
                                  ['rotate-icon'] : toggleIcon
                                })} ></i>
                                </h6>
                              </div>
                              <div id="productDetailsNotes" className="panel-collapse collapse show" aria-labelledby="headingOne" aria-expanded="false">
                                <div className="panel-body fc-prd-notes">
                                  <span id="productNotesFragments">
                                   {(productDetail?.notes?.publicNotes) &&
                                    <div dangerouslySetInnerHTML={{__html: productDetail.notes?.publicNotes}}></div>
                                  }{(productDetail?.notes.bulletPoints) &&
                                    <div dangerouslySetInnerHTML={{__html: productDetail.notes?.bulletPoints}}></div>
                                  }{(productDetail?.notes.applicationsUse) &&
                                    <div dangerouslySetInnerHTML={{__html: productDetail.notes?.applicationsUse}}></div>
                                  }
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      }
                      </tbody>
                    </table></div></div>
              </div>
            </div>
          </div>

        <div className="col-xs-12 co-sm-12 col-md-12 col-lg-4 ie-float-left ie-col-3">
            <div className="ecom-pd-pricing-details mb-2">
              { priceDisplayData && priceDisplayData.length > 0 ?
                  priceDisplayData.map((price, index) => {
                    return (
                        <div key={index}>
                          <div className={`${price.type !== 4 && "feco-text-size-17"} text-${price.label === "Wholesale:" || price.label === "List Price:" ? "black" : (
                              price.label === "Unit Price:" && index - 1 >= 0 && priceDisplayData[index - 1].label === "Wholesale:" ? "black" : "primary"
                          )} ${(price.type === 4) ? "h6" : "font-weight-600"}`}>
                            <span>{ price.label } { price.price } {price.uom ? "/" : ""} { price.uom }</span>
                          </div>
                        </div>
                    )
                  })
                  :
                  <span><span className="font-weight-bold"><br/>{label?.price}: </span>{label?.noPriceAvailable}
                    {<OverlayTrigger placement="bottom" overlay={popover}>
                      <i className="fas fa-info-circle fa-1x text-primary tool-tip ml-1"></i>
                    </OverlayTrigger>}
                  </span>
              }
            </div>
            <div className="ecom-pd-item-cart d-flex align-items-center">
              <label className="mr-2 mr-2 font-weight-600 ie-float-left ie-pt-10" htmlFor="inlineFormCustomSelectPref">QTY</label>
              <input type="number" min="0" max="99999"  aria-label={label?.quantity} className="mb-2 mr-2 mr-sm-2 ecom-pd-qty-input-small ie-float-left form-control p-1" id={`selected_quantity_${productDetail?.sku}`} defaultValue="1" onChange={(e) => { onChangeInputQuantity(e) }} onKeyDown={(e) => { onkeydownInputQuantity(e) }} />
              <div className="btn-group mt-n2">
                {window.isPunchOutUser && isLevelParam.isleveltwo ?
                    <PunchoutForm productDetail={productDetail} props={props} label={label}/>
                      :
                    <button type="button" className="btn btn-primary ecom-button ecom-button-highlight ie-mt-0" onClick={(e) => { AddToShoppingCart(e, productDetail, props, "Product Detail") }}>
                      <i className="fa fa-shopping-cart" aria-hidden="true"></i> {label?.add}
                    </button>}
                <AddToCartTemplate sku={productDetail?.sku} productDetail={productDetail} responseData={props} isleveltwo={isLevelParam.isleveltwo} AddToShoppingCart={AddToShoppingCart} />
              </div>
            </div>
            {inventoryList !== undefined && inventoryList && checkKeysInObject(inventoryList, "locale") && checkErrorInInventoryResponse(inventoryList) &&
              <div className="card ecom-card mt-4">
              <div className="card-header bg-gainsboro-grey text-bokara-grey  rounded-0 feco-font-weight-600">{label?.myInventoryLocations}</div>
              <div className="card-body">
                { inventoryList.binstockList &&
                  <>
                    <div className="row">
                      <div className="col">
                        <span><img src={imgSrc("/static/redesign/images/icon-bin.png")} alt="..." className="ecom-icon-default-size" /></span>
                        <span className="font-weight-bolder ml-3">{label?.binStock}</span>
                        <span className="float-right">
                          <i className={`fas fa-${showBinSelection ? 'minus' : 'plus'}-circle`} onClick={() => {showBinDropDown()}}></i>
                        </span>
                      </div>
                    </div>
                    {showBinSelection &&
                      <>
                        <div className="row mt-2">
                          <div className="col">
                            <select className="custom-select" onChange={handleChangeBin}>
                              <option defaultValue="SELECT">{label?.select}</option>
                              {
                                inventoryList.binstockList && inventoryList.binstockList.map((bin, index) => {
                                  return (
                                    <option value={JSON.stringify(bin.deviceList)} key={index}>
                                      {`${bin.addressName} - ${bin.locationName}`}
                                    </option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                        { binDeviceList &&
                          <div className="row">
                            <div className="col">
                              {
                                binDeviceList && binDeviceList.map((device, i) => {
                                  return (
                                      <span key={i} className="text-primary cursor-pointer" onClick={(e) => { openBinStockPopup(device) }}>{device.planogramName}</span>
                                  )
                                })
                              }
                            </div>
                          </div>
                        }
                      </>
                    }
                  </>
                }
                { inventoryList.vendingList &&
                <>
                  <div className="row mt-2">
                    <div className="col">
                      <span><img src={imgSrc("/static/redesign/images/icon-vending.png")} alt="..." className="ecom-icon-default-size" /></span>
                      <span className="font-weight-bolder ml-3">{label?.vending} </span>
                      <span className="float-right"><i className={`fas fa-${showVendingSelection ? 'minus' : 'plus'}-circle`} onClick={() => {showVendingDropDown()}}></i></span>
                    </div>
                  </div>
                  {showVendingSelection &&
                    <>
                      <div className="row mt-2">
                        <div className="col">
                          <select className="custom-select" onChange={handleChangeVending}>
                            <option defaultValue="SELECT">{label?.select}</option>
                            {
                              inventoryList.vendingList && inventoryList.vendingList.map((vending, index) => {
                                return (
                                  <option value={JSON.stringify(vending.deviceList)} key={index}>
                                    {`${vending.addressName} - ${vending.locationName}`}
                                  </option>
                                )
                              })
                            }
                          </select>
                        </div>
                      </div>
                      { vendingDeviceList &&
                        <div className="row">
                          <div className="col">
                            {
                              vendingDeviceList && vendingDeviceList.map((device, i) => {
                                return (
                                  <span key={`k-${i}`} className="mr-2 text-primary cursor-pointer" onClick={(e) => { openVendingMachinePopup(device) }}>
                                    {device.deviceName}
                                  </span>
                                )
                              })
                            }
                          </div>
                        </div>
                      }
                    </>
                  }
                </>
                }
              </div>
            </div>
            }
            <div className="card ecom-card mt-4 supply-chain ie-float-left ie-width-full">
              <div className="card-header bg-gainsboro-grey text-bokara-grey  feco-font-weight-600">{label?.supplyChain}</div>
              <div className="card-body p-0">
                <div className="row no-gutters p-2">
                  <div className="col font-weight-600 ie-col-3 ie-float-left">{label?.availability}</div>
                  <div className="col ie-col-8 ie-float-left">
                    <ProductAvailability productEda={productEda} />
                    <button onClick={()=> {showOtherLocations()}} className="btn btn-outline-light mt-2">
                      <span className="text-primary"> {label?.CheckOtherLocations}</span>
                    </button>
                  </div>
                </div>
                { (productEda && productEda.pdpDeliveryMethodList && productEda.pdpDeliveryMethodList.length > 0) &&
                  <>
                    <hr className="m-0"/>
                    {
                      productEda.pdpDeliveryMethodList.map((item, ind) => {
                        return (
                          <div className="row p-2 no-gutters" key={ind}>
                            <div className="col-md-7 col-md-7 ie-col-3 ie-float-left">
                              <span className="font-weight-600">{item.shippingName}
                                {item.freePickupFlag !== undefined && <span className="label-free ml-1">{label?.free}</span>}
                                {item.locker !== undefined && < a href={setfcAndJessionId(item.lockerUrl)} className="label-locker js-branchLockerAvailable hidden ml-1">Locker</a>}
                              </span>
                            </div>
                            <div className="col-md-5 ie-float-left ie-col-8">
                              <span className="availability-badge">
                                {item.express !== undefined && item.express === true &&
                                  <a href={setfcAndJessionId(item.expressUrl)}>
                                    <img className="express-icon mr-1" src={imgSrc("/static/images/express-icon.png")} alt="Express Icon"/>
                                  </a>
                                }
                                <span className="ml-1">{item.day}</span>
                              </span>
                            </div>
                          </div>

                        )
                      })
                    }
                  </>
                }
                <div className="row pb-2 pl-2">
                  <div className="col-sm-12">
                  {(productEda && productEda !== undefined) &&
                    <>
                      <div className={`mt-3 font-weight-600 ie-float-left ie-width-full ${productEda.colorStatus === "0" ? "text-danger" : ""}`}>
                        {(productEda.message !== undefined) && productEda.message}
                      </div>
                      {(productEda.phone !== undefined && productEda.colorStatus === "2") &&
                        <span className={`text-blue ie-width-full ie-float-left`}>{productEda.phone}</span>
                      }
                      </>
                    }
                  </div>
                </div>
              </div>
          </div>
          {xrefProducts?.relatedItem && <SimilarProducts skus={xrefProducts?.relatedItems} productDetail={xrefProducts?.relatedItem} title={"Related Items"} addToCart={props.addToCart} onClickViewAll={ handleViewAll }/>}
          </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (shoppingCartSummary) => {dispatch(addToCart(shoppingCartSummary))}
  }
};

const popover = (
    <Popover style={{ maxWidth: 360}}>
      <Popover.Title as="h3"><b>{label?.noPriceAvailable}</b></Popover.Title>
      <Popover.Content className="popover-content">
        {label?.noPriceAvailableMessage}
      </Popover.Content>
    </Popover>
);

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);