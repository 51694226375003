import React, { useState, useEffect } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';

const DataProgressBar = () => {
  const [value, setValue] = useState(10);

  useEffect(() => {
    let isMounted= true;
    const interval = setInterval(() => {
      if (isMounted) {
        setValue(oldValue => {
          const newValue = oldValue + 10;
          if (newValue === 100) {
            clearInterval(interval);
          }
          return newValue;
        });
      }
    }, 500);
    return () => { isMounted = false;}
  }, []);
    return (
    <ProgressBar now={value} max="100" style={{ height: "0.3rem" }} striped variant="info" id="progress-bar" className="mb-2" />
  );
}

export default DataProgressBar;