import React from "react";
import {postRequest} from "../apiCalls/ApiCall";
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { showAddToTemplate } from '../helpers/Helpers';
import {
  ADD_TO_TEMPLATE_URL,
  ADD_TEMPLATE_URL
} from "../constants/Constants";
import {setJessionId, errorMessage} from "../helpers/CommonScripts";

function AddToCartTemplate(props) {
  const label = props.labels;
  const sku = props.sku;
  const templateList = props.templateList;
  const addTemplate = (event) =>{
    const selectedQty = event.target.offsetParent && event.target.offsetParent.parentElement.firstChild;
    const quantity = parseInt(selectedQty && selectedQty.value ? selectedQty.value : document.getElementById(`selected_quantity_${sku}`).value);
    postRequest(ADD_TEMPLATE_URL, {"sku":sku, "quantity":quantity}).then((res) => {
      if (res.redirectUrl !== undefined) {
        window.location.href = setJessionId(window.isPunchOutUser ? "/fc"+ res.redirectUrl.toString() : res.redirectUrl);
      } else {
        let ua = window.navigator.userAgent;
        let msie = ua.indexOf('MSIE ');
        errorMessage(msie, label?.addTemplateError);
      }
    });
  };

  const addToTemplate = (event, tempId) =>{
    const selectedQty = event.target.offsetParent && event.target.offsetParent.parentElement.firstChild;
    const quantity = parseInt(selectedQty && selectedQty.value ? selectedQty.value : document.getElementById(`selected_quantity_${sku}`).value);
    postRequest(ADD_TO_TEMPLATE_URL, {"sku": sku, "quantity": quantity, "template": tempId}).then((res) => {
      let ua = window.navigator.userAgent;
      let msie = ua.indexOf('MSIE ');
      if(res.updateMessage !== undefined) {
        var message = res.updateMessage;
        toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      } else {
        errorMessage(msie, label?.addTemplateError);
      }
    });
  };

  function cartTemplate(){
    return (
      <>
        <button className="btn btn-primary dropdown-toggle dropdown-toggle-split feco-dropdown-toggle-split" type="button" data-toggle="dropdown"><span className="caret"/></button>
        <ul className="dropdown-menu feco-dropdown-menu-add-template feco-split-dropdown-sroll pt-0 mt-0 border-0 rounded-0">
          {window.isPunchOutUser && props.isleveltwo && <li className="border-bottom" onClick={(e) => { props.AddToShoppingCart(e, props.productDetail, props.responseData, "Product Detail") }}>
            <span className="dropdown-item p-1 cursor-pointer">{label?.addToCart}</span>
          </li>}
          <li className="bg-primary text-white p-0 dd-template-header" onClick={(e) => { addTemplate(e)}}>
            <span className="text-white dropdown-item p-1 cursor-pointer">{label?.newOrderTemplate}</span>
          </li>
          {templateList && templateList.length > 0 &&
            <>
              <li className="mt-1 mb-1 border-bottom border-dark"><span className="cursor-default p-1">{label?.myOrderTemplates}</span></li>
              {
                templateList.map(function(template, index) {
                  return(
                    <li className="border-bottom" key={`template-${index}`} onClick={(e) => { addToTemplate(e, template.value) }}>
                      <span className="dropdown-item p-1 cursor-pointer">{template.name}</span>
                    </li>
                  )
                })
              }
            </>
          }
        </ul>
      </>
    )
  }
  return( (window.userLoggedIn && showAddToTemplate()) ? cartTemplate() : "")
}

const mapStateToProps = (state) => {
  return {
    templateList: state.commonReducer.addToTemplateList,
    labels: state.commonReducer.pageLabels
  }
};

export default connect( mapStateToProps, null )(AddToCartTemplate);