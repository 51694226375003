import { connect } from "react-redux";

function ServiceUnavailable(props) {
  const label = props.labels;
  return (
    <div className="feco-container-fix feco-container-inner mt-5 mb-5">
      <div className="mt-5 text-center mb-5">
          <h2 className="mt-5 mb-2">
            {label?.serviceUnavailable}
          </h2>
          <p className="mb-5 pb-5">
            {label?.backSoon}
          </p>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
}

export default connect( mapStateToProps, null )(ServiceUnavailable);