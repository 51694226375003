import React,{useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import useOnclickOutside from "react-cool-onclickoutside";
import { VscGlobe } from "react-icons/vsc";
import { FaUserCircle, FaMapMarkerAlt, FaShoppingCart } from "react-icons/fa";
import { LOGIN_URL} from "../../../constants/Constants";
import { titleCase,changeColorToOrange } from "../../../helpers/CommonScripts";

function Header(props) {
  const label = props.labels;
  const branchInfo = props?.headerInformationData?.branchInformation ?? {};
  const userSettingResponse = props?.userSettings;
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);
  const [activeIcon, setActiveIcon] = useState("");
  const emailRef = useRef();
  const cartSummary = props.headerInformationData?.shoppingCartSummary.count ?? props.cartSummarys.count;

  const onclickOutsideRef = useOnclickOutside(() => {
    setActiveIcon("")
  });

  useEffect(() => {
    if (window.location.pathname === "/catalog/country-locations") {
      setActiveIcon("locations")
    }
    if (window.location.pathname === "/locations") {
      setActiveIcon("myBranch")
    }
    if (window.location.pathname === "/shopping-cart") {
      setActiveIcon("myCart")
    }
  }, [window.location.pathname])

  const handleFormSubmit = (event) => {
    let emailValue = emailRef.current ? emailRef.current.value : "";
    if (
      !emailValue ||
      !/^[\w'-]+(\.[\w'-]+)*@([\w'-]+(\.[\w'-]{2,})+)$/.test(emailValue)
    ) {
      event.preventDefault();
      setEmailErrorMessage(
        emailValue
          ? "Email Address is not a valid email address."
          : "Email Address is required."
      );
    } else {
      emailErrorMessage && setEmailErrorMessage(null);
    }
  };

  const BanchInfoDetails = (
    <Popover style={{right: 0, maxWidth: "100%", minWidth: "101%"}} className="ml-n2 rounded-0 shadow">
      <Popover.Content className="popover-content p-0 pr-2">
        <p className="float-right font-weight-bold" onClick={() => { document.body.click() }}>X</p>
        <div>
              {branchInfo && Object.keys(branchInfo).length > 0 && (
                <div>
                  <span id="branch-store5code" className="d-none">
                    {branchInfo.store5Code}
                  </span>
                  <div className="p-2 border-bottom pb-2">
                    <h6 className="p-0 m-0">
                      <a className="navBarBranchPopupLink" href={`/locations/details/${branchInfo.store5Code}`} onClick={(event) => changeColorToOrange(event)}>
                        <span>
                          {`${branchInfo.store5Code} - ${titleCase(
                            branchInfo.city
                          )} ${branchInfo.postal}`}
                        </span>
                      </a>
                    </h6>
                    {branchInfo.branchMessage && (
                      <div style={{ Color: "red" }}>
                        <span>{branchInfo.branchMessage}</span>&nbsp;
                        <i
                          className="fa fa-info-circle pl-1 text-primary"
                          aria-hidden="true"
                          title={branchInfo.branchTooltip}
                        ></i>
                      </div>
                    )}
                    <span>{branchInfo.address1}</span>
                    <br />
                    <span>{`${branchInfo.city}, ${branchInfo.stateAbbreviation} ${branchInfo.postal}`}</span>
                    <br />
                    <span>
                      {label?.phone}: {branchInfo.phone}
                    </span>
                    <br />
                    <span>
                      {label?.email}:{" "}
                      <a className="navBarBranchPopupLink" href={`mailto: ${branchInfo.email}`} onClick={(event) => changeColorToOrange(event)}>
                        {branchInfo.email}
                      </a>
                    </span>
                  </div>
                  <div className="p-2 font-weight-600 navBarBranchPopupLink">
                  <a className="navBarBranchPopupLink" href="/locations" onClick={(event) => changeColorToOrange(event)}>
                    {label?.findAnotherBranch}
                  </a>
                  </div>
                </div>
              )}
            </div>
      </Popover.Content>
    </Popover>
  )

  const MyAccountInfo = (
    <Popover style={{right: 0, maxWidth: "100%", minWidth: "101%", height: window.userLoggedIn ? "100%" : ""}} className="ml-n2 rounded-0 shadow ">
      <Popover.Content className="popover-content">
        <p className="float-right font-weight-bold" onClick={() => { document.body.click() }}>X</p>
          <form action={LOGIN_URL} method="post">
            <div className="pl-1 pr-1 mb-2">
              <div className="form-group">
                <label htmlFor="mail" className="font-weight-bold">
                  {label?.myAccount}:
                </label>
                {emailErrorMessage && (
                  <span
                    className="field-error-message"
                    id="emailAddress.errors"
                  >
                    {emailErrorMessage}
                  </span>
                )}
                <input
                  required
                  type="email"
                  name="emailAddress"
                  autoComplete="on"
                  style={{height: "38px"}}
                  className={
                    "form-control form-control feco-w-sm-100 rounded-0" +
                    (emailErrorMessage ? " ui-invalid-input" : "")
                  }
                  id="mail"
                  placeholder={label?.emailAddressStar}
                  aria-describedby="emailHelp"
                  ref={emailRef}
                />
              </div>
              <div className="mb-1">
                <button
                  type="submit"
                  className="btn btn-primary rounded-0 font-weight-bold btn-lg"
                  onClick={handleFormSubmit}
                >
                  <span className="font-weight-bolder">{label?.signInorRegister}</span>
                </button>
                  <a className="float-right mt-1" href={"/azurelogin/password-forgot"}>
                    {label?.forgotpassword}
                  </a>
              </div>
            </div>
          </form>
      </Popover.Content>
    </Popover>
  );

  return (
    <nav className="navbar bg-primary flex-nowrap flex-row " ref={onclickOutsideRef}>
      <div className="mr-auto">
        <Link className="navbar-brand ecom-logo float-left" to="/">
          <img
            src="https://crafter.tst.fastenal.com/static-assets/images/fcom/fastenal-logo-blue-white.png"
            className="img-fluid"
            alt="Fastenal Logo"
          />
        </Link>
      </div>
      <div className="d-flex justify-content-between mr-3">
        {!window.isPunchOutUser && (
        <Link to="/catalog/country-locations">
          <VscGlobe size="2.3em" className={`ml-3 ${activeIcon === 'locations' ? "svgActive" : "text-white"}`} onClick={()=> setActiveIcon("locations")}/>
        </Link>
        )}
        {window.isPunchOutUser && (
            <a href={`/punchout/location`}>
              <VscGlobe size="2em" className={`ml-3 ${activeIcon === 'locations' ? "svgActive" : "text-white"}`} onClick={()=> setActiveIcon("locations")}/>
            </a>
        )}
        {window.userLoggedIn ? <a href="/my-account">
          <FaUserCircle size="2em" className={`ml-3 ${activeIcon === 'myAccount' ? "svgActive" : "text-white"}`} onClick={()=> setActiveIcon("myAccount")}/>
        </a> : <OverlayTrigger placement="bottom" rootClose overlay={MyAccountInfo} trigger="click">
          <FaUserCircle size="2em" className={`ml-3 ${activeIcon === 'myAccount' ? "svgActive" : "text-white"}`} onClick={()=> setActiveIcon(activeIcon === 'myAccount' ? "" : "myAccount")}/>
        </OverlayTrigger>}
        {branchInfo && Object.keys(branchInfo).length > 0 ? <OverlayTrigger placement="bottom" rootClose overlay={BanchInfoDetails} trigger="click">
          <FaMapMarkerAlt size="2em" className={`ml-3 ${activeIcon === 'myBranch' ? "svgActiveMap" : "text-white"}`} onClick={()=> setActiveIcon(activeIcon === 'myBranch' ? "" : "myBranch")} />
        </OverlayTrigger> : <a href="/locations">
          <FaMapMarkerAlt size="2em" className={`ml-3 ${activeIcon === 'myBranch' ? "svgActiveMap" : "text-white"}`} onClick={()=> setActiveIcon("myBranch")} />
        </a>}
        <a href="/shopping-cart">
          <FaShoppingCart size="2em" className={`ml-3 ${activeIcon === 'myCart' ? "text-orange" : "text-white"}`} onClick={()=> setActiveIcon("myCart")} />
        </a>
        <span className="badge badge-pill bg-orange h-25 ml-n2 text-white">{cartSummary ? cartSummary : 0}</span>
      </div>
    </nav>
  );
}


const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels,
    cartSummarys: state.cartReducer.addedProducts,
  };
};

export default connect(mapStateToProps, null)(Header);

