import React, {useState} from 'react';
import {postRequest} from '../../apiCalls/ApiCall';
import {PRODUCT_SEARCH_URL} from '../../constants/Constants';
import Section from "./Section";
import ProductPopover from "./ProductPopover";
import {connect} from "react-redux";
import {sendPDAnalyticsData} from "../../helpers/CommonScripts";

function CounterBook(props) {
    const counterbook = props.counterbook;
    const sections = (counterbook && counterbook.sections) || [];
    const [loading, setLoading] = useState(false);
    const [productDetail, setProductDetails] = useState({});
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);

    const compareProducts = props.compareProducts;
    const comparelist_skus =  compareProducts && compareProducts.map(function (obj) {
        return obj.sku
    });

    const handleClickShowProductDetails = (event, sku) => {
        let bodyContent = Object.assign({"sku": [sku]})
        setLoading(true);
        postRequest(PRODUCT_SEARCH_URL, bodyContent).then((res) => {
            setProductDetails(res && res.productList && res.productList[0]);
            setLoading(false);
            sendPDAnalyticsData(res.productList[0], "view_item", "Catalog View Pop-up");
        })
        setShow(true);
        setTarget(document.getElementById("sku_" + sku));
    };

    return (
      <>
        <div className="table-responsive-lg">
            <input type="hidden" name="pageVal" value="Counter Book" />
            {
                sections && sections.map((section, sectionIndex) => {
                    return (
                        <Section key = {sectionIndex} section = {section}
                                 handleClickShowProductDetails ={handleClickShowProductDetails}
                                 columnHeaders = {counterbook.columnHeaders}
                        />
                    )
                })
            }
        </div>

        <ProductPopover comparelist_skus = {comparelist_skus}
                        productDetail = {productDetail}
                        loading = {loading}
                        show = {show}
                        target = {target}
                        setShow = {setShow}
                        onClickAddToCart={props.onClickAddToCart}
                        onClickAddToCompare={props.onClickAddToCompare}
                        addToCart={props.addToCart}/>
      </>
    )
}

const mapStateToProps = (state) => {
    return {
        compareProducts: state.compareReducer.compareItems
    }
};

export default connect( mapStateToProps, null )(CounterBook);