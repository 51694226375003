import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { connect } from 'react-redux';
import {getResponse, isPunchoutURL} from '../apiCalls/ApiCall';
import DataProgressBar from './DataProgressBar';
import MobileRoutes from "./Mobile/Routes"
import CatalogNavigator from './CatalogNavigator';
import Footer from './Footer';
import Header from './Header';
import Navbar from './Navbar';
import NotFound from './NotFound'
import Fast from "./Fast";
import {setUserLoggedIn, setUserLoggedOut, setUserType} from '../helpers/Helpers';
import { CATEGORY_MENU_URL, GET_TEMPLATE_LIST_URL, USER_LOGIN_URL, PAGE_LABELS_URL, USER_HEADER_INFORMATION_URL } from '../constants/Constants';
import Cookies from "./Cookies";
import ViewAs from "./ViewAs";
import { addToTemplateList, categoryMenuList, pageLabels } from '../actions/commonActions';
var pjson = require('../../package.json');

function Routes(props) {
  window.isPunchOutUser = pjson.homepage === "/fc" ? true : false;
  let languageCode = window.location.pathname.split("/").filter(Boolean)[1];
  languageCode = [2,3].includes(languageCode?.length) ? languageCode : "";
  const [data, setData] = useState({});
  const [headerInformationData, setHeaderInformationData] = useState(JSON.parse(sessionStorage.getItem("headerInformationData")));
  const [cmsData, setCmsData] = useState(sessionStorage.getItem("cmsData"));
  const [showProgressBar, setShowProgressBar] = useState(false);
  window.baseUrl = window.isPunchOutUser ? languageCode ? `/fc/${languageCode}` : "/fc" : "";

  const showLoader = (status) => {
    setShowProgressBar(status);
  }

  const changeLanguage = (languageMapping) => {
    window.baseUrl = window.isPunchOutUser ? languageMapping ? `/fc/${languageMapping}` : "/fc" : "";
  }
  useEffect(() => {
    if (window.isPunchOutUser) {
      const anchors = document.querySelectorAll('a, area');
      function hasClass(elem, cls) {
        return (' ' + elem.className + ' ').indexOf(' ' + cls + ' ') > -1;
      }
      anchors && anchors.forEach(anchor => {
        if (isPunchoutURL(anchor.href) && hasClass(anchor,"feco-jsessionid-none") === false) {
          if (anchor.href.indexOf("jsessionid") < 0) {
            anchor.style.setProperty("pointer-events", "none");
          }
        }
      });
    }
    getResponse(PAGE_LABELS_URL, {}).then((response) => {
      props.pageLabels(response);
    });

    getResponse(USER_HEADER_INFORMATION_URL,{}).then((response) => {
      if(response) {
        sessionStorage.setItem("headerInformationData", JSON.stringify(response));
        setHeaderInformationData(response);
        if (response.branchInformation) {
          window.country = (response.branchInformation.country !== undefined) ? response.branchInformation.country : "";
          window.branchCode = (response.branchInformation.store5Code !== undefined) ? response.branchInformation.store5Code : "";
        }
      }
    });
  }, []);
  useEffect(() => {
    getResponse(USER_LOGIN_URL, {}).then((response) => {
      setData(response);
      window.landingPageUrl =  response?.landingPageUrl ?? "/";
      window.userLoggedIn = response?.userLoggedIn ?? false;
      window.isNonAccountUser = response?.isNonAccountUser ?? true;
      window.myInventoryLocationsEnabled = response?.myInventoryLocationsEnabled ?? false;
      if (response && response.cmsData) {
        sessionStorage.setItem("cmsData", JSON.stringify(response.cmsData));
        setCmsData(response.cmsData);
      }
      if(response && response.currencyCodeForGA) {
        sessionStorage.setItem("currencyCodeForGA", JSON.stringify(response.currencyCodeForGA));
      }
      if (response && response.myAccountInfo !== undefined) {
        setUserLoggedIn();
        setUserType(response.userRoles || [])
      }else {
        setUserLoggedOut();
      }
    });
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const result = await getResponse(CATEGORY_MENU_URL, {});
      props.categoryMenuList(result.categoryList);
    };
    fetchData();
    window.onbeforeunload = function(){
      window.scrollTo(0,0);
    };
  }, []);

  useEffect(()=>{
     let container = document.getElementById("main-container");
     let paddingTop = document.getElementById("ecom-header")?.offsetHeight;
     if(paddingTop < 120){
       paddingTop = 89;
     }
     container && (container.style.setProperty('padding-top', `${paddingTop}px`, 'important' ));
    window.userLoggedIn && fetchTemplateList();
  },[data]);

  const fetchTemplateList = async () => {
    const templateValues = await getResponse(GET_TEMPLATE_LIST_URL, {});
    props.addToTemplateList(templateValues);
  };
  const [toggle, setToggle] = useState(false);
  const [activeNavLink, setActiveNavLink] = useState("");
  const preventClickForMobile = (event) => {
    if(window.innerWidth < 992){
      event.preventDefault();
      let name = event.target.dataset.name || event.target.parentElement.dataset.name;
      if(name){
        setActiveNavLink( activeNavLink === name ? "mobile" : name);
      } else {
        setActiveNavLink('')
      }
    } else {
      activeNavLink && setActiveNavLink(null);
    }
  };

  return (
    <div className="App">
      {props.isMobile ? (
          <MobileRoutes
              cmsData={cmsData}
              data={data}
              headerInformationData={headerInformationData}
              showLoader={showLoader}
              showProgressBar={showProgressBar}
              isMobile={props.isMobile}
          />
      ) : (
      <Router basename={window.baseUrl}>
       {cmsData && <header className="fixed-top ecom-header" id="ecom-header">
          <Header setActiveNavLink={setActiveNavLink} data={data} preventClickForMobile = {preventClickForMobile} activeNavLink={activeNavLink}/>
          <Navbar userSetting={data} preventClickForMobile = {preventClickForMobile} setActiveNavLink={setActiveNavLink} activeNavLink={activeNavLink} headerInformationData={headerInformationData} setToggle={setToggle}
                  toggle={toggle}/>
          <ViewAs userSetting={data} />
          {showProgressBar && <DataProgressBar/>}
        </header>}
        <Switch>
          <Route key="Fast" exact path="/fast/*"  render={(props) => (<Fast {...props} showLoader={showLoader} setActiveNavLink={setActiveNavLink} />)} />
          <Route key="Landing" exact path="/landing/*"  render={(props) => (<Fast {...props} showLoader={showLoader} />)}/>
          <Route key="categoryList" exact path="/*"
            render={(props) => (<CatalogNavigator {...props} showLoader={showLoader} activeNavLink={activeNavLink} setActiveNavLink={setActiveNavLink} setToggle={setToggle}/>)} />
          <Route component={NotFound} />
        </Switch>
        {cmsData &&
          <>
            {data.cookieAgreement !== undefined && <Cookies cookieAgreement={data.cookieAgreement} />}
            <Footer data={ data }/>
          </> 
        }
      </Router>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    addToTemplateList: (templateList) => { dispatch(addToTemplateList(templateList)) },
    pageLabels: (labelList) => { dispatch(pageLabels(labelList)) },
    categoryMenuList: (menuList) => {dispatch(categoryMenuList(menuList))}
  }
};

export default connect(null, mapDispatchToProps)(Routes);