import React, { useEffect, useState } from 'react';
import {connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import {postRequest} from "../apiCalls/ApiCall";
import { PRODUCT_SEARCH_URL} from "../constants/Constants";
import { AddToShoppingCart} from '../helpers/CommonScripts';
import GridView from "./GridView";
import Spinner from './PopupSpinner';

function ViewAll(props) {
  const skus = props.skus;
  const title = props.title;
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(true);
  const label = props.labels;

  useEffect(() => {
    getProductList();
  }, [skus]);


  const getProductList = async () => {
    const response = await postRequest(PRODUCT_SEARCH_URL, {sku: skus, "requestType":"xref"});
    setProductList(response.productList);
    setLoading(false);
  };

  if (loading) return <Spinner />
  return (
    <Modal show={true} size="xl" onHide={props.handleViewAll} id={`${title.replace(" ", "-")}ProductsModal`} aria-labelledby={`${title}ProductModalLabel`} animation={false} size={'xl'}>
      <Modal.Header className="feco-modal-header-primary p-2"  closeButton>
        <Modal.Title as="h6">{title} ({ skus.length } {label?.items})</Modal.Title>
      </Modal.Header>

      <Modal.Body className="modal-width feco-grid-col feco-related-popup">
        {productList.length > 0 && <GridView productList={productList} onClickAddToCart={AddToShoppingCart} title={title} addToCart={ props.addToCart } closeViewAll={props.handleViewAll} />}
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = (state) => {
	return {
		labels: state.commonReducer.pageLabels
	}
};

export default connect( mapStateToProps, null )(ViewAll);