import React from "react";
import { Eclipse } from "react-loading-io";

function PopupSpinner() {
  return (
    <div className="ecom-spinner position-middle" style={{margin: "400px 0px 0 800px"}}>
      <Eclipse size={64} />
    </div>
  )
}

export default PopupSpinner;