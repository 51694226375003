import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useStickyHeader from "./counterbook/UseStickyHeader";
import {useRect} from "./counterbook/useRect";

function ViewToggle(props) {
	const label = props.labels;
	const itemsCountPerPage = props.itemsCountPerPage;
	const itemSortBy = props.queryParams.sortBy === undefined ? props.defaultItemSortBy : props.queryParams.sortBy;
	const {sectionRef, isSticky, minTopRef} = useStickyHeader();
	const{rect, rectRef} = useRect();
	const viewPreference = props.viewPreference;

	useEffect(() => {
		const progressBar = document.getElementById("progress-bar");
		//temporarly hardcoding progress bar height for hotfix.
		minTopRef.current = (document.getElementById("ecom-header")?.offsetHeight+document.getElementById("ecom-breadcrumb")?.offsetHeight) - (progressBar ? 14 : 2);
	}, []);

	function handleSortBy(event) {
		const searchParams = Object.assign(props.queryParams, { "sortBy": event.target.value }, { "page": "1" }, { "pageSize": `${itemsCountPerPage}` });
		props.history.push(`?${props.queryString.stringify(searchParams)}`);
	}
	function handleKeyPress(event, viewMode){
		if(event.key=="Enter"){
			props.handleViewChange(viewMode);
		}
	}
	return (
		<>
			<div className="clearfix"></div>
			<div ref={sectionRef} className={"row feco-mx-sm-0 feco-mx-md-0 " + (viewPreference === "COUNTERBOOK" ? " feco-prodview-header-row" : "")} id="view-toggle-button">
				<div ref={rectRef} className={"col d-flex align-items-center pl-0 pr-0 flex-md-row feco-sm-column " +
				"feco-prodview-header" + (isSticky ? " frozen" : "")}
						 style={viewPreference === "COUNTERBOOK" && rect && isSticky ? {width:rect.width, top: (minTopRef.current)} : {}}>
					{(viewPreference !== "COUNTERBOOK") && <div className="d-flex ie-inline-block feco-w-sm-100">
						<div className="d-flex align-items-center text-muted feco-w-sm-100 feco-mb-sm-1">
							<div className="feco-sm-flex col pl-0 text-nowrap flex-sm-flex feco-sm-align-items-center feco-pr-sm-0">
								<span className="pr-2">({props.productListCount.toLocaleString()}  {label?.items})</span>
								<select className="custom-select custom-select-sm feco-select-width-120 feco-select" id="items-count-per-page" aria-label={label?.select} value={props.itemsCountPerPage} onChange={props.handleSelectChange}>
									<option value="12">12 {label?.perPage}</option>
									<option value="24">24 {label?.perPage}</option>
									<option value="48">48 {label?.perPage}</option>
								</select>
							</div>
						</div>
					</div>}
					<div className="ml-auto d-flex align-items-center text-nowrap ie-inline-block ie-float-right position-relative flex-column flex-md-row feco-w-sm-100 feco-text-sm-wrap p-1">
						{ (viewPreference !== "COUNTERBOOK") &&
							<div className="feco-sm-flex feco-w-sm-100 flex-sm-align-items-center feco-pr-sm-0 feco-mb-sm-1">
								<span className="p-1 ie-float-left feco-text-sm-nowrap">{label?.sortBy}: </span>
								<select className="custom-select custom-select-sm mr-2 ie-float-left feco-mr-sm-0 ie-select-width-170" aria-label={label?.sortBy} id="item-sortby" value={itemSortBy} onChange={handleSortBy}>
									<option value="Top Sellers">{label?.topSellers}</option>
								<option value="Availability">{label?.availability}</option>
									<option value="Best Match">{label?.bestMatch}</option>
									{ (!window.country || ["USA", "CAN"].includes(window.country)) &&
										<>
											<option value="Price Low To High">{label?.priceLowToHigh}</option>
											<option value="Price High To Low">{label?.priceHighToLow}</option>
										</>
									}
								</select>
							</div>
						}
						<div className={`ie-float-left pb-2 ${isSticky ? 'mt-3' : 'mt-2'}`}>
							{props.productListCount <= 3000 && (props.resultType === "counterBook" || (props.resultType === "productList" && props.drawCounterBook)) &&
							((viewPreference === "COUNTERBOOK") ?
							<span className="text-secondary right-border px-2">{label?.catalog}</span> :
							<a className="px-2 right-border cursor-pointer" tabIndex="0" onKeyPress={event => handleKeyPress(event,"COUNTERBOOK")} onClick={() => props.handleViewChange("COUNTERBOOK")}>{label?.viewCatalog}</a>)
							}
							{(viewPreference === "LIST") ?
								<span className="text-secondary px-2 right-border">{label?.viewList}</span> :
								<a className="px-2 right-border cursor-pointer" tabIndex="0" onKeyPress={event => handleKeyPress(event,"LIST")} onClick={() => props.handleViewChange("LIST")} >{label?.viewList}</a>
							}
							{(viewPreference === "GRID") ?
								<span className="text-secondary px-2">{label?.viewGrid}</span> :
								<a className="px-2 cursor-pointer" tabIndex="0" onKeyPress={event => handleKeyPress(event,"GRID")} onClick={() => props.handleViewChange("GRID")} >{label?.viewGrid}</a>
							}
						</div>
					</div>
				</div>
			</div>
			{(viewPreference === "COUNTERBOOK") && <div className={"row toggle-divider top-border" + (isSticky ? " frozen mt-2" : "")}
																									style={viewPreference === "COUNTERBOOK" && rect && isSticky ? {width:rect.width, top: (minTopRef.current + document.getElementById("ecom-breadcrumb")?.offsetHeight)}
																										: {}}/>}
		</>
	)
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};

export default connect( mapStateToProps, null )(ViewToggle);