import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import { setJessionId } from '../helpers/CommonScripts';
import cx from 'classnames';
import $ from "jquery";

function HomeContent(props) {
  const homePageContent = props.homePageContent;
  const cmsScripts = homePageContent?.scripts;
  const label = props.labels;
  $("#cmsScripts").html(cmsScripts);

  return (
    <div className="feco-container-fix feco-container-inner pl-0 pr-0" id="main-container">
      <div id="cmsScripts"></div>
      <input type="hidden" name="pageVal" value="HOME" />
      <div className="body-part-one" dangerouslySetInnerHTML={{ __html: homePageContent["body-part-one"]}}></div>
      <div className="mt-4">
        <div className="row feco-row-cols-lg-5 feco-row-cols-sm-3 feco-row-cols-xs-1 no-gutters pl-2 pr-2">
          {
            homePageContent["catalog-categories"] && homePageContent["catalog-categories"].map((catetgory, index) => {
              return(
                <div className="col card feco-card-home-categ ecom-card-home-categ feco-card rounded-0" key={`category-${index}`}>
                  <div className="my-0 mx-0 d-flex align-items-center ie-img-100 position-relative flex-column w-100 h-100 feco-card-home-height">
                    <div className="h-100 position-relative w-100">
                      <div className="p-2 position-absolute feco-card-home-categ-img d-flex align-items-stretch h-100 w-100"><img src={catetgory.image} className="img-fluid feco-card-home-categ-img-w" alt=""/></div>
                      <div className="position-absolute feco-card-overlay"></div>
                      <div className="feco-card-home-categ-list w-100 h-100">
                        <ul className="nav flex-column h-100 flex-nowrap w-100 position-relative feco-card-home-categ-item">
                          {
                            catetgory.categoryList && catetgory.categoryList.slice(0, 5).map((catgoryhash, i) => {
                              return(
                                <li className="nav-item" key={`home-${i}`}>
                                  <Link className="nav-link" to={setJessionId(`/product/${catetgory.categoryLevelOne}/${catgoryhash.categoryLevelTwo}?fsi=1${catgoryhash.categoryId !==undefined ? '&categoryId='+catgoryhash.categoryId:''}`)}>{catgoryhash.categoryLevelTwo}</Link>
                                </li>
                              )
                            })
                          }
                        </ul>
                        <div className="ecom-home-cate-view-all w-100">
                          <Link to={setJessionId(`/product/${catetgory.categoryLevelOne}?fsi=1${catetgory.categoryId !== undefined ? '&categoryId=' + catetgory.categoryId : ''}`)} className="ecom-home-cate-link text-center feco-text-weight-700">{label?.viewAll}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer feco-card-home-categ-list-link bg-primary text-white text-center cstm-card-footer-home rounded-0">
                    <Link className="nav-link p-0" to={setJessionId(`/product/${catetgory.categoryLevelOne}?fsi=1${catetgory.categoryId !==undefined ? '&categoryId='+catetgory.categoryId:''}`)}>{catetgory.categoryLevelOne}</Link>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <div className="body-part-two" dangerouslySetInnerHTML={{ __html: homePageContent["body-part-two"] }}></div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};

export default connect( mapStateToProps, null )(HomeContent);