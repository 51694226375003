import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Modal from "react-bootstrap/Modal";
import {getResponse, postRequest} from "../apiCalls/ApiCall";
import { GET_NEAR_BY_STORES, AVAILABILITY_RESULT_URL } from "../constants/Constants";
import { removeUnwantedKeysFromObject } from "../helpers/Helpers";
import Spinner from './PopupSpinner';
import { SET_LOCATION } from '../constants/Constants';
import StockData from './StockData';

function CheckOtherLocations(props) {
  const label = props.labels;
  const show = props.showLocations;
  const sku = props.sku;
  const [searchTypeList, setSearchTypeList] = useState({});
  const [productAvailability, setProductAvailability] = useState({});
  const [distanceList, setDistanceList] = useState({});
  const [states, setStates] = useState([]);
  const [otherLocationResult, setOtherLocationResult] = useState([]);
  const [stockData, setStockData] = useState([]);
  const [homeCountry,setHomeCountry] = useState("");

  const [showZipCode, setShowZipCode] = useState(true);
  const [showDistance, setShowDistance] = useState(true);
  const [showCity, setShowCity] = useState(false);
  const [showState, setShowState] = useState(false);
  const [showStoreCode, setshowStoreCode] = useState(false);
  const [showIncludeInventory, setShowIncludeInventory] = useState(true);
  const [emptyStoreMessage, setEmptyStoreMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const branchName = document.getElementById("branch-store5code");

  const [params, setParams] = useState({
    searchType: 'mp_zipCode',
    zipCode: "",
    city: "",
    state: "",
    distance: "",
    latitude: "",
    longitude: "",
    storeCode: "",
    sku: sku,
    onlyIncludeInventory: true
  });


  useEffect(() => {
    getResponse(GET_NEAR_BY_STORES+`?sku=${sku}`, {}).then((response) => {
      setSearchTypeList(response.searchTypeList);
      setProductAvailability(response.productAvailability);
      setDistanceList(response.distanceList);
      setStates(response.states);
      setHomeCountry(response.homeCountry);
      setLoading(false);

      const newParams = Object.keys(params).reduce((object, key) => {
        if (key !== 'sku' && key !== 'onlyIncludeInventory' && key !== 'searchType') {
          object[key] = params[key]
        }
        return object
      }, {});

      Object.keys(newParams).map((key, ind) =>{
        if(key === 'distance'){
          if(response.productAvailability.distance === undefined || parseInt(response.productAvailability.distance) === 0 || response.productAvailability.distance === ''){
            params[key] = Object.keys(response.distanceList)[0]
          }else{
            params[key] = response.productAvailability[key];
          }
        }else if(key === 'state'){
          if(response.productAvailability.state === undefined || response.productAvailability.state === '' || response.productAvailability.state === '0'){
            params[key] = response.states[0]["abbreviation"]
          }else{
            params[key] = response.productAvailability[key];
          }
        }else{
          params[key] = response.productAvailability[key] || '';
        }
        setParams(params);
      });
    });
  }, []);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    params[name] = value;
    setParams(params);

    toggle(value);
    if(value === "gps") {
      setLatLong();
    }
  };

  const handleIncludeInventoryChange = (e) => {
    params[e.target.name] = e.target.checked;
    setParams(params);
  };

  const checkInHomeCountry = (store)=>{
    if(homeCountry=="" || store.countryAbbreviation==homeCountry){
      store["inHomeCountry"] = true;
    }else{
      store["inHomeCountry"] = false;
    }
  }

  const submitForm = (e) => {
    e.preventDefault();
    var bodyContent = removeUnwantedKeysFromObject(params);

    postRequest(AVAILABILITY_RESULT_URL, bodyContent).then((res) => {
      if(res.stores && res.stores.length > 0){
        res.stores.forEach(checkInHomeCountry);
        setOtherLocationResult(res.stores);
        setStockData([]);
        setEmptyStoreMessage(false);
      }else if(res.stockData && res.stockData.length > 0){
        setStockData(res.stockData);
        setOtherLocationResult([]);
        setEmptyStoreMessage(false);
      }else{
        setOtherLocationResult([]);
        setStockData([]);
        setEmptyStoreMessage(true);
      }
    })
  };

  const setLatLong = () => {
    navigator.geolocation.getCurrentPosition(function(position) {
      params["latitude"] = position.coords.latitude;
      params["longitude"] = position.coords.longitude;
    });

  };


  const getMessage = (result) => {
    if(branchName !== null && branchName.innerText === result.store5Code){
      return <span className="border item-availability text-white border-success bg-success">
        <i className="fas fa-check item-availability-icon border-success bg-white text-success ml-1 mr-1 mb-1 mt-1 fa-xs" aria-hidden="true"></i>{label?.currentlyMyBranch}
      </span>
    }else if(result.hubindicator === 1){
      return label?.setAsLocalBranchError
    }else if((undefined === otherLocationResult.isGovtBranchType) || (undefined !== otherLocationResult.isGovtBranchType && otherLocationResult.isGovtBranchType === false)){
      if(result.businessUnitType !== 'GOVT'){
        if(result.inHomeCountry){
          return <a href="#" className="" onClick={(e) => {setBranch(result.store5Code)}}>{label?.make} {result.store5Code} {label?.yourServingBranch}</a>
        }else{
          return <a onClick="return false;" title="Please change your account number to select this branch" className="disabled-branch">{label?.make} {result.store5Code} {label?.yourServingBranch}</a>
        }
      }else{
        return ''
      }
    }else{
      return ''
    }
  };

  const setBranch = (branchName) => {
    getResponse(SET_LOCATION + `/${branchName}`, {}).then((res) => {
      window.location.reload();
    })
  };

 const toggle = (expression) =>{
    if(expression === "mp_zipCode"){
      setShowZipCode(true);
      setShowDistance(true);
      setShowCity(false);
      setShowState(false);
      setshowStoreCode(false);
      setShowIncludeInventory(true)
    }else if(expression === "mp_cityState"){
      setShowZipCode(false);
      setShowDistance(true);
      setShowCity(true);
      setShowState(true);
      setshowStoreCode(false);
      setShowIncludeInventory(true)
    }else if(expression === "mp_storeCode"){
      setShowZipCode(false);
      setShowDistance(false);
      setShowCity(false);
      setShowState(false);
      setshowStoreCode(true);
      setShowIncludeInventory(false)
    }else if(expression === "mp_gps"){
      setShowZipCode(false);
      setShowDistance(true);
      setShowCity(false);
      setShowState(false);
      setshowStoreCode(false);
      setShowIncludeInventory(true)
    }else if(expression === "mp_remoteStockCheck"){
      setShowZipCode(false);
      setShowDistance(false);
      setShowCity(false);
      setShowState(false);
      setshowStoreCode(false);
      setShowIncludeInventory(false)
    }
 };

  if (loading) return <Spinner />
  return (
    <Modal show={show} onHide={props.handleClose} className="ie-feco-loca-popup" animation={false} size={otherLocationResult.length > 0 ? 'lg':''}>
      <Modal.Header className="feco-modal-header-primary p-2"  closeButton>
        <Modal.Title as="h6">
          <span className="ml-2 mr-5">{label?.CheckOtherLocations}</span>
          {window.branchCode &&
            <span className="ml-5">
              <i className="fa fa-map-marker-alt"></i> {label?.myBranch}: {window.branchCode}
            </span>
          }
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div classNameName="" >
          <form method="post" onSubmit={submitForm}>
            <div className="panel-body">
              <div className="row">
                <div className="col-xs-12 col-sm-4">
                  <label className="font-weight-600">{label?.searchType}:</label>
                </div>

                <div className="col-xs-12 col-sm-6  ">
                  <select id="searchType" name="searchType" className="form-control" data-role="toggler" onChange={(e) => {handleChange(e)}}>
                    {
                      searchTypeList && Object.keys(searchTypeList).map(function(key, index) {
                        return(
                          <option value={"mp_" + key} key={`searchtype-${index}`}>{searchTypeList[key]}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 mt-1 mb-1"></div>
              {showZipCode &&
                <div className="row ie-10-d-block">
                  <div className="col-xs-12 col-sm-4">
                    <label className="font-weight-600">{label?.zipCode}:</label>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <input id="zipCode" name="zipCode" className="form-control" type="text" defaultValue={productAvailability.zipCode} onChange={(e) => {handleChange(e)}}/>
                  </div>
                </div>
              }

              <div className="col-xs-12 col-sm-12 mt-1 mb-1"></div>

              {showCity &&
                <div className="row ie-10-d-block">
                  <div className="col-xs-12 col-sm-4">
                    <label className="font-weight-600">{label?.city}:</label>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <input id="city" name="city" className="form-control" type="text" defaultValue={productAvailability.city} onChange={(e) => {handleChange(e)}} />
                  </div>
                </div>
              }

              <div className="col-xs-12 col-sm-12 mt-1 mb-1"></div>

              {showState &&
                <div className="row row ie-10-d-block">
                  <div className="col-xs-12 col-sm-4">
                    <label className="font-weight-600">{label?.state}:</label>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <select id="state" name="state" className="form-control" defaultValue={productAvailability.state} onChange={(e) => {handleChange(e)}}>
                      {
                        states.length > 0 && states.map(function(state, ind) {
                          return(
                            <option value={state.abbreviation} key={`state-${ind}`}>{state["state"]}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
              }

              <div className="col-xs-12 col-sm-12 mt-1 mb-1"></div>
              {showDistance &&
                <div className="row ie-10-d-block">
                  <div className="col-xs-12 col-sm-4">
                    <label className="font-weight-600">{label?.distance}:</label>
                  </div>

                  <div className="col-xs-12 col-sm-6">
                    <select id="distance" name="distance" className="form-control" defaultValue={parseInt(productAvailability.distance)} onChange={(e) => {handleChange(e)}}>
                      {
                        distanceList && Object.keys(distanceList).map(function (key, index) {
                          return (
                            <option value={parseInt(key)} key={`distance-${index}`}>{distanceList[key]}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
              }

              <div className="col-xs-12 col-sm-12 mt-1 mb-1"></div>
              {showStoreCode &&
                <div className="row ie-10-d-block">
                  <div className="col-xs-12 col-sm-4">
                    <label className="font-weight-600">{label?.branchCode5Letter}:</label>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <input id="storeCode" name="storeCode" className="form-control" type="text" defaultValue={productAvailability.storeCode} onChange={(e) => {handleChange(e)}}/>
                  </div>
                </div>
              }

              <div className="col-xs-12 col-sm-12 mt-1 mb-1"></div>
              {showIncludeInventory &&
                <div className="row ie-10-d-block">
                  <div className="col-xs-12 col-sm-4 ie-10-ml-col-4"></div>
                  <div className="col-xs-12 col-sm-7 ie-10-ml-col-4">
                    <input className="mr-2" type="checkbox" name="onlyIncludeInventory" defaultChecked={"checked"} id="onlyIncludeInventory" onChange={(e) => {handleIncludeInventoryChange(e)}} />
                    {label?.includeLocationWithInventory}
                  </div>
                </div>
              }

              <div className="row ie-10-d-block mt-3 mb-1">
                <div className="col-xs-12 col-sm-4">&nbsp;</div>
                <div className="col-xs-12 col-sm-7 ie-10-ml-col-4">
                  <input type="submit" className="btn btn-primary" name="btnSearch" value="Check Availability" />
                </div>
              </div>
            </div>
          </form>
        </div>

        {otherLocationResult.length > 0 &&
          <div className="mt-4">
            <table className="table">
              <thead>
                <tr>
                  <th className="font-weight-600">{label?.availability}</th>
                  <th colspan="2">{label?.branch}</th>
                </tr>
              </thead>


              <tbody>
              {
                otherLocationResult.map((result, index) => {
                  return (
                    <tr key={`result-${index}`}>
                      <td>
                        {
                          result.isAvailable ?
                          <span className="border item-availability text-blue border-primary" title={result.title}>
                            <i className="fas fa-check item-availability-icon bg-primary mr-4" aria-hidden="true"></i>{label?.inStock}
                          </span>
                          :
                          <span className="border item-availability text-muted border-secondary" title={result.title}>
                            <i className="fas fa-times item-availability-icon bg-secondary mr-4" aria-hidden="true"></i>{label?.outOfStock}
                          </span>
                        }
                      </td>

                      <td>
                        <div className="">
                          <div className="row">
                            <div className="col-sm-9">
                              <span className="text-blue font-weight-bold">
                                <a href={`/locations/details/${result.store5Code.toLowerCase()}`}>{result.store5Code}</a>
                              </span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-6">
                              <div>{result.address1}</div>
                              <div>{result.address2}</div>

                              <div>
                                <span>{result.city},</span>&nbsp;
                                <span>{result.stateAbbreviation}</span>&nbsp;
                                <span>{result.countryAbbreviation}</span>&nbsp;
                                <span>{result.postal}</span>
                              </div>
                            </div>


                            <div className="col-sm-6">
                              <div>P:<a href={`tel: ${result.phone}`}> {result.phone}</a></div>
                              <div>F:{result.fax}</div>
                              <div className="email">
                                <a href={`mailto: ${result.email}`}>{result.email}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        {getMessage(result)}
                      </td>
                    </tr>
                  )
                })
              }
              </tbody>
            </table>
          </div>
        }
        {stockData.length > 0 && <StockData stockData={stockData} />}
        {emptyStoreMessage && <div className="ie-float-left ie-width-full">{label?.noAvailableInventory}</div>}
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};

export default connect( mapStateToProps, null )(CheckOtherLocations);