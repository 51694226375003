export const PAGE_LABELS = {
  "newOrderTemplate": "New Order Template",
  "myOrderTemplates": "My Order Templates",
  "allProducts": "All Products",
  "noResultsFound": "No results found",
  "noResults": "No Results Found",
  "showing": "Showing",
  "resultsFor": "Results for",
  "showAll": "Show All",
  "Loading": "Loading...",
  "results": "Results",
  "viewMore": "View More",
  "viewLess": "View Less",
  "currentlyMyBranch": "Currently My Branch",
  "setAsLocalBranchError": "This is a corporate location and cannot be set as a local branch",
  "make": "Make",
  "yourServingBranch": "your servicing branch.",
  "CheckOtherLocations": "Check Other Locations",
  "myBranch": " My Branch",
  "searchType": "Search Type",
  "zipCode": "Zip Code",
  "city": "City",
  "state": "State",
  "distance": "Distance",
  "branchCode5Letter": "Branch Code (5 Letter)",
  "includeLocationWithInventory": "Only include locations with inventory",
  "checkAvailability": "Check Availability",
  "availability": "Availability",
  "branch": "Branch",
  "inStock": "In stock",
  "outOfStock": "Out-of-stock",
  "noAvailableInventory": "No available inventory.",
  "noPriceAvailable": "No Price Available",
  "localBranch": "Local Branch",
  "forPricing": "for Pricing",
  "description": "Description",
  "sku": "SKU",
  "manufacturerPartNo": "Manufacturer Part No.",
  "manufacturer": "Manufacturer",
  "price": "Price",
  "Qty": "QTY",
  "add": "Add",
  "compliance": "Compliance",
  "compare": "Compare",
  "clearAll": "Clear all",
  "productCompare": "Product Compare",
  "AddToCompareAtLeastOne": "Please select more than one product to compare",
  "AddToCompareMaxQty5": "The maximum number of products you may compare is five (5)",
  "searchInstead": "Search instead",
  "Copyright": "Copyright © 2021 Fastenal Company. All Rights Reserved.",
  "english": "English",
  "français": "Français",
  "español": "Español",
  "signUp": "Sign Up",
  "sloganText": "WE ARE WHERE YOU ARE.",
  "searchPlaceHolder": "Search keyword, model or part number",
  "yourSku": "Your Sku",
  "fastenalPartNo": "Fastenal Part No. (SKU)",
  "contactYour": "Contact Your",
  "cartErrorMsgZeroQty": "Minimun Item Quantity should be 1",
  "addToCart": "ADD TO CART",
  "myCart": "My Cart",
  "item": "Items",
  "cart": "Cart",
  "moreItemsInCart": "...More items in cart",
  "cartSummaryNote": "Note: Total price reflects all items in cart",
  "viewShoppingCart": "View Shopping Cart",
  "shoppingCartEmptyMsg": "Your shopping cart is empty.",
  "browse": "Browse",
  "browseProducts": "Browse Products",
  "products": "Products",
  "addOrderTemplate": "Add Order Template",
  "fastOrderPad": "Fast Order Pad",
  "items": "Items",
  "home": "Home",
  "businessUnit": "Business Unit",
  "quantity": "Quantity",
  "distanceAndMiles": "Distance(miles)",
  "list": "List",
  "machine": "Machine",
  "image": "Image",
  "desc": "Desc",
  "Positions": "Position(s)",
  "inventoryTurns": "Inventory Turns",
  "inventoryValue": "Inventory value",
  "minOrMax": "Min/Max",
  "skuValue": "Sku Value",
  "endSession": "End Session",
  "viewingAs": "Viewing As",
  "perPage": "Per Page",
  "sortBy": "Sort by",
  "bestMatch": "Best Match",
  "topSellers": "Top Sellers",
  "priceLowToHigh": "Price Low To High",
  "priceHighToLow": "Price High To Low",
  "viewCatalog": "View Catalog",
  "viewList": "View List",
  "viewGrid": "View Grid",
  "productStandards": "Product Standards",
  "CADDrawings": "CAD Drawings",
  "fastenalProductStandard": "Fastenal Product Standard",
  "fnlProductStandard" : "FNL Product Standard",
  "productAttributes": "Product Attributes",
  "productWeight": "Product Weight",
  "myInventoryLocations": "My Inventory Location(s)",
  "binStock": "Bin Stock",
  "select": "SELECT",
  "vending": "Vending",
  "supplyChain": "Supply Chain",
  "free": "Free",
  "locker": "Locker",
  "relatedItems": "Related Items",
  "alternativeItems": "Alternative Items",
  "unableToAddProductToCart": "Unable to add this product to cart",
  "somethingWentWrong": "Opps, looks like something went wrong!!",
  "searchTerms": "Search Term(s)",
  "account": "Account",
  "register": "Register",
  "myAccount": "My Account",
  "signInorRegister": "Sign In or Register",
  "emailAddress": "Email Address",
  "forgotYour": "Forgot your",
  "password": "password",
  "findAnotherBranch": "Find Another Branch",
  "findBranch": "Find a Branch",
  "localBranchNotYetSelected": "You do not have a local branch selected.",
  "menu": "Menu",
  "search": "Search",
  "exactCustomerProductMatch": "Exact customer product match shown below.",
  "clickHere": "Click here",
  "forAdditionalSearchResults": "for additional search results.",
  "notes": "Notes",
  "total": "Total",
  "viewAll": "View All",
  "view": "View",
  "catalog": "Catalog",
  "YourSearch": "Your search",
  "hasNoMatchingResults": "has no matching results.",
  "noPriceAvailableMessage": "This item does not have a published price.  Please enter your quantity in the Qty box and add the item to your cart.\n" +
      "After checkout, your local Fastenal Branch will contact you with pricing information.",
  "noResultsMessage": "We were unable to find a match for your search term. Please try another keyword, part number or cross reference.",
  "notAvailableMessage": "The product you are looking for is not currently available on Fastenal.com/FR",
  "browseOurCategories": "Browse our categories",
  "contactYourLocalBranch": "Contact your Local Branch",
  "accessWebsite": "Click here to access our website in English.",
  "contactLocalBranch": "Or contact your local branch for help with this article.",
  "BranchIsReadyToHelpMessage": "Your knowledgeable Fastenal branch is ready to help!",
  "gainAccessToFastenalAccessories": "to gain access to the entire Fastenal offerings as well as sourcing options.",
  "enquiryEmailMessage": "Still have a question? Send your inquiry to",
  "brand": "Brand",
  "viewDetails": "View Details",
  "uom": "UOM",
  "callForPrice": "Call For Price",
  "min": "MIN",
  "max": "MAX",
  "searchWarning": "Your search term must contain at least 2 characters and no more than 256 characters.",
  "serviceUnavailable": "Sorry, this service is unavailable. We're working on bringing it back.",
  "backSoon": "We will be back soon",
  "defaultTitle" : "Fastenal Industrial Supplies, OEM Fasteners, Safety Products & More",
  "defaultMetaDescription" : "Fastenal is the largest fastener distributor in North America. Shop our huge selection of OEM, MRO, construction, industrial, and safety products.",
  "forgotPassword" : "Forgot password",
  "emailAddressStar" : "Email Address",
  "tryAgain" : "No Data, Please try again later.",
  "reOrder":"REORDER"
};
