import React, {useEffect, useState} from 'react';
import {showAllParam, constructBasicURLParam, appendQuerySeparator} from '../helpers/Helpers'
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { imgSrc } from '../helpers/CommonScripts';

function AggregationValue(props) {
	const [checked, setChecked] = useState(false);
	const aggregationValue = props.aggregationValue;
	const attributeFilters = props.attributeFilters;
	const expressData = props.expressData;
	const selectCheckBox = Object.keys(attributeFilters).map((attribute) => {
		return (attribute.split(".")[1] === aggregationValue.displayName);
	}).includes(true);

	useEffect(() => {
		if ((attributeFilters[aggregationValue.filterName] && (attributeFilters[aggregationValue.filterName]).includes(aggregationValue.filterValue)) || selectCheckBox) {
			setChecked(true);
		} else {
			setChecked(false);
		}
	}, [aggregationValue.filterName, aggregationValue.filterValue, attributeFilters, selectCheckBox]);

	const popover = (
		<Popover>
			<Popover.Title as="h3">{expressData && expressData.Heading}</Popover.Title>
			<Popover.Content>
				{expressData && expressData.message}
			</Popover.Content>
		</Popover>
	);

	function removeCurrentFromArray(arr, current) {
		const itemsWithoutCurrent = arr.filter(function (x) {
			return x !== current;
		});
		return itemsWithoutCurrent;
	}

	function addCurrentToArray(arr, current) {
		arr.push(current)
		return arr;
	}

	function handleAggregationClick() {
		let aggergationName = aggregationValue.filterName;
		let aggregationValueName = aggregationValue.filterValue;
		let aggregation_val_names;
		let params = constructBasicURLParam(props.searchRequest, aggergationName);
		if (["Green", "Supplier Diversity", "Recycling Program", "Green Building Qualifier"].includes(aggregationValue.displayName) && props.aggName === "Special Interest") {
			let aggId = aggregationValue.displayName === "Green" ? "Green Certifications" : aggregationValue.displayName;
			let isChecked = document.getElementById(aggregationValue.displayName + '_' + props.index).checked;
			isChecked ? document.getElementById(aggId).className = document.getElementById(aggId).className.replace("d-none","") : document.getElementById(aggId).className +="d-none";
			setChecked(isChecked);
		} else {
			if (attributeFilters[aggergationName]) {
				if (((attributeFilters[aggergationName]).includes(aggregationValueName))) {
					aggregation_val_names = removeCurrentFromArray(attributeFilters[aggergationName], aggregationValueName).join('->');
				} else {
					aggregation_val_names = addCurrentToArray(attributeFilters[aggergationName], aggregationValueName).join('->');
					//to keep the original attributeFilters value, as it was affecting unchecking any checked attribute filter
					attributeFilters[aggergationName] = removeCurrentFromArray(attributeFilters[aggergationName], aggregationValueName);
				}
			} else {
				aggregation_val_names = aggregationValueName;
			}

			if (aggregation_val_names) {
				params = appendQuerySeparator(params) + aggergationName + "=" + encodeURIComponent(aggregation_val_names);
				params = params + showAllParam(props.showAllArray, false, aggergationName);
			} else if (params) {
				params = params + showAllParam(props.showAllArray, true, aggergationName);
			}
			props.appendCategoryAndReplace(params);
		}
	}

	return (
		<div className="list-group-item list-group-item-action border-0 rounded-0 cursor-pointe py-0 px-2 cursor-pointer">
			<div className="form-check">
				<span>
					<input className="form-check-input" type="checkbox" onChange={() => {handleAggregationClick()}} checked={checked} id={`${aggregationValue.displayName}_${props.index}`}/>
				</span>
				{aggregationValue.displayName === "In Stock Indicator" ?
					<label className="form-check-label cursor-pointer" htmlFor={`${aggregationValue.displayName}_${props.index}`}>
						<img src={imgSrc("/static/images/express-logo-small.png")} className="feco-icon-express-sm" alt={aggregationValue.displayName} />
						<span> ({aggregationValue.count})</span>
						<OverlayTrigger placement="right" overlay={popover}>
							<i className="fas fa-info-circle fa-1x text-primary ml-1" ></i>
						</OverlayTrigger>
					</label>
					:
					<label className="form-check-label cursor-pointer" htmlFor={`${aggregationValue.displayName}_${props.index}`} dangerouslySetInnerHTML={{ __html: `<span className="">${aggregationValue.displayLabel}</span> (${aggregationValue.count})` }}></label>
				}
			</div>
		</div>
	)
}

export default AggregationValue;