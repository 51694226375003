import { connect } from 'react-redux';

const NonStaticRedirects = (props) => {
    const label = props.labels;
    const data = props.nonStaticRedirectsData;

    return (
        <>
            {data.length > 0 &&
                <>
                    <p className="row border-bottom mb-0">
                    <h6 className="font-weight-normal">{label?.nonStaticTitle}</h6>
                    </p>
                    <div className="row feco-mx-sm-0 feco-mx-md-0 row-cols-3 mb-1 ie-float-left ie-width-full">
                        {data.map((content) => {
                            return <div className="pr-2">
                                <a href={content.url} target={content.url.includes('/fast/') ? "" : "_blank"}>
                                <div className="d-flex mt-2 ie-width-full ie-float-left ie-mb-10 border align-items-center p-1">
                                    { content.pageThumbnailImage &&
                                    <div className="d-flex align-self-start feco-thumbnail-sm ie-float-left ie-img-space-1 border">
                                        <img src={content.pageThumbnailImage}
                                             className="rounded float-left ie-img-responsive" alt="..."/>
                                    </div>
                                    }
                                    <div className="pl-2 flex-grow-1 ie11-catalog-text category-description">
                                        <h6 className= "default-nav-link pt-1" >{content.pageTitle}</h6>
                                        {content.shortDescription && <p title={content.shortDescription} className="feco-para-ellipses text-dark">
                                            {content.shortDescription}
                                        </p>}
                                    </div>
                                </div>
                                </a>
                            </div>})
                        }
                    </div> 
                </>
            }
        </>
    )
}


const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};

export default connect( mapStateToProps, null )(NonStaticRedirects);