import React from 'react';
import { connect } from 'react-redux';
import { FaShoppingCart } from "react-icons/fa";

function ShoppingCartSummary(props) {
  const label = props.labels;
  const cartSummary = (props.cartSummarys && Object.keys(props.cartSummarys).length === 0) ? (props.cartSummary ? props.cartSummary: null) : props.cartSummarys;
  return (
      <li className="nav-item position-relative feco-cart-flyout-menu" onMouseOver={() => {props.setToggle(false)}}>
        <a className="nav-link d-flex flex-column flex-lg-row justify-content-center" href="/shopping-cart" data-name="cart">
            <FaShoppingCart size="2em" className="mr-2 mt-1"/>
          <div className="desktop-only ecom-wrap-nav-text">
            {label?.myCart}
            {(props.myAccountInfo?.length > 0 ||  (props.userRole && props.userRole === 'GUEST')) && <div className="nav-link-sub">{label?.item}<span
                className="ecom-cart-count">({cartSummary && cartSummary.count ? cartSummary.count : 0 })</span>
            </div>}
          </div>
          <div className="d-block d-lg-none text-center text-lg-left nav-link-mobile">{label?.cart}</div>
        </a>
      <div className="feco-cart-dropdown feco-header-dropdown pt-2 ie-header-dropdown">
        {((cartSummary && cartSummary.lineItems && cartSummary.lineItems.length > 0) ?
          <div className="ecom-header-cart pb-2">
            <ul className="list-unstyled m-2">
              {
                cartSummary.lineItems.slice(0, 10).map((cartItem, i) => {
                  var lineTotal = cartItem.lineTotal;
                  return (
                    <li className="p-0" key={i}>
                      <div className="row">
                        <div className="col-9 ie-float-left">
                          <a href={`/product/details/${cartItem.sku && cartItem.sku}`}>
                            <span className="small">({cartItem.qty})</span> {cartItem.description}
                          </a>
                        </div>
                        <div className="col-3 text-right ie-float-left">
                            {lineTotal === 0 ? "N/A" : `${cartSummary && cartSummary.currencySymbol}${lineTotal}`}
                        </div>
                      </div>
                    </li>
                  )
                })
              }{ cartSummary && cartSummary.count > 10 &&
                <li className="p-0">
                  <a className="pr-2" href="/shopping-cart">
                      {label?.moreItemsInCart}
                  </a>
                </li>
              }
              <li className="p-0 mt-1">
                <div className="row font-weight-bold">
                  <div className="col-9 ie-float-left">
                      {label?.total} ({cartSummary && cartSummary.currencyCode})
                  </div>
                  <div className="col-3 text-right ie-float-left">
                    {`${cartSummary && cartSummary.currencySymbol}${cartSummary.orderTotal}`}
                  </div>
                </div>
              </li>
              { cartSummary && cartSummary.count > 10 &&
                <li className="p-0 mb-n1">
                  <span className="pl-1 pr-1">{label?.cartSummaryNote}</span>
                </li>
              }
            </ul>
            <hr className="border-secondary mb-2" />
            <a className="p-2" href="/shopping-cart">
                {label?.viewShoppingCart}
            </a>
          </div>
        :
         <>
           <div className="ecom-cart-empty p-2">
              <p>{label?.shoppingCartEmptyMsg}</p>
           </div>
           <hr/>
           <div className="p-2">
           <span>
              <a className="button btn empty-cart-button bg-primary text-white feco-btn-text-sm-normal" href="/product/all">{label?.browseProducts}</a>
              <a className="button btn empty-cart-button bg-primary text-white ml-2 feco-btn-text-sm-normal feco-ml-sm-0" href="/order-templates">{label?.addOrderTemplate}</a>
              <a className="button btn empty-cart-button bg-primary text-white ml-2 feco-btn-text-sm-normal feco-ml-sm-0" href="/shopping-cart#fast-order-pad">{label?.fastOrderPad}</a>
           </span>
           </div>
         </>
        )}
      </div>
      </li>
  )
}

const mapStateToProps = state => {
  return{
    cartSummarys: state.cartReducer.addedProducts,
    labels: state.commonReducer.pageLabels
  }
};
export default connect(mapStateToProps, null)(ShoppingCartSummary)