import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom';
import { setJessionId } from '../helpers/CommonScripts';
import {connect} from "react-redux";
import {sendCategoryClickAnalytics} from "../helpers/CommonScripts";

const BreadCrumb = (props) => {
	const label = props.labels
	const bredCrumbValues = (props.breadCrumbsData !== undefined) ? props.breadCrumbsData : [];
	const landingPageUrl = window.landingPageUrl;
	const [bredcrumbWidth, setBredcrumbWidth] = useState("");

	let breadCrumbsArray = [];
	let breadCrumbsLabels = [];
	let categoryIds = [];
	bredCrumbValues && Object.entries(bredCrumbValues).map(([key, value]) => (
		(value !== null) && key.toString().includes("Label") ? breadCrumbsLabels.push(value) : key.toString().includes("Id") ? categoryIds.push(value) : breadCrumbsArray.push(encodeURIComponent(value))
	))
	const breadCrumbAnchors = [];
	const breadcrumbLength = breadCrumbsArray.length
	let breadCrumbAnchor;

	if(props.currentProductFamily && props.resultType && (props.resultType === "counterBook" || props.resultType === "productList")){
		breadCrumbsArray.push(encodeURIComponent(props.currentProductFamily.name))
	}
	const breadCrumbItems = breadCrumbsLabels.slice(0, -1);
	const didYouMean = props.didYouMean;
	const lastItem = breadCrumbsLabels.slice(-1)[0]

	useEffect(() => {
		setBredcrumbWidth(document.getElementById("main-container")?.offsetWidth-34);
	}, [])

	for (let i = 0; i < breadcrumbLength - 1; i++) {
		let res = new Array(i + 1).fill(true).map((e, ind) => breadCrumbsArray[ind]);
		breadCrumbAnchor = <Link className="nav-link active" to={setJessionId(`/product/${res.join("/")}${categoryIds[i] !== undefined ? '?categoryId='+categoryIds[i]:""}`)}>{breadCrumbsLabels[i]}<i className="fas fa-chevron-right "></i></Link>
		breadCrumbAnchors.push(breadCrumbAnchor);
	}

	useEffect(() => {
		setBredcrumbWidth(document.getElementById("main-container")?.offsetWidth-35);
	}, [])

	return (
		<>
			<div className="feco-breadcrumb-row" style={{width: bredcrumbWidth}}>
				<div className="bg-white border ecom-breadcrumb mb-2 mt-2 ie-breadcrumb" id="ecom-breadcrumb">
					<ul className="nav ml-1">
						<li className="nav-item">
							<Link className="nav-link active" to={setJessionId(landingPageUrl)}>
							{label?.home} <i className="fas fa-chevron-right"></i>
							</Link>
						</li>
						{ breadCrumbsArray &&
							<>
								<li className="nav-item">
									<Link className="nav-link active" to={setJessionId(`/product/all`)} >
									{label?.allProducts} {(breadCrumbsArray.length > 0) && <i className="fas fa-chevron-right "></i>}
									</Link>
								</li>
								{breadCrumbItems.map((breadCrumb, i) => {
									const res = breadCrumbsArray.map(encodeURIComponent).slice(0, -(breadCrumbItems.length -i));
									return (
										<li className="nav-item" key= {i}>
											<Link onClick={()=>{let category = {};category['name']=breadCrumb; category['categoryId']=categoryIds[i];category['categoryLevelOne']=(i>=0) && breadCrumbsArray[0]?decodeURIComponent(breadCrumbsArray[0]):"";category['categoryLevelTwo']=(i>=1) && breadCrumbsArray[1]?decodeURIComponent(breadCrumbsArray[1]):"";category['categoryLevelThree']=(i>=2) && breadCrumbsArray[2]?decodeURIComponent(breadCrumbsArray[2]):"";category['categoryLevelFour']=(i>=3) && breadCrumbsArray[3]?decodeURIComponent(breadCrumbsArray[3]):"";category['categoryLevelFive']=(i>=4) && breadCrumbsArray[4]?decodeURIComponent(breadCrumbsArray[4]):"";sendCategoryClickAnalytics(category,i+3,"Breadcrumbs");}} className="nav-link active" to={setJessionId(`/product/${res.join("/")}${categoryIds[i] !== undefined ? '?categoryId=' + categoryIds[i] : ""}`)} dangerouslySetInnerHTML={{ __html: decodeURIComponent(breadCrumb)+"<i class='fas fa-chevron-right'></i>"}}></Link>
										</li>
									)
								})}
								{(props.resultType === 'productDetail' && lastItem) ?
									<li className="nav-item" key= "bredCrumbPdp">
										<Link onClick={()=>{let category= {};category['name']=lastItem;category['categoryId']=categoryIds.slice(-1)[0] !== undefined ? categoryIds.slice(-1)[0] : "";category['categoryLevelOne']=breadCrumbsArray[0]?decodeURIComponent(breadCrumbsArray[0]):"";category['categoryLevelTwo']=breadCrumbsArray[1]?decodeURIComponent(breadCrumbsArray[1]):"";category['categoryLevelThree']=breadCrumbsArray[2]?decodeURIComponent(breadCrumbsArray[2]):"";category['categoryLevelFour']=breadCrumbsArray[3]?decodeURIComponent(breadCrumbsArray[3]):"";category['categoryLevelFive']=breadCrumbsArray[4]?decodeURIComponent(breadCrumbsArray[4]):"";sendCategoryClickAnalytics(category,breadCrumbsArray.length+2,"Breadcrumbs")}} className="nav-link active" to={setJessionId(`/product/${breadCrumbsArray.join("/")}${categoryIds.slice(-1)[0] !== undefined ? '?categoryId=' + categoryIds.slice(-1)[0] : ""}`)} dangerouslySetInnerHTML={{ __html: decodeURIComponent(lastItem)}}></Link>
									</li>
								:
									<li className="nav-item">
										<Link className="nav-link disabled" aria-hidden='true'  to="/" dangerouslySetInnerHTML={{__html: lastItem && decodeURIComponent(lastItem)}}></Link>
									</li>
								}
							</>
						}

						{props.resultType === 'noResults' && ((didYouMean && didYouMean.length > 1) || didYouMean === undefined) &&
							<li className="nav-item ml-n4">
								<Link className="nav-link font-weight-bold" to="/"><i className="fas fa-chevron-right mr-2"> </i>{label?.noResultsFound }</Link>
							</li>
						}
					</ul>
				</div>
			</div>
			<div className="feco-breadcrumb-white-space" id="breadcrumb-white-space"></div>
		</>
	)
};

const mapStateToProps = (state) => {
	return {
		labels: state.commonReducer.pageLabels
	}
};
export default connect( mapStateToProps, null )(BreadCrumb);