import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { addToCart } from '../actions/cartActions';
import {ADD_TO_CART_RETURN_TO_REQUISITION, COMPLETE_CHECKOUT} from "../constants/Constants";
import {AddToShoppingCart, errorMessage} from "../helpers/CommonScripts";
import {postRequest} from "../apiCalls/ApiCall";

import ReactDOM from "react-dom";


function PunchoutForm({productDetail, props, label}) {

    const AddItemReturnRequisition = (event) => {
        const cartQuantity = event.target.parentElement.parentElement.parentElement.getElementsByTagName("input")[0];
        const selectedQty = (cartQuantity && cartQuantity.value) ? cartQuantity : event.target.parentElement.querySelector(`#selected_quantity_${productDetail.sku}`);
        const quantity = parseInt(selectedQty ? selectedQty.value : document.getElementById(`selected_quantity_${productDetail.sku}`).value);
        let ua = window.navigator.userAgent;
        let msie = ua.indexOf('MSIE ');
        if (isNaN(quantity) || quantity < 1) {
            errorMessage(msie, props.labels?.cartErrorMsgZeroQty)
        }else {
            postRequest(ADD_TO_CART_RETURN_TO_REQUISITION, {"sku":productDetail.sku, "quantity":quantity}).then((res) => {
                if (res !== undefined) {
                    var form = document.getElementById("ProductAddForm")
                    var input = document.getElementsByName('cxml-urlencoded')[0]
                    input.value = res.punchoutMessage?.replaceAll("&reg;","[REG]").replaceAll("&deg;","[DEG]").replaceAll("&trade;","[TM]")
                    form.action = res.postbackUrl
                    postRequest(COMPLETE_CHECKOUT).then(() => {
                        form.submit()
                    });
                } else {
                    errorMessage(msie, props.labels?.cartErrorMsg);
                }
            });
        }
    };

    return (
        <div>
            <form action="" target="" method="POST" name="ProductAddForm" id="ProductAddForm">
                <input type="hidden" value={productDetail.sku} name="sku"/>
                <input type="hidden" value={productDetail.description} name="description"/>
                <input type="hidden" value="" name="cxml-urlencoded"/>
            </form>
            <button type="submit" className="btn btn-primary ecom-button ecom-button-highlight ie-mt-0" onClick={(e) => { AddItemReturnRequisition(e, productDetail, props, "Product Detail") }} >
                <i className="fa fa-shopping-cart" aria-hidden="true"/> {label?.addAndRequisition}
            </button>
        </div>
    )
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};
/*const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (shoppingCartSummary) => {dispatch(addToCart(shoppingCartSummary))}
  }
};*/

export default connect(mapStateToProps)(PunchoutForm);