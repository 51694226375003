// =============== PRODUCT SEARCH STARTS HERE =====================================================
export const PRODUCT_SEARCH_URL = "/api/product-search";

export const AGGREGATIONS_URL = `${PRODUCT_SEARCH_URL}/aggregations/show-all`;
export const ACCEPT_COOKIE = "/user/cookie-agreement";
export const ADD_TEMPLATE_URL = "/api/add-template";
export const ADD_TO_CART_URL = "/api/add-to-cart";
export const ADD_TO_TEMPLATE_URL = "/api/add-to-template";
export const AVAILABILITY_RESULT_URL = "/api/product-search/availability-results-redesign";
export const BIN_VENDING_STOCK_CHECK_URL = "/api/get-Vending-Binstock";
export const ADD_TO_CART_RETURN_TO_REQUISITION = "/api/add-to-cart-return-requisition";
export const COMPLETE_CHECKOUT = "/api/completeCheckout";
export const BLOCK_COOKIE = "/user/cookie-agreement";
export const CATEGORY_MENU_URL = `${PRODUCT_SEARCH_URL}/category-menu`;
export const CHECK_ADDITIONAL_RESULTS = `${PRODUCT_SEARCH_URL}/check-additional-results`;
export const CMS_CONTENT_URL = "/api/cms/cms-contents";
export const GET_INVENTORY_LOCATIONS_URL = "/api/get-Inventory-Locations";
export const GET_NEAR_BY_STORES = `${PRODUCT_SEARCH_URL}/near-by-stores`;
export const GET_TEMPLATE_LIST_URL = "/api/get-template-list";
export const HOME_PAGE_URL = "/api/cms/home-page";
export const LOGIN_URL = "/azurelogin/login";
export const LOGOUT_URL = "/azurelogin/sign-out";
export const NON_STATIC_REDIRECTS = `${PRODUCT_SEARCH_URL}/non-static-redirects`;
export const PRODUCT_SUGGESTIONS_URL = `${PRODUCT_SEARCH_URL}/suggestion`;
export const PAGE_LABELS_URL = `${PRODUCT_SEARCH_URL}/react-labels`;
export const PROMOTION = "/api/product-search/promotions";
export const CUSTOM_UI_CONTENT = "/api/product-search/customUIContent";
export const SET_LOCATION = "/locations/set-branch";
export const STATIC_PAGE_URL = "/api/cms/static-content?path=";
export const USER_LOGIN_URL = "/api/user-settings";
export const USER_HEADER_INFORMATION_URL = "/api/header-information";
export const VIEW_PREFERENCE_URL = "/api/product-search/product-view-preference";
export const XREF_PRODUCTS_URL = "/api/product-search/xref";
