import React, { useState} from 'react';
import ComplianceDetail from './ComplianceDetail';
import AddToCartTemplate from './AddToCartTemplate';
import { AddToShoppingCart, sendPDAnalyticsData, imgSrc, setJessionId} from '../helpers/CommonScripts';
import {addToCart} from "../actions/cartActions";
import { connect } from "react-redux";
import ProductAvailability from "./ProductAvailability";
import { Link } from 'react-router-dom';
import { onChangeInputQuantity } from './OnchangeInputQuantity';
import {onkeydownInputQuantity} from "./OnkeydownInputQuantity";
import {PAGE_LABELS as label} from "../constants/Labels";
function SimilarProducts({ labels, skus, title, productDetail, onClickViewAll, addToCart }) {
  const complianceDetail = productDetail?.compliances;
  const productEda = productDetail?.productEda;
  const [show, setShow] = useState(false);
  const showComplianceDetails = () => {
    setShow(true);
    document.body.className += 'modal-open';
  };
  const handleClose = () => {
    setShow(false);
    document.body.className = '';
  }

  return (
    <div className="card ecom-card mt-4 ie-float-left ie-width-full">
      { show && <ComplianceDetail compliances={complianceDetail} handleClose={handleClose} show={ show }/> }
      <div className="card-header bg-gainsboro-grey text-bokara-grey  ecom-card-radius-none">{ title } ({skus.length})</div>
      <div className="card-body">
        { productDetail?.isExpress === 'Y' &&
          <div className="row">
            <div className="col-xs-12 ml-2 mb-2">
              <img src={imgSrc("/static/images/express-logo-small.png")} className="" alt="..." />
            </div>
          </div>
        }
        <div className="row">
          <div className="col-xs-12 col-sm-5 col-md-5">
            <div className="border d-flex mr-0 p-2 feco-thumbnail-size-100">
              <Link
                  to={setJessionId(`/product/details/${productDetail?.sku}`)} onClick = {() => {sendPDAnalyticsData(productDetail, "select_item",title)}}>
                <img src={imgSrc(productDetail?.thumbnail)} className="rounded card-img" alt="..."/>
              </Link>
            </div>
            <div className="ml-auto d-flex m-5 ie-feco-mr-0">
              <input type="number" min="0" max="99999" aria-label={label?.quantity} className="form-control p-1 ecom-input-sm mr-2 ie-float-left" defaultValue="1" id={`selected_quantity_${productDetail?.sku}`} onChange={(e) => { onChangeInputQuantity(e) }} onKeyDown={(e) => { onkeydownInputQuantity(e) }}/>
              <div className="btn-group">
                <button type="button" className="btn btn-primary ecom-button ecom-button-highlight" aria-label={label?.addToCart} onClick={(e) => { AddToShoppingCart(e, productDetail, {addToCart, labels}, title) }}><i className="fa fa-shopping-cart" aria-hidden="true"></i></button>
                <AddToCartTemplate sku={productDetail?.sku} />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-7 col-md-7">
            <div className="ecom-card-text">
              <div className="ecom-card-text-line">
                <span className="font-weight-bold">
                  {productDetail?.customerSku !== undefined ? 'Your Sku':'SKU'}{": "}
                </span>
                <Link className="card-title font-weight-bold"
                      to={setJessionId(`/product/details/${productDetail?.sku}`)} onClick = {() => {sendPDAnalyticsData(productDetail, "select_item", title)}}>
                  {productDetail?.customerSku !== undefined ? `${productDetail?.customerSku} (${productDetail?.sku})` : productDetail?.sku}
                </Link>
              </div>
              <span dangerouslySetInnerHTML={{ __html: productDetail?.description}}></span>
              {productDetail?.manufacturer &&
                <div className="ecom-card-text-line mt-2">
                <div className="font-weight-bold">{labels?.manufacturer}</div>
                  <div className="font-weight-normal"  dangerouslySetInnerHTML={{__html: productDetail?.manufacturerLabel}}></div>
                </div>
              }
              {
                productDetail?.brandName &&
                <div className="ecom-card-text-line mt-2">
                  <div className="font-weight-bold">{labels?.brand}</div>
                  <div className="font-weight-normal"><span dangerouslySetInnerHTML={{__html: productDetail?.brandLabel}}></span></div>
                </div>
              }
              <div className="ecom-card-icon-list-group mt-2">
                <ul className="nav">
                  {
                    productDetail?.compliances.map((compliance, index) => {
                      if((compliance.complianceName === "Restricted Product" && (compliance["Restriction Areas"] !== undefined)) || (compliance.complianceName !== 'Restricted Product')) return (
                        <li className= {`ie-float-left ${index > 0 ? "ml-1":''}`} key={index} onClick={(e) => { showComplianceDetails(productDetail?.compliances) }}>
                          <img src={imgSrc(compliance.Image)} title={compliance["complianceNameLabel"]} alt={compliance["Alt Text"]} className={compliance.Image ? "" : "img-20x20"} className="img-20x20"/>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
              <div className="mt-2 ie-float-left ie-width-full">
                {productDetail?.priceDisplayData?.length > 0 ?
                  productDetail?.priceDisplayData.map((price, index) => {
                    return (
                      <div key={index}>
                        <div className={`text-${price.label === "Wholesale:" ? "black" : (
                            price.label === "Unit Price:" && index - 1 >= 0 && productDetail?.priceDisplayData[index - 1].label === "Wholesale:" ? "black" : "primary"
                        )} ${index > 0 ? "mt-1" : ""}`}>
                          <span className="font-weight-600">{ price.label }</span><br />
                          <span className="font-weight-bold">{ price.price } / {price.uom}</span>
                        </div>
                      </div>
                    )
                  })
                  :
                  <span><span className="font-weight-bold">{labels?.price}: </span>{labels?.noPriceAvailable}</span>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <ProductAvailability productEda={productEda} />
          </div>
          <div className="col-sm">
            {(skus && skus.length > 1) && <a  href="#" className="btn float-right text-primary" data-toggle="modal" data-target={`#${title.replace(" ", "-")}ProductsModal`} onClick={(e) => {onClickViewAll(true, title)}}>
              {labels?.viewAll} <i className="fa fa-angle-double-right"></i>
            </a>}
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (shoppingCartSummary) => { dispatch(addToCart(shoppingCartSummary)) },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SimilarProducts);