import { connect } from "react-redux";
function NoResult(props) {
  const label = props.labels;
  return(
    <div className="feco-container-fix feco-container-inner mt-5">
      <div className="text-center mt-5">
        <b style={{fontSize: "40px"}}>{label?.somethingWentWrong}</b>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};

export default connect(mapStateToProps, null)(NoResult);