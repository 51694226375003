import React from "react";

function Spinner() {
  return (
    <div className="feco-container-fix">
      <div className="d-flex justify-content-center m-5 p-5">
        <div className="m-5 p-5">
          <span className="spinner-border text-primary m-5"></span>
        </div>
      </div>
    </div>
  )
}

export default Spinner;