import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ComplianceDetail from './ComplianceDetail';
import {connect} from "react-redux";
import AddToCartTemplate from './AddToCartTemplate';
import { imgSrc, setJessionId } from '../helpers/CommonScripts';
import ProductAvailability from './ProductAvailability';
import CustomCatalog from "./CustomCatalog";
import { onChangeInputQuantity } from './OnchangeInputQuantity';
import {onkeydownInputQuantity} from "./OnkeydownInputQuantity";
import { FiRepeat } from "react-icons/fi";
function ListView(props) {
  const label = props.labels;
  const productList = props.productList;
  const [complianceDetail, setComplianceDetail] = useState([]);
  const [show, setShow] = useState(false);
  const compareProducts = props.compareProducts;
  const resultTypeGA = props.resultTypeGA;
  const comparelist_skus =  compareProducts && compareProducts.map(function (obj) {
    return obj.sku
  });

  const showComplianceDetails = (compliances) => {
    setComplianceDetail(compliances);
    setShow(true);
    document.body.className += 'modal-open';
  };
  const handleClose = () => setShow(false);

  return(
      <div className="row ie-width-full feco-mx-sm-0" id="ecom-product-list-view">
        <input type="hidden" name="pageVal" value="Product List View" />
        { show && <ComplianceDetail compliances={complianceDetail} handleClose={handleClose} show={ show }/>}
        <div className="col p-0">
          <table className="table table-sm feco-product-list-view-table">
            <tbody>
            {productList && productList.map((product) => {
              const productPrice = product.priceDisplayData;
              const uom = product.uom;
              return (
                  <tr key={product.sku}>
                    <td>
                      <div className="card ecom-card-sm ecom-border-0 feco-col-product-item">
                        { product.isExpress !== undefined && product.isExpress === 'Y' &&
                          <div className="row">
                            <div className="col-xs-12">
                              <img src={imgSrc("/static/images/express-logo-small.png")} className="ml-3" alt="..." style={{width: "75px"}}/>
                            </div>
                          </div>
                        }
                        <div className="row no-gutters">
                          <div className="flex-shrink-0 ie-float-left">
                          <div className="border p-2 feco-thumbnail-sm fixed-height d-flex position-relative">
                              <Link to={setJessionId(`/product/details/${ product.sku }`)} className="ie-thumbnail">

                                <img src={imgSrc(product.thumbnail)} className="rounded card-img" alt="..."/>
                                { product.hasOwnProperty("previouslyPurchased") &&
                                  <div className="position-absolute l-0 b-0 d-inline-flex mt-5">
                                    <FiRepeat className=" p-1 border rounded-circle border-orange text-orange bg-white" style={{zIndex: 5}} size="1.65em"/>
                                    <div className="b-2 bg-orange text-white ml-n2 pl-2 pr-1" style={{zIndex: 4, marginTop: '0.1rem', fontSize:'12px'}}>{label?.reOrder}</div>
                                  </div>
                                }
                              </Link>

                            </div>
                            <div className="form-check mt-1 mb-2">
                              <input className="form-check-input mt-1" type="checkbox" value="" defaultChecked={comparelist_skus.includes(product.sku) ? "checked":""} id={product.sku} onClick={(e) => {props.onClickAddToCompare(e, product.sku, imgSrc(product.thumbnail))} }/>
                              <label className="form-check-label" htmlFor={product.sku}>
                                Compare
                              </label>
                            </div>
                          </div>
                          <div className="col-md-8 pl-2 ie-float-left feco-pl-sm-0">
                            <div className="card-body">
                              <div className="card-title font-weight-bold">{product.customerSku ? (label?.yourSku) : (label?.fastenalPartNo)}:
                                <Link className="default-nav-link font-weight-normal" to={setJessionId(`/product/details/${ product.sku }`)}> { product.customerSku !== undefined ? `${product.customerSku}(${product.sku})`: product.sku}</Link>
                              </div>
                              <p className="card-text"><span dangerouslySetInnerHTML={{ __html: product.description}}></span></p>
                              {
                                product.manufacturer &&
                                <p className="card-text" dangerouslySetInnerHTML={{ __html: product.manufacturerLabel}}></p>
                              }
                              {  product.brandName &&
                                <p className="card-text"><span dangerouslySetInnerHTML={{__html: product.brandLabel}}></span></p>
                              }
                            </div>
                            <div className="ecom-card-icon-list-group ie-card-icon-list-group">
                              <ul className="nav">
                                {
                                  product.compliances && product.compliances.map((compliance, i) => {
                                    if((compliance.complianceName === "Restricted Product" && (compliance["Restriction Areas"] !== undefined)) || (compliance.complianceName !== 'Restricted Product')) return (
                                      <li className="nav-item cursor-pointer feco-border-sm" key={i} onClick={(e) => {showComplianceDetails(product.compliances)} }>
                                        <img src={imgSrc(compliance.Image)} title={compliance["complianceNameLabel"]} alt={compliance["Alt Text"]} className={compliance.Image ? "" : "img-20x20"} className="img-20x20"/>
                                      </li>
                                    )
                                  })
                                }
                              </ul>
                              <CustomCatalog productDetail ={product} displayText={true}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{ uom.message }</td>
                    <td>
                      {productPrice && productPrice.length > 0 ?
                        productPrice.map((price, index) => {
                          return (
                              <div key={index}>
                                <div className={`text-${price.label === "Wholesale:" ? "black" :(
                                    price.label === "Unit Price:" && index - 1 >= 0 && productPrice[index - 1].label === "Wholesale:" ? "black" : "primary"
                                )} ${index > 0 ? "mt-1" : ""}`}>
                                  <span className="font-weight-600">{ price.label } </span><br />
                                  <span className="font-weight-bold">{price.price} {price.uom ? "/" : ""} { price.uom}</span>
                                </div>
                              </div>
                          )
                        })
                        :
                        <span><span className="font-weight-bold">{label?.price}: </span>{label?.noPriceAvailable}</span>
                      }
                    </td>
                    <td>
                      {(product && product.productEda !== undefined) ? <ProductAvailability productEda={product.productEda} /> : (label?.Loading)}
                    </td>
                    <td>
                      <input type="number" min="0" max="99999" aria-label={label?.quantity} className="form-control feco-prdvw-input-w-sm p-1 ecom-input-sm" id={`selected_quantity_${product.sku}`} defaultValue="1" onChange={(e) => { onChangeInputQuantity(e) }} onKeyDown={(e) => { onkeydownInputQuantity(e) }} />
                    </td>
                    <td>
                      <div className="text-nowrap btn-group ie-button-left">
                        <button type="button" className="btn btn-primary ml-4 ie-float-right feco-ml-sm-0" onClick={(e) => { props.onClickAddToCart(e, product, props, resultTypeGA === "categoryList" ? "Search Results" : "List View") }}>
                          <i className="fa fa-shopping-cart" aria-hidden="true"></i> {label?.addToCart}
                        </button>
                        <AddToCartTemplate sku={product.sku}/>
                      </div>
                    </td>
                    <input type="hidden" name="data-sku" value={product?.sku}/>
                  </tr>
              )
            })}
            </tbody>
          </table>
        </div>
      </div>
  )
}


const mapStateToProps = (state) => {
  return {
    compareProducts: state.compareReducer.compareItems,
    labels: state.commonReducer.pageLabels
  }
}

export default connect( mapStateToProps, null )(ListView);
