import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from "react-bootstrap/Modal";
import { imgSrc } from '../helpers/CommonScripts';
import Spinner from './PopupSpinner';
import VendingMachineView from './VendingMachineView';
import VendingMachineListView from './VendingMachineListView';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

function VendingMachine(props) {
    const label = props.labels;
    const vendingDetails = props.vendingMachineDetails;
    const sku = props.sku;
    const [showListView, setShowListView] = useState("");

    useEffect(() => {
        setShowListView(vendingDetails.displayOnlyListView);
    }, [props])

    if (showListView === undefined) return <Spinner />

    const changeViewPreference = () => {
        setShowListView(!showListView)
    }
    const popoverVendingImg = (
        <Popover style={{ maxWidth: 400}}>
            <Popover.Content className="border">
                <img src={imgSrc("/static/images/vending-machine-info.jpg")} alt="..."/>
            </Popover.Content>
        </Popover>
    );
    return(
        <Modal show={props.show} animation={true} size="xl" onHide={props.handleClose} >
            <Modal.Header className="feco-modal-header-primary p-2"  closeButton>
                <Modal.Title as="h6">
                    {`${vendingDetails.addressName} - ${vendingDetails.locationName} - ${vendingDetails.deviceName}`}
                    <OverlayTrigger placement="bottom-end" delay={{ show: 250, hide: 500 }} overlay={popoverVendingImg}>
                        <span className="fa fa-question-circle pl-2 text-white" ></span>
                    </OverlayTrigger>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-width">
                <div className="row">
                    <div className="col-10">
                        <h5 className="text-center">{showListView ? `label?.list` : `${label?.machine} ${label?.view}`}</h5>
                    </div>
                    { !showListView &&
                        <div className="col-2">
                            <button className={`btn btn-sm focus ${showListView ? 'btn-default' : 'btn-primary text-white'}`} onClick={() => { changeViewPreference()}}>
                                <img src={imgSrc("/static/images/vending-black.png")} className="machine-icon text-white" alt="..." /> <span> { label?.machine}</span>
                            </button>
                            <button className={`ml-1 btn btn-sm ${showListView ? 'btn-primary text-white' : 'btn-default'}`} onClick={() => { changeViewPreference() }}><span className="fas fa-list"></span> {label?.list}</button>
                        </div>
                    }
                </div>
                { showListView ?
                    <VendingMachineListView vendingDetails={vendingDetails} />
                    : <VendingMachineView vendingDetails={vendingDetails} sku={sku}/>
                }
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};

export default connect( mapStateToProps, null )(VendingMachine);