import React, { useState } from "react";
import { connect } from 'react-redux';
import {getResponse} from "../apiCalls/ApiCall";
import {ACCEPT_COOKIE, BLOCK_COOKIE} from "../constants/Constants";

function Cookies(props) {
  const label = props.labels;
  const  data = props.cookieAgreement;
  const [showCookiePopup, setShowCookiePopup] = useState(true);

  const acceptCookie = () =>{
    getResponse(ACCEPT_COOKIE+"?cookieAgreementValue=1", {}).then((res) => {
      setShowCookiePopup(false);
    })
  };

  const blockCookie = () =>{
    getResponse(BLOCK_COOKIE+"?cookieAgreementValue=0", {}).then((res) => {
      setShowCookiePopup(false);
      window.location.href = data.cookieInformationUrl;
    });
  };

  if (!showCookiePopup) return '';

  return (
    <div className="col bg-default-gray position-fixed fixed-bottom feco-cookie-msg" tabIndex="1">
      <div className="feco-container-fix feco-container-inner pt-3 p-0">
      <div className="row">
          <h6 className="pl-3 w-100 font-weight-bolder">{label?.cookieTitle}</h6>
        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9 pr-3 pr-sm-0 feco-cookie-msg-left">
          <p>{label?.cookiePara}</p>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 text-right feco-cookie-msg-right">
            <button className="btn btn-primary p-1" onClick={(e) => { acceptCookie() }}>{label?.acceptContinue}</button>
          <a className="ml-3 cursor-pointer btn-text" href="#" onClick={(e) => { blockCookie()}}>
            {label?.blockCookieMsg}
          </a>
        </div>
      </div>
    </div>
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};

export default connect( mapStateToProps, null )(Cookies);