import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getUriWithParam } from "../helpers/Helpers";
import { connect } from 'react-redux';
import { setJessionId } from '../helpers/CommonScripts';

const Language = (props) => {
    const activeNavLink = props.activeNavLink;
    const isMobileView = props.isMobileView;
    const label = props.labels;
    const [locales, setLocales] = useState([]);
    const location = useLocation();

    useEffect(() => {
        setLocales(props.locales)
    }, [location, props])

    return(
        <>
            {(locales && isMobileView) &&
                <li key="language-dropdown" className={`nav-item position-relative feco-language-flyout-menu ${activeNavLink && (activeNavLink === "languageMenu" ? 'active-hdr-icon' : 'inactive-hdr-icon')}`} onClick={props.preventClickForMobile}>
                    <a className="nav-link d-flex pl-2 flex-column flex-lg-row" href="#" data-name="languageMenu">
                        <div className="fas fa-globe text-center fa-2x pt-2"></div>
                        <div className="d-block d-lg-none text-center text-lg-left pl-1 pr-1 nav-link-mobile">
                            {locales && locales[0]?.countryCode} - {locales && locales[0]?.languageCode}
                        </div>
                    </a>
                    <div className="feco-header-dropdown feco-language-dropdown ie-header-dropdown">
                        <ul className="list-unstyled language-menu">
                            {locales && locales.map((locale, index) => {
                                return (
                                    <li key={locale.displayName} className="nav-item pb-1">
                                        {index === 0 ? locale.displayName :
                                            <a href={setJessionId('/fc' + `${locale.languageMapping ? locale.languageMapping : ''}/product${getUriWithParam(window.location, { locale: `${locale.languageCode}_${locale.countryCode}` })}`)}>
                                                {locale.displayName}
                                            </a>
                                        }
                                    </li>
                                )
                            })
                            }
                            <li className="nav-item mt-1 font-weight-bold">
                                <a href={`/punchout/location`} className="lang-menu-list-link" data-ol-has-click-handler="">{label?.selctAnotherLocation}</a>
                            </li>
                        </ul>
                    </div>
                </li>
            }
            {(locales && !isMobileView) &&
                <li className={"nav-item dropdown feco-lang-dd ml-5"}>
                    <a className="nav-link dropdown-toggle ie-float-right" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-globe  mr-1"></i>
                        {locales && locales[0]?.displayName}
                        <i className="fas justify-content-end float-right fa-chevron-down pt-1 pl-1"></i>
                    </a>
                    <div className="dropdown-menu feco-lang-dd-menu p-2" aria-labelledby="navbarDropdown" style={{ width: "max-content" }}>
                        <i className="fa fa-caret-up feco-lang-dd-arrow-dir" aria-hidden="true"></i>
                        <ul className="list-unstyled">
                            {locales && locales.map((locale, index) => {
                                return (
                                     <li className="nav-item pb-1" key={locale.displayName}>
                                        {index === 0 ? locale.displayName :
                                            <a href={setJessionId('/fc' + `${locale.languageMapping ? locale.languageMapping : ''}/product${getUriWithParam(window.location, { locale: `${locale.languageCode}_${locale.countryCode}` })}`)}>
                                                {locale.displayName}
                                            </a>
                                        }
                                    </li>
                                )
                            })
                            }
                            <li className="nav-item mt-1 font-weight-bold">
                            <a href={`/punchout/location`} className="lang-menu-list-link" data-ol-has-click-handler="">{label?.selctAnotherLocation}</a>
                            </li>
                        </ul>
                    </div>
                </li>
            }
        </>
    )
}

const mapStateToProps = (state) => {
  return {
    isMobileView: state.commonReducer.isMobileView,
    labels: state.commonReducer.pageLabels
  }
};

export default connect( mapStateToProps, null )(Language);