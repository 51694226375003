import { ADD_TO_COMPARE, REMOVE_PRODUCT_COMPARE, REMOVE_ALL_PRODUCTS_FROM_COMPARE } from './action-types/compare-actions';

// Select Product To Compare
export const addToCompare = (sku, image) => {
    return {
        type: ADD_TO_COMPARE,
        image: image,
        sku
    }
}

//Remove Product from compare List
export const removeProductCompare = (sku) => {
    return {
        type: REMOVE_PRODUCT_COMPARE,
        sku
    }
};

//Remove All Products from compare List
export const removeAllProductsFromCompare = (skus) => {
    return {
        type: REMOVE_ALL_PRODUCTS_FROM_COMPARE,
        skus
    }
};

