import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setJessionId } from '../helpers/CommonScripts';
import DidYouMean from "./DidYouMean";
import NonStaticRedirects from "./NonStaticRedirects";

function NoResult(props) {
  const label = props.labels;
  const searchRequest = props.responseData.searchRequest;
  const categoryMenus = props.categoryMenu;
  const nonStaticRedirects = props.nonStaticRedirectsData;
  const languageMapping = props.responseData?.languageMapping;

  return (
    <div className="row flex-nowrap flex-column flex-lg-row ie-width-full">
      <div className="flex-column col-12 ie-float-left ie-width-full">
        {
          nonStaticRedirects.length > 0 ?
            <div className="ml-3">
              <NonStaticRedirects nonStaticRedirectsData={nonStaticRedirects} />
            </div>
          :
            props.responseData.notCountrySpecificItem ?
              <>
                <div className="feco-noresult-keyfor-search mb-2"><b>
                  {label?.notAvailableMessage}{languageMapping}</b>
                  <div className="card-body px-0">
                      <a href={setJessionId(`/fc/product?query=${searchRequest?.query}&fsi=1&locale=${props.responseData?.locale}`)}>
                        {label?.accessWebsite}
                      </a>
                      {label?.contactLocalBranch}
                  </div>
                </div>
                <div className="card border-0 mt-3 ie-float-left ie-width-full">
                  <h6 className="card-header bg-primary text-white font-weight-bolder rounded-0">
                    {label?.contactYourLocalBranch}
                  </h6>
                  <div className="card-body px-0">
                    <p className="card-text">{label?.BranchIsReadyToHelpMessage}
                      <a className="ml-1" href="/locations">{label?.contactYourLocalBranch}</a>
                      {label?.gainAccessToFastenalAccessories}
                    </p>
                    <p className="card-text">{label?.enquiryEmailMessage}
                      <a href="mailto:webhelp@fastenal.com">webhelp@fastenal.com</a>.</p>
                  </div>
                </div>
              </>
            :
          <>
            <div className="feco-noresult-keyfor-search mb-2">{label?.YourSearch}
              <span className="feco-noresult-keyvalue font-weight-bolder">
                {searchRequest && searchRequest.query ? `${searchRequest.query}` : searchRequest && searchRequest.searchTerms && searchRequest.searchTerms.length > 0 ? searchRequest.searchTerms.join(",") : ""}
              </span> {label?.hasNoMatchingResults}
            </div>

            {props.didYouMean && props.didYouMean.length > 0 ? <DidYouMean didYouMean={props.didYouMean} />
              :
              <div className="feco-noresult-msg-info">
                {label?.noResultsMessage}
              </div>
            }
            <div className="card mt-3 border-0">
              <h6 className="card-header bg-primary text-white rounded-0 font-weight-bolder">
                {label?.browseOurCategories}
              </h6>
              <div className="card-body">
                <ul className="nav">
                  {
                    categoryMenus && categoryMenus.map((category) => {
                      return (
                        <li className="nav-item col-xs-12 col-sm-4 col-md-3 px-0">
                          <Link className="nav-link active" to={setJessionId(`/product/${category.categoryLevelOne}?fsi=1${category.categoryId !== undefined ? '&categoryId=' + category.categoryId : ""}`)}>
                            <span className="">-</span> {category.categoryLabelOne}
                          </Link>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
            <div className="card border-0 mt-3 ie-float-left ie-width-full">
              <h6 className="card-header bg-primary text-white font-weight-bolder rounded-0">
                {label?.contactYourLocalBranch}
              </h6>
              <div className="card-body px-0">
                <p className="card-text">{label?.BranchIsReadyToHelpMessage}
                  <a className="ml-1" href="/locations">{label?.contactYourLocalBranch}</a>
                  {label?.gainAccessToFastenalAccessories}
                </p>
                <p className="card-text">{label?.enquiryEmailMessage}
                  <a href="mailto:webhelp@fastenal.com">webhelp@fastenal.com</a>.</p>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
	return {
    labels: state.commonReducer.pageLabels,
    categoryMenu: state.commonReducer.categoryMenuList
	}
};
export default connect( mapStateToProps, null )(NoResult);