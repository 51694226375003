import React, {useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { slice, sortBy, omit} from 'lodash';
import { imgSrc, setJessionId, sendViewPromotionAnalytics,sendCategoryViewAnalytics, sendCategoryClickAnalytics } from '../helpers/CommonScripts';
import {constructBasicURLParam, appendQuerySeparator} from '../helpers/Helpers'

function CategoryList(props) {
  const label = props.labels;
  const responseData = props.responseData;
  const promotions = props.promotions;
  const searchRequest = responseData.searchRequest;
  const baseUrl = window.location.pathname;
  const categoryList = (Object.keys(omit(searchRequest, "attributeFilters", 'sortBy', 'pageUrl', 'includeSuggestedFilters')).length === 0 && Object.keys(searchRequest.attributeFilters).length === 0) ?
    sortBy(responseData.categoryList, ['name']) : responseData.categoryList;
  const LENGTH = categoryList && categoryList.length;
  const [list, setList] = useState([]);
  const history = useHistory();
  const searchQuery = history.location.search;
  const isSearch = props.isSearch;

  const viewMoreToggle = () => {
    let showAll = list.length < LENGTH;
    const newList =  showAll ? categoryList : slice(categoryList, 0, 5);
    setList(newList);
    sendCategoryViewAnalytics(newList,isSearch?"Search Results":"Category Page");
  }

  useEffect(()=>{
    setList(slice(categoryList, 0, responseData.productList ? 5 : LENGTH))
  },[props]);

  const handleUrl = (catLevel, familyId, categoryId) => {
    const searchParams = new URLSearchParams(searchQuery);
    const searchQueryParams = queryString.parse(searchQuery);
    searchParams.delete("page");
    if (searchQueryParams && searchQueryParams.searchTerms && searchQueryParams.searchTerms.trim().length < 2) {
      searchParams.delete("searchTerms");
    }
    if (searchQueryParams && searchQueryParams.query && searchQueryParams.query.trim().length < 2) {
      searchParams.delete("query");
    }
    searchParams.delete("pageSize");
    searchParams.delete("fsi");
    searchParams.delete("categoryId");

    var categoryUrl = (searchParams.toString() !== '') ? `?${searchParams.toString()}` : `${searchParams.toString()}`;
    categoryUrl = (catLevel === "one") ? (categoryUrl.includes("?") ? `${categoryUrl}&fsi=1` : `?fsi=1`) : categoryUrl
    {!categoryUrl.includes("productFamilyId") && (categoryUrl = familyId ? (categoryUrl.includes("?") ? `${categoryUrl}&productFamilyId=${familyId}` : `?productFamilyId=${familyId}`) : categoryUrl)}
    {!categoryUrl.includes("categoryId") && (categoryUrl = categoryId ? (categoryUrl.includes("?") ? `${categoryUrl}&categoryId=${categoryId}` : `?categoryId=${categoryId}`) : categoryUrl)}

    return categoryUrl
  };

  const categoryTitle = (category) => {
    return(
      category.familyId ? (category.categoryLabelFour || category.categoryLabelThree) : category.categoryLevelFour ? category.categoryLabelThree : category.categoryLevelThree ? category.categoryLabelTwo : category.categoryLevelTwo ? category.categoryLabelOne : ""
    )
  }

  useEffect(() => {
    if (promotions && promotions.GAResponse && promotions.GAResponse.length > 0 && !(responseData.productList)) {
      JSON.parse(promotions.GAResponse).map((promo) => {
          sendViewPromotionAnalytics(promo, responseData, []);
        });
    }
  }, [list]);

useEffect(()=>{
  if(categoryList && categoryList.length>0){
    let newList =  slice(categoryList, 0, responseData.productList ? 5 : LENGTH);
    sendCategoryViewAnalytics(newList,isSearch?"Search Results":"Category Page");
  }
},[responseData])

  const encodeCategoryName = (categoryName) => {
    return ((encodeURIComponent(categoryName)))
  }

  return (
    <>
      <input type="hidden" name="pageVal" value="Category List" />
      {!props.responseData.productList && props.responseData.categoryList[0].familyId &&
      <strong className="row justify-content-end ie-width-full">
        <Link
          to={`${baseUrl.replace(window.baseUrl, '')}${appendQuerySeparator(searchRequest && constructBasicURLParam(searchRequest))}productListOnly=true${searchRequest?.categoryId ? "&categoryId="+searchRequest.categoryId : ""}`}>
          {label?.showAll} {responseData.paging.totalCount} {label?.results}
        </Link>
      </strong>
      }
      <div className="row  mt-2 mb-4 ie-width-full ie-float-left feco-row-cols-xs-1 feco-row-cols-lg-5 feco-row-cols-md-4 feco-row-cols-sm-3 px-md-3 px-sm-3 px-lg-0 px-xl-0">
        {list && list.map((category, i) => {
          const titleName = (searchQuery.includes("query") || searchQuery.includes("searchTerm")) && categoryTitle(category);
          return (
            <div className="col-xs-12 col-sm-3 col-md-3 card rounded-0 feco-card ie-card-category p-2" key={i}>
              <Link to={
                setJessionId((category.categoryLevelFour) ? `/product/${encodeCategoryName(category.categoryLevelOne)}/${encodeCategoryName(category.categoryLevelTwo)}/${encodeCategoryName(category.categoryLevelThree)}/${encodeCategoryName(category.categoryLevelFour)}${handleUrl("four", category.familyId, category.categoryId)}` : (category.categoryLevelThree) ?
                    `/product/${encodeCategoryName(category.categoryLevelOne)}/${encodeCategoryName(category.categoryLevelTwo)}/${encodeCategoryName(category.categoryLevelThree)}${handleUrl("three", category.familyId, category.categoryId)}` : (category.categoryLevelTwo) ?
                        `/product/${encodeCategoryName(category.categoryLevelOne)}/${encodeCategoryName(category.categoryLevelTwo)}${handleUrl("two", category.familyId, category.categoryId)}` : `/product/${encodeCategoryName(category.categoryLevelOne)}${handleUrl("one", category.familyId, category.categoryId)}`
                )} onClick = {() => sendCategoryClickAnalytics(category, i+1 ,isSearch?"Search Results":"Category Page")} className="default-nav-link h-100">
                <div className="feco-thumbnail-size-80 m-auto"><img src={imgSrc(category.image)} className="card-img-top" alt={category.name} title={titleName ? titleName : ""}/></div>
                <div className="card-body text-center" dangerouslySetInnerHTML={{ __html: category.name}}></div>
              </Link>
            </div>
          )
        })}
      </div>
      <div className="text-center">
        {LENGTH > 5 && responseData.productList &&
         <button className="btn btn-primary" onClick={viewMoreToggle}> {list.length < LENGTH ? label?.viewMore : label?.viewLess}</button>
        }
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};

export default connect( mapStateToProps, null )(CategoryList);