import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { detectWindowSize } from '../src/actions/commonActions';
import Routes from './components/Routes';

function App(props) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.screen.width <= 992 ? setIsMobile(true) : setIsMobile(false);
  }, [window.screen.width]);

  function detectWindowSize() {
    window.innerWidth <= 992 ? setIsMobile(true) : setIsMobile(false);
  }
  window.onresize = detectWindowSize;
  props.detectWindowSize(isMobile)
  return (
    <Routes isMobile={isMobile}/>
  );
} 

const mapDispatchToProps = (dispatch) => {
  return {
    detectWindowSize: (isMobileView) => { dispatch(detectWindowSize(isMobileView)) },
  }
};

export default connect(null, mapDispatchToProps)(App);