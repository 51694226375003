import {postRequest} from "../apiCalls/ApiCall";
import {CUSTOM_UI_CONTENT} from "../constants/Constants";

export const getCMSContent = async ( cache) =>{
    //removing unnecessary fastconnect data from url to match crafter url
    let pageUrl = window.location.pathname;
    pageUrl = pageUrl + window.location.search;
    pageUrl = pageUrl.replace("/fc/fr", "");
    pageUrl = pageUrl.replace("/fc", "");
    if( sessionStorage != null && sessionStorage.hasOwnProperty("headerInformationData")) {
        pageUrl = pageUrl.replace("locale="+JSON.parse(sessionStorage.headerInformationData).locale+"&", "");
    }
    if(sessionStorage != null && sessionStorage.hasOwnProperty("jsessionid")) {
        pageUrl = pageUrl.replace(";" + sessionStorage.jsessionid, "");
    }
    pageUrl = pageUrl.replace("allowGoogleRemarketing=false&", "");
    pageUrl = pageUrl.replace("allowGoogleRemarketing=true&", "");

    let bodyContent = Object.assign({}, {"pageUrl": pageUrl });

    const cmsContent = cache.current[pageUrl+"-CMS-content"] ?? postRequest(CUSTOM_UI_CONTENT, bodyContent).then((res) => {
        if(res){
            cache.current[pageUrl+"-CMS-content"] = res;
        }
        return res;
    });
    return cmsContent;
}
export const AddCustomContent = (customContent, contentType) => {
    if(contentType === "CSS" && customContent != null){
        const dynamicExperienceClass = "dynamicExperience";
        customContent.map((css, index) => {
            const content = css.split("~~##~~");
            const id = "css"+index;
            if(!document.getElementById(id)) {
                const customCSS = document.createElement("link");
                customCSS.type = "text/css";
                customCSS.rel = "stylesheet";
                customCSS.href = content[0];
                customCSS.integrity = content[1];
                customCSS.crossOrigin = "anonymous"
                customCSS.classList.add(dynamicExperienceClass);
                const head = document.querySelector("head");
                head.appendChild(customCSS);
            }
        })
    }
    if(contentType === "JS" && customContent != null){
        const dynamicExperienceClass = "dynamicExperience";
        customContent.map((js, index) => {
            const content = js.split("~~##~~");
            const id = "JS-"+index;
            if(!document.getElementById(id)){
                const customJS = document.createElement("script");
                customJS.type = "text/javascript";
                customJS.src = content[0];
                customJS.integrity = content[1];
                customJS.crossOrigin = "anonymous";
                customJS.classList.add(dynamicExperienceClass);
                const head = document.querySelector("head");
                head.appendChild(customJS);
            }
        })
    }
    if(contentType === "IMG" && customContent != null){
        const dynamicExperienceClass = "dynamicExperience";
        customContent.map((ImgURL, index) => {
                //content[2] = Where to place, content[1] defines Position
                const content = ImgURL.split("~~##~~");
                const divId = `${content[2]}-${content[1]}-${index} `
                const div = document.createElement("div");
                let element = document.querySelector(content[2]);
                if (!document.getElementById(divId) && element) {
                    let para = document.createElement("p");
                    let img = document.createElement("img");
                    img.src = content[0];
                    para.appendChild(img);
                    div.appendChild(para);
                    div.classList.add(dynamicExperienceClass);
                    if (content[1] === "above") {
                        element.parentNode.insertBefore(div, element);
                    } else {
                        element.after(div);
                    }
                }
        })
    }
    if(contentType === "HTML" && customContent != null){
        const dynamicExperienceClass = "dynamicExperience";
        customContent.map((html, index) => {
                const div = document.createElement("div");
                const content = html.split("~~##~~");
                const divId = `${content[0]}-${content[1]}-${index}`;
                let element = document.querySelector(content[1]);
                if (element != null && !document.getElementById(divId)) {
                    div.innerHTML += content[2];
                    div.classList.add(dynamicExperienceClass);
                    if (content[0] === "above") {
                        element.parentNode.insertBefore(div, element);
                    } else {
                        element.after(div);
                    }
                }
        })
    }
}
export const AddCustomCMSDataDynamically = (customCMSContent) => {
    if (Object.keys(customCMSContent).length > 0) {
        AddCustomContent(customCMSContent?.jsContent, 'JS');
        AddCustomContent(customCMSContent?.cssContent, 'CSS');
        const intervalId = setInterval(function(){
            AddCustomContent(customCMSContent?.htmlContent, 'HTML');
            AddCustomContent(customCMSContent?.imageContent, 'IMG');
            clearInterval(intervalId);
        },5000);
    }
}