import React, {useEffect, useRef} from "react";
import {useRect} from "./useRect";
import useStickyHeader from "./UseStickyHeader";
import {connect} from "react-redux";

function SubSectionTable(props) {
	const label = props.labels;
	const thRef = useRef([]);
	const lastRowRef = useRef();
	const headRowRef = useRef();
	const{rectRef} = useRect();
	const {sectionRef, isSticky, minTopRef, bottomDiffRef, headerRef, fixedHeaderTop} = useStickyHeader();

	useEffect(() => {
		thRef.current = thRef.current.slice(0, props.columnHeaders.length + 3);
		minTopRef.current = props.calculatedTop;
	}, [props.columnHeaders, props.calculatedTop]);

	useEffect(() => {
		headerRef.current = rectRef.current;
		bottomDiffRef.current = lastRowRef.current.offsetHeight;
	}, [rectRef]);

	useEffect(() => {
		props.setBottomDiffRef(bottomDiffRef.current + headRowRef.current.offsetHeight + 16);
	}, [isSticky]);


	function createMarkup(msg) {
		return {__html: msg};
	}

	const htmlMsg = (msg) => {
		return <span dangerouslySetInnerHTML={createMarkup(msg)} />;
	};

	const renderHeader = () => (
		<thead ref={rectRef}>
		<tr ref={headRowRef}>
			{
				props.columnHeaders.map((headeName, index) => {
					return (
						<th scope="col" key={index} ref={el => thRef.current[index+3] = el}>{headeName}</th>
					)
				})
			}
			<th className="counterbook-width-sku" scope="col" ref={el => thRef.current[0] = el}>{label?.sku}</th>
			<th className="counterbook-width-uom" scope="col" ref={el => thRef.current[1] = el} className="text-center">{label?.uom}</th>
			<th className="counterbook-width-price" scope="col" ref={el => thRef.current[2] = el}>{ label?.price}</th>
		</tr>
		{thRef.current[0] && isSticky &&
		<tr className="frozen" style={{top : fixedHeaderTop ? fixedHeaderTop : ""}}>
			{
				props.columnHeaders.map((headeName, index) => {
					return (
						<th scope="col" key={index} style={{width: thRef.current[index + 3].offsetWidth}}>{headeName}</th>
					)
				})
			}
			<th className="counterbook-width-sku" scope="col" style={{width: thRef.current[0].offsetWidth}}>{label?.sku}</th>
			<th className="counterbook-width-uom" scope="col" style={{width: thRef.current[1].offsetWidth}} className="text-center">{label?.uom}</th>
			<th className="counterbook-width-price" scope="col" style={{width: thRef.current[2].offsetWidth}}>{ label?.price}</th>
		</tr>
		}
		</thead>
	);

	return (
		<table className="table feco-table-modif counterbook-table" ref={sectionRef}>
			{renderHeader()}
			<tbody>
			{
				props.subsection.products && props.subsection.products.map((product, productIndex) => {
					const productPrice = (product.priceDisplayData && product.priceDisplayData[0]) || "";
					return (
						<tr className="cursor-pointer"  key={productIndex}
								ref={lastRowRef}
								onClick={(e) => {
									props.handleClickShowProductDetails(e, product.sku)
								}}>
							{
								props.columnHeaders.map((headerName, i) => {
									const atterVal = product.categoryAttributes.map(function (el) {
										if(el.name === headerName && el.value){
											return htmlMsg(el.name === headerName && el.value)
										}
									});

									return (
										<td key={i}>
											{atterVal}
										</td>
									)
								})
							}
							<td className="text-primary counterbook-width-sku" id={`sku_${product.sku}`}>
								<input type="hidden" name="data-sku" value={product?.sku}/>
								{product.sku} 
							</td>
							<td className="text-center counterbook-width-uom">{product.uom && product.uom.publicPackageUOM}</td>
							<td className={(productPrice ? productPrice.color !== "blue" && "text-black" : "text-red") + " counterbook-width-price"}>
								{productPrice ? productPrice.price : <span>{label?.noPriceAvailable}</span>}
							</td>
						</tr>
					)
				})
			}
			</tbody>
		</table>
	);
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
}

export default connect( mapStateToProps, null )(SubSectionTable);