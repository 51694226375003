import axios from "axios";
import { handleResponse, handleError } from './response';

axios.defaults.headers.Pragma = 'no-cache';
axios.defaults.withCredentials = true;
const jsessionId =  window.location.pathname.indexOf("jsessionid") > 0 ? window.location.pathname.split(";").slice(-1)[0] : window.sessionStorage.jsessionid;

export function postRequest(URL, bodyContent) {
  setJessionId();
  URL = constructURL(URL);
  return axios
    .post(URL, bodyContent)
    .then(handleResponse)
    .catch(handleError)
}

export function getResponse(URL, bodyContent) {
  delete bodyContent[0]
  setJessionId();
  if(isPunchoutURL(URL)) {
    URL = constructURL(URL);
  }

  return axios
    .get(URL, bodyContent)
    .then(handleResponse)
    .catch(handleError)
}

export function isPunchoutURL(URL) {
  return(URL && (URL.toLowerCase().includes(window.location.host.toLowerCase())
      || !URL.includes('://')))
}

function constructURL(URL) {
  URL = (jsessionId !== undefined && URL.toString().includes("?")) ? `${URL.split('?')[0]};${jsessionId}?${URL.split('?')[1]}` : (jsessionId !== undefined) ? `${window.baseUrl}${URL};${jsessionId}` : `${window.baseUrl}${URL}`;
  URL = (window.location.pathname.toString().includes("/fc") && !URL.toString().includes("/fc")) ? `/fc${URL}` : URL;
  return(URL)
}

function setJessionId() {
  window.setInterval(function() {
    const anchors = document.querySelectorAll('a, area');
    function hasClass(elem, cls) {
      return (' ' + elem.className + ' ').indexOf(' ' + cls + ' ') > -1;
    }

    jsessionId && anchors && anchors.forEach(anchor => {
      if (isPunchoutURL(anchor.href) && hasClass(anchor,"feco-jsessionid-none") === false) {
        if (anchor.href.indexOf("jsessionid") < 0) {
          anchor.pathname = `${window.baseUrl}${anchor.pathname};${jsessionId}`
          anchor.style.setProperty("pointer-events", "visible");
        }
      }
    });
  }, 1500);
}






