import { ADD_TO_CART_URL } from '../constants/Constants';
import { postRequest } from '../apiCalls/ApiCall';
import { toast } from 'react-toastify';


// ========== ONCLICK ADD TO CART ================
export const AddToShoppingCart = (event, product, props, resultType) => {
    const cartQuantity = event.target.parentElement.parentElement.parentElement.getElementsByTagName("input")[0];
    const selectedQty = (cartQuantity && cartQuantity.value) ? cartQuantity : event.target.parentElement.querySelector(`#selected_quantity_${product.sku}`);
    const quantity = parseInt(selectedQty ? selectedQty.value : document.getElementById(`selected_quantity_${product.sku}`).value);
    let ua = window.navigator.userAgent;
    let msie = ua.indexOf('MSIE ');

    if (isNaN(quantity) || quantity < 1) {
        errorMessage(msie, props.labels?.cartErrorMsgZeroQty)
    } else {
        postRequest(ADD_TO_CART_URL, {sku: product.sku, quantity: quantity}).then((res) => {
            if (res.shoppingCartSummary && res.shoppingCartSummary.message) {
                toast.success(toasterHtmlMsg(res.shoppingCartSummary && res.shoppingCartSummary.message), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClick: function (e) {
                        if (msie > 0) {
                            if (e.currentTarget.className.toString().indexOf('Toastify') === 0 || e.currentTarget.children[1].className.toString().indexOf('Toastify__close-button') === 0) {
                                e.currentTarget.remove();
                            }
                        }
                    }
                });
                props.addToCart(res.shoppingCartSummary);
                sendAddToCartAnalyticsData(product, quantity, resultType, res)
            } else {
                errorMessage(msie, props.labels?.cartErrorMsg)
            }
        });
    }
};

export const errorMessage = (msie, msg) => {
    toast.error(toasterHtmlMsg(msg), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick:function (e) {
            if (msie > 0) {
                if (e.currentTarget.className.toString().indexOf('Toastify') === 0 || e.currentTarget.children[1].className.toString().indexOf('Toastify__close-button') === 0) {
                    e.currentTarget.remove();
                }
            }
        }
    });
};

function createMarkup(msg) {
  return {__html: msg};
}

export const toasterHtmlMsg = (msg) => {
  return <span dangerouslySetInnerHTML={createMarkup(msg)} />;
};

//===========GA Data for Compare products================
export const sendCompareProductsAnalytics = (sku) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "compareProducts",
        productCount: (sku) ? sku.length : "",
        productSKUs: (sku) ? `${sku}` : ""
    });
}

//============View Item List" (Category views GA event)==========
export const sendCategoryViewAnalytics = (categoryList,page) => {
    window.dataLayer = window.dataLayer || [];
    let items = [];
    categoryList.map((category,index)=>{
        items.push({
            item_name: (category.name) ? `${category.name}` : ((category.categoryLevelOne) ? `${category.categoryLevelOne}` : ""), // Name or ID is required.
            item_id: (category.categoryId) ? `${category.categoryId}` : "", // Category ID
            price: "",
            item_brand: "",
            item_category: (category.categoryLevelOne) ? `${category.categoryLevelOne}` : "", // Category tree
            item_category2: (category.categoryLevelTwo) ? `${category.categoryLevelTwo}` : "",
            item_category3: (category.categoryLevelThree) ? `${category.categoryLevelThree}` : "",
            item_category4: (category.categoryLevelFour) ? `${category.categoryLevelFour}` : "",
            item_category5: (category.categoryLevelFive) ? `${category.categoryLevelFive}` : "",
            item_variant: "",
            item_list_name: page,
            item_list_id: "",
            index: index+1, // Category position
            quantity: 1,
        });
    })
    window.dataLayer.push({
        event: "view_item_list", ecommerce: {
            "items": items
        }
    });
}
//================GA Events for Promotion================
export const sendViewPromotionAnalytics = (promotions, data, productList) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ecommerce: null});
    window.dataLayer.push({
        event: "view_promotion",
        ecommerce: {
            creative_name: (promotions["Creative Name"]) ? promotions["Creative Name"] : "",
            creative_slot: (promotions["Creative Slot"]) ? promotions["Creative Slot"] : "",
            promotion_id: (promotions['Promotion ID']) ? promotions['Promotion ID'] : "", // Name or ID is required.
            promotion_name: (promotions['Promotion Name']) ? promotions['Promotion Name'] : "",
            items:
                productList.map((item, index) =>({
                    item_id: (item.sku) ? item.sku : "",
                    item_name: (item.description) ? item.description : "",
                    item_brand: (item.brandName) ? item.brandName : "",
                    item_category: (data.breadCrumbs && data.breadCrumbs["categoryLevelOne"]) ? data.breadCrumbs["categoryLevelOne"] : "",
                    item_category2: (data.breadCrumbs && data.breadCrumbs["categoryLevelTwo"]) ? data.breadCrumbs["categoryLevelTwo"] : "",
                    item_category3: (data.breadCrumbs && data.breadCrumbs["categoryLevelThree"]) ? data.breadCrumbs["categoryLevelThree"] : "",
                    item_category4: (data.breadCrumbs && data.breadCrumbs["categoryLevelFour"]) ? data.breadCrumbs["categoryLevelFour"] : "",
                    item_category5: (data.breadCrumbs && data.breadCrumbs["categoryLevelFive"]) ? data.breadCrumbs["categoryLevelFive"] : "",
                    item_variant: "",
                    location_id: "",
                    price: GA4viewListItem.getItemPrice(item),
                    index: index+1,
                    quantity: 1}))
        },
    });
}

export const sendSelectPromotionAnalytics = (event, data) => {
    var currencyCode = JSON.parse(sessionStorage.currencyCodeForGA);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ecommerce: null});
    window.dataLayer.push({
        event: "select_promotion",
        ecommerce: {
            items: [{
                item_id: "",
                item_name: "",
                affiliation: "",
                coupon: "",
                creative_name: event.currentTarget.getAttribute("CreativeName"),
                creative_slot:event.currentTarget.getAttribute("CreativeSlot"),
                currency: currencyCode ? currencyCode : "",
                discount: "",
                index: "",
                item_brand: "",
                item_category: (data.breadCrumbs && data.breadCrumbs["categoryLevelOne"]) ? data.breadCrumbs["categoryLevelOne"] : "",
                item_category2: (data.breadCrumbs && data.breadCrumbs["categoryLevelTwo"]) ? data.breadCrumbs["categoryLevelTwo"] : "",
                item_category3: (data.breadCrumbs && data.breadCrumbs["categoryLevelThree"]) ? data.breadCrumbs["categoryLevelThree"] : "",
                item_category4: (data.breadCrumbs && data.breadCrumbs["categoryLevelFour"]) ? data.breadCrumbs["categoryLevelFour"] : "",
                item_category5: (data.breadCrumbs && data.breadCrumbs["categoryLevelFive"]) ? data.breadCrumbs["categoryLevelFive"] : "",
                item_list_id: "",
                item_list_name: "",
                item_variant: "",
                location_id: "",
                price: "",
                promotion_id: event.currentTarget.getAttribute("PromotionID"), // Name or ID is required.
                promotion_name: event.currentTarget.getAttribute("PromotionName"),
                quantity: 1
            }],
        },
    });
}

// ========== NO IMAGE ================
export const imgSrc = (img) => {
    let imgSrc = (img && img !== undefined) ? img : "/static/images/no_image.png"
    imgSrc =  window.location.pathname.toString().includes("/fc") && !imgSrc.toString().includes("//") ? `/fc${imgSrc}` : imgSrc
    return (imgSrc)
}
//================ Script ===============
export const scriptSrc = (script) => {
    let source =  window.location.pathname.toString().includes("/fc") && !script.toString().includes("//") ? `/fc${script}` : script
    return (source)
}
//============Select_item (Category clicks GA event)==========
export const sendCategoryClickAnalytics = (category, position,page) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "select_item", ecommerce: {
            items: [{
                item_name: (category.name) ? `${category.name}` : ((category.categoryLevelOne) ? `${category.categoryLevelOne}` : ""), // Name or ID is required.
                item_id: (category.categoryId) ? `${category.categoryId}` : "", // Category ID
                item_brand: "",
                item_category: (category.categoryLevelOne) ? `${category.categoryLevelOne}` : "", // Category tree
                item_category2: (category.categoryLevelTwo) ? `${category.categoryLevelTwo}` : "",
                item_category3: (category.categoryLevelThree) ? `${category.categoryLevelThree}` : "",
                item_category4: (category.categoryLevelFour) ? `${category.categoryLevelFour}` : "",
                item_category5: (category.categoryLevelFive) ? `${category.categoryLevelFive}` : "",
                item_variant: "",
                item_list_name: page,
                item_list_id: "",
                index: position, // Category position
                quantity: 1,
            }]
        }
    });
}

// ========== Punch Out User set JsessionId ================
export const setJessionId = (url) => {
    const jsessionid =  window.location.pathname.indexOf("jsessionid") > 0 ? window.location.pathname.split(";").slice(-1)[0] : window.sessionStorage.jsessionid;
    const anchorUrl = (url !== undefined && url !== null) && url.split("?");
    const href = anchorUrl ? (jsessionid ?  (anchorUrl.slice(1).length > 0) ? `${anchorUrl[0]};${jsessionid}?${anchorUrl.slice(1)}` : `${anchorUrl[0]};${jsessionid}` : url) : "#";
    return (href)
}

export const setfcAndJessionId = (url) => {
    const jsessionid =  window.location.pathname.indexOf("jsessionid") > 0 ? window.location.pathname.split(";").slice(-1)[0] : window.sessionStorage.jsessionid;
    const anchorUrl = (url !== undefined && url !== null) && url.split("?");
    let href = anchorUrl ? (jsessionid ?  (anchorUrl.slice(1).length > 0) ? `${anchorUrl[0]};${jsessionid}?${anchorUrl.slice(1)}` : `${anchorUrl[0]};${jsessionid}` : url) : "#";
    href = (window.location.pathname.toString().includes("/fc") && !URL.toString().includes("/fc")) ? `${window.baseUrl}${href}` : href;
    return (href)
}

export const sendPDAnalyticsData = (productDetail, event_type, item_type) => {
    var priceData = GA4viewListItem.getItemPrice(productDetail);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: event_type,
        ecommerce: {
            items: [{
                item_id: `${productDetail.sku}`,
                item_name: `${productDetail.description}`,
                price: priceData ? priceData : "",
                item_brand: (productDetail.brandName) ? `${productDetail.brandName}` : "",
                item_category: (productDetail.categoryLevelOne) ? `${productDetail.categoryLevelOne}` : "",
                item_category2: (productDetail.categoryLevelTwo) ? `${productDetail.categoryLevelTwo}` : "",
                item_category3: (productDetail.categoryLevelThree) ? `${productDetail.categoryLevelThree}` : "",
                item_category4: (productDetail.categoryLevelFour) ? `${productDetail.categoryLevelFour}` : "",
                item_variant: "",
                item_list_name: item_type,
                item_list_id: "",
                index: 1,
                quantity: 1
            }]
        }
    });
}

export const sendAddToCartAnalyticsData = (productDetail, quantity, itemListName, res) => {
    var priceData = GA4viewListItem.getItemPrice(productDetail)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
            items: [{
                item_id: `${productDetail.sku}`,
                item_name: `${productDetail.description}`,
                currency : res.shoppingCartSummary.currencyCode ? res.shoppingCartSummary.currencyCode : "",
                price: priceData ? priceData : "",
                item_brand: (productDetail.brandName) ? `${productDetail.brandName}` : "",
                item_category: (productDetail.categoryLevelOne) ? `${productDetail.categoryLevelOne}` : "",
                item_category2: (productDetail.categoryLevelTwo) ? `${productDetail.categoryLevelTwo}` : "",
                item_category3: (productDetail.categoryLevelThree) ? `${productDetail.categoryLevelThree}` : "",
                item_category4: (productDetail.categoryLevelFour) ? `${productDetail.categoryLevelFour}` : "",
                item_category5: "",
                item_variant: "",
                item_list_id: "",
                item_list_name: itemListName ? itemListName : "",
                quantity: quantity
            }]
        }
    });
}

// ========== Removes empty or tags containing just '&nbsp;' ================
// export function cleaner(el) {
//   if (el.innerHTML === '&nbsp;' || el.innerHTML === '') {
//     el.parentNode.removeChild(el);
//   }
// }

//================GA4 event for View_item_list================
export var GA4viewListItem = {
    init : function (productList, resultType, viewPreference, productViewPreference, counterBook) {
        var itemListName = GA4viewListItem.getItemListName(resultType, viewPreference, productViewPreference);
        if(productList){
            if(resultType !== "counterBook" && viewPreference !== "COUNTERBOOK"){
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ecommerce: null});
                window.dataLayer.push({
                    event: "view_item_list",
                    ecommerce: {
                        items: GA4viewListItem.getItemList(productList, itemListName)
                    }
                });
            }
        }
        else if(counterBook && resultType === "counterBook" && (viewPreference === "COUNTERBOOK" || viewPreference === "")){
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ecommerce: null});
            window.dataLayer.push({
                event: "view_item_list",
                ecommerce: {
                    items: GA4viewListItem.getItemListForCounterBook(counterBook.sections, itemListName)
                }
            });
        }
    },
    getItemList : function(productList, itemListName){
        var items = [];
        for(var i = 0; i < productList.length; i++){
            const obj = {
                item_name: productList[i].description ? productList[i].description : "",
                item_id: productList[i].sku ? productList[i].sku : "",
                price: GA4viewListItem.getItemPrice(productList[i]),
                item_brand: (productList[i].brandName) ? productList[i].brandName : "",
                item_category: productList[i].categoryLevelOne ? productList[i].categoryLevelOne : "",
                item_category2: productList[i].categoryLevelTwo ? productList[i].categoryLevelTwo : "",
                item_category3: productList[i].categoryLevelThree ? productList[i].categoryLevelThree : "",
                item_category4: productList[i].categoryLevelFour ? productList[i].categoryLevelFour : "",
                item_variant: "",
                item_list_name: itemListName,
                item_list_id: "",
                index: i+1,
                quantity: 1
            }
            items.push(obj);
        }
        return items;
    },

    getItemListForCounterBook : function (productList, itemListName){
        var items = [];
        var index = 0;
        if(productList){
            productList.map((counterBookList)=> {
                counterBookList.cbSubsactions.map((cbProductsList)=> {
                    cbProductsList.products.map((cbItemsList) =>{
                        if(index<25){
                            items.push(GA4viewListItem.getProductObjForCB(cbItemsList, itemListName, index));
                            index++;
                        }else{
                            return items;
                        }
                    })
                })
            });
        }
        return items;
    },

    getProductObjForCB : function (singleItem, itemListName, index){
        const obj = {
            item_name: singleItem.description ? singleItem.description : "",
            item_id: singleItem.sku ? singleItem.sku : "",
            price: GA4viewListItem.getItemPrice(singleItem),
            item_brand: singleItem.brandName ? singleItem.brandName : "",
            item_category: singleItem.categoryLevelOne ? singleItem.categoryLevelOne : "",
            item_category2: singleItem.categoryLevelTwo ? singleItem.categoryLevelTwo : "",
            item_category3: singleItem.categoryLevelThree ? singleItem.categoryLevelThree : "",
            item_category4: singleItem.categoryLevelFour ? singleItem.categoryLevelFour : "",
            item_variant: "",
            item_list_name: itemListName,
            item_list_id: "",
            index: index+1,
            quantity: 1
        }
        return obj;
    },

    getItemListName : function (resultType, viewPreference, productViewPreference){
        if(resultType === "categoryList"){
            return "Search Results";
        }
        else if(resultType === "productList"){
            if(viewPreference){
                return GA4viewListItem.getViewType(viewPreference);
            }
            else if(productViewPreference) {
                return GA4viewListItem.getViewType(productViewPreference);
            }
        }
        else if(resultType === "counterBook"){
            return "Catalog View";
        }
        else if(resultType === "Related Items"){
            return "Related Items";
        }
        else if(resultType === "Alternative Items"){
            return "Alternative Items";
        }
        else{
            return "";
        }
    },

    getViewType : function (view){
        if(view === "LIST"){
            return "List View";
        }
        else if(view === "GRID"){
            return "Grid View";
        }
        else if(view === "COUNTERBOOK"){
            return "Catalog View";
        }
    },

    getItemPrice : function (singleItem){
        if(singleItem.priceDisplayData){
            var hasUnitPrice = false;
            var hasWholesalePrice = false;
            var hasYourPrice = false;
            var hasOnlinePrice = false;
            var hasDiscountPrice = false;
            var unitPrice;
            var wholesalePrice;
            var yourPrice;
            var onlinePrice;
            var discountPrice;

            var priceDisplayDataList = singleItem.priceDisplayData;
            priceDisplayDataList.forEach((priceList)=>{
                if(priceList.label === "Unit Price:"){
                    hasUnitPrice = true;
                    unitPrice = priceList.price;
                }
                else if(priceList.label === "Wholesale:"){
                    hasWholesalePrice = true;
                    wholesalePrice = priceList.price;
                }
                else if(priceList.label === "Online Price:"){
                    hasOnlinePrice = true;
                    onlinePrice = priceList.price;
                }
                else if(priceList.label === "Your Price:"){
                    hasYourPrice = true;
                    yourPrice = priceList.price;
                }
                else{
                    hasDiscountPrice = true;
                    discountPrice = priceList.price;
                }
            });

            if(hasUnitPrice){
                return GA4viewListItem.getItemPriceWithoutSymbol(unitPrice);
            } else if(hasWholesalePrice){
                return GA4viewListItem.getItemPriceWithoutSymbol(wholesalePrice);
            } else if(hasOnlinePrice){
                return GA4viewListItem.getItemPriceWithoutSymbol(onlinePrice);
            } else if(hasYourPrice){
                return GA4viewListItem.getItemPriceWithoutSymbol(yourPrice);
            } else if(hasDiscountPrice){
                return GA4viewListItem.getItemPriceWithoutSymbol(discountPrice);
            } else{
                return "";
            }
        }
        return "";
    },

    getItemPriceWithoutSymbol : function (price) {
        if(typeof price === "string"){
            return parseFloat(price.substring(price.search(/\d/), price.length));
        }else{
            return price;
        }
    }
}

export const titleCase = (str) => {
    return str?.toLowerCase()?.replace(/(^|\s)(\w)/g, function (x) {
        return x?.toUpperCase();
    });
};

export const convertArrayToObject = (arr) =>
    arr?.reduce(
        (obj, item) => ({
            ...obj,
            [item["name"]]: item,
        }),
        {}
    ) ?? {};

export const changeColorToOrange =  (event) => {
    Array.from(document.getElementsByClassName("navBarBranchPopupLink")).forEach((ele)=>{
        ele.style.color = "#00599c";
    });
    event.target.style.color = "#ff6600";
}