import { ADD_TO_TEMPLATE_LIST, CATEGORY_MENU_LIST, DETECT_WINDOW_SIZE, PAGE_LABELS, VIEW_PREFERENCE, CLOSE_SUGGESTIONS } from '../actions/action-types/common-actions';

const initTemplateList = {
    addToTemplateList: [],
    baseUrl: "",
    categoryMenuList: [],
    labelList: [],
    isMobileView: false,
    viewPreference: {},
    closeSuggestions: false,
};

function commonReducers(state = initTemplateList, action) {
    //Inside Home Component
    if (action.type === ADD_TO_TEMPLATE_LIST) {
        return {
            ...state,
            addToTemplateList: action.templateList
        }
    }

    if (action.type === CATEGORY_MENU_LIST) {
        return {
            ...state,
            categoryMenuList: action.menuList
        }
    }
    if (action.type === PAGE_LABELS) {
        return {
            ...state,
            pageLabels: action.labelList
        }
    }
    if (action.type === DETECT_WINDOW_SIZE) {
        return {
            ...state,
            isMobileView: action.isMobileView
        }
    }
    if (action.type === VIEW_PREFERENCE) {
        return {
            ...state,
            viewPreference: {...state.viewPreference, [action.categoryId]: action.preferenceView }
        }
    }

    if (action.type === CLOSE_SUGGESTIONS) {
        return {
            ...state,
            closeSuggestions: action.suggestionsClose,
        }
    }

    else {
        return state
    }
}

export default commonReducers;