import { ADD_TO_CART } from '../actions/action-types/cart-actions';

const initProduct = {
    addedProducts: {}
};

function cartReducer(state = initProduct, action) {
    //Inside Home Component
    if (action.type === ADD_TO_CART) {
        return {
            ...state,
            addedProducts: action.shoppingCartSummary
        }
    }
    else {
        return state
    }
}

export default cartReducer;