import React from "react";
import { connect } from "react-redux";

function ViewAs(props) {
	const label = props.labels;
	return (
		<>
			{props.userSetting && props.userSetting.viewingAs &&
				<div className="view-as-notice">
					<strong>
						{label?.viewingAs} {props.userSetting.viewingAs}
						<a href="javascript:void(0)" data-ol-has-click-handler="" onClick={()=>{window.location.href="/azurelogin/end-session"}}>{label?.endSession}</a>
					</strong>
				</div>
			}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		labels: state.commonReducer.pageLabels
	}
};

export default connect( mapStateToProps, null )(ViewAs);