import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {setJessionId} from "../helpers/CommonScripts";

function DidYouMean(props) {
  const label = props.labels;
  const didYouMean = props.didYouMean;
  const row = props.row || false;

  return (
    <div className={"mt-1 mb-4 search-instead font-weight-600" + (row ? " row" : "")}>
      {label?.searchInstead}:
      {didYouMean.map((searchVal, index) => {
        return (
          <span key={index}>
            {index > 0 ? 'or':''}
            <Link to={setJessionId(`/product?squery=${encodeURIComponent(searchVal.query)}&fsi=1`)} key={index} className = "ml-1">
              <u className="mr-1" dangerouslySetInnerHTML={{ __html: searchVal.query }}></u>
            </Link>
          </span>
        )
      })}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};

export default connect( mapStateToProps, null )(DidYouMean);