import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {Link, useHistory, useLocation} from 'react-router-dom';
import Aggregations from "./Aggregations";
import $ from "jquery";
import {
  appliedAggregation,
  globalSearchParameter,
  appendQuerySeparator,
  constructBasicURLParam
} from '../helpers/Helpers';
import queryString from 'query-string';
import {PAGE_LABELS as labels} from "../constants/Labels";

function LeftFilter(props) {
  const label = props.labels;
  const [appliedSearchTerms, setAppliedSearchTerms] = useState('');
  const [freshSearchTerm, setFreshSearchTerm] = useState('');
  const aggregations = props.responseData.aggregations;
  const searchRequest = props.responseData.searchRequest;
  props.responseData.searchRequest.attributeFilters = {...props.responseData.searchRequest.attributeFilters, ...props.responseData.suggestedAttributes}
  const attributeFilters = searchRequest["attributeFilters"] || {};
  const history = useHistory();
  const baseUrl = window.location.pathname;
  const [height, setHeight] = useState(window.innerHeight);
  const location = useLocation();
  const queryParams = queryString.parse(history.location.search, {
    ignoreQueryPrefix: true
  });
  const leftFilterId = document.getElementById("leftAggr01");
  const leftFilterInputs = leftFilterId?.getElementsByTagName('input');
  if(leftFilterInputs && leftFilterInputs.length > 0) {
    for (const child of leftFilterInputs) {
      child.value = "";
    }
  }
  const categoryId = queryParams.categoryId;
  const productFamilyId = queryParams.productFamilyId;
  function removeCurrenttFromArray(arr, current) {
    return  arr.filter(function(x) { return x !== current; });
  }

  const updateDimensions = () => {
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    setTimeout(() => {
      const aggDiv = document.getElementById("aggregation_div");
      aggDiv && (aggDiv.scrollTop = 0);
    }, 3000);
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [location]);
  
  function exceptCurrentAppliedSearch(appliedSeachArr, appliedSearchText) {
    var applied_search = removeCurrenttFromArray(appliedSeachArr, appliedSearchText).join();
    var applied_paramters = appliedParametersForSearchTerms();
    var res = '';

    if (applied_search !== "" && applied_paramters !== ''){
      res = `${applied_paramters}&searchTerms=${encodeURIComponent(applied_search)}`;
    }else if (applied_search !== "" && applied_paramters === ''){
      res = `?searchTerms=${encodeURIComponent(applied_search)}`;
    }else if (applied_search === "" && applied_paramters !== '') {
      res = `${applied_paramters}`;
    }
    return res;
  }

  function removeGlobalSearch() {
    var applied_search_terms = removeCurrenttFromArray(searchRequest.searchTerms || [], '').join();
    var applied_aggregation = appliedAggregation(attributeFilters, '')
    var url = '';

    if (applied_aggregation){
      url = `?${applied_aggregation}`
    }

    if (applied_search_terms){
      url = `${appendQuerySeparator(url)}searchTerms=${encodeURIComponent(applied_search_terms)}`;
    }
    return url;
  }

  function applyNewSearchTerm(event) {
    var newSearchText = event.target.value;
    if (newSearchText && newSearchText.trim() !== ''){
      if (appliedSearchTerms === '' && appliedParametersForSearchTerms() === '') {
        setFreshSearchTerm(`?searchTerms=${encodeURIComponent(newSearchText)}`);
      }else if(appliedSearchTerms === '' && appliedParametersForSearchTerms() !== '') {
        setFreshSearchTerm(`&searchTerms=${encodeURIComponent(newSearchText)}`);
      }else {
        setFreshSearchTerm(`,${encodeURIComponent(newSearchText)}`);
      }
    }else {
      setFreshSearchTerm('');
    }
  }

  function appliedParametersForSearchTerms(){
    var url = '';
    var globalSearch =  globalSearchParameter(searchRequest);
    var aggregation = appliedAggregation(attributeFilters, '');

    if (globalSearch){
      url = `?${globalSearch}`
    }
    if (aggregation){
      if(url !== ''){
        url = `${url}&${aggregation}`;
      }else{
        url = `?${aggregation}`
      }
    }

    return url;
  }

  function appliedSearchTermsFun(){
    var applied_parameters = '';

    if (appliedSearchTerms !== '' && appliedSearchTerms !== undefined){
      if(appliedParametersForSearchTerms() === ''){
        applied_parameters = `?${appliedSearchTerms}`;
      }else{
        applied_parameters = `&${appliedSearchTerms}`;
      }
    }
    return applied_parameters;
  }

  const appendCategoryAndReplace = (url) => {
    url = url ? url : history.location.pathname;
    url = categoryId !== undefined ? `${appendQuerySeparator(url)}categoryId=${categoryId}` : url;
    url = productFamilyId !== undefined ? `${appendQuerySeparator(url)}productFamilyId=${productFamilyId}` : url;
    if( props.responseData.resultType !== "categoryList" || searchRequest["query"] || searchRequest["squery"]){
      url = searchRequest.sortBy ? `${appendQuerySeparator(url)}sortBy=${searchRequest.sortBy}` : url;
    }
    url = searchRequest.productListOnly ? `${appendQuerySeparator(url)}productListOnly=${searchRequest.productListOnly}` : url;
    $("#searchTermInput").val("");
    setFreshSearchTerm('');
    url = url.includes("?") ? url + "&includeSuggestedFilters=false" : url+"?includeSuggestedFilters=false"
    history.push(url.replace(window.baseUrl,""));
  };

  const isIE = () => {
    const ua = navigator.userAgent;
    return ua && (ua.indexOf('MSIE') > -1 || ua.indexOf('Trident') > -1);
  };

  function handleAggregationScroll() {
    let aggregation_div = document.getElementById("aggregation_div");
    let footer_div = document.getElementById("ecom-footer");
    aggregation_div && (aggregation_div.style.minHeight = "100%");
    if (aggregation_div && footer_div) {
      let siteHeaderHeight = document.getElementById("ecom-header")?.getBoundingClientRect()?.height;
      let promotionsTopHeight = document.getElementById("promotions-top")?.getBoundingClientRect()?.height;
      let breadcrumbHeight = document.getElementById("ecom-breadcrumb")?.getBoundingClientRect()?.height;
      let footerHeight = document.getElementById("ecom-footer")?.getBoundingClientRect()?.height;
      let prodSection = document.getElementById("product-section");
      prodSection.style.minHeight = (height-(promotionsTopHeight+breadcrumbHeight+siteHeaderHeight+footerHeight)) + "px";
      let leftAggrComputedStyle = window.getComputedStyle(aggregation_div.parentElement);
      if(isIE()){
        aggregation_div.style.width = parseFloat(leftAggrComputedStyle.width) + "px";
      }
      else{
        aggregation_div.style.width = parseFloat(leftAggrComputedStyle.width) - (parseFloat(leftAggrComputedStyle.paddingRight) + parseFloat(leftAggrComputedStyle.paddingLeft)) + "px";
      }
      fixAggregations();
      window.addEventListener("scroll", () => {
        fixAggregations();
        setHeight(window.innerHeight);
      });
    }
  }

  if(window.console){
    if (window.innerWidth > 992) { fixAggregations()};
  }

  useEffect(() => {
    setTimeout(() => {
      if (window.innerWidth > 992) { fixAggregations()};
    }, 200);
  },[props.compareProducts, height]);

  function fixAggregations() {
    let aggregation_div = document.getElementById("aggregation_div");
    let footer_div = document.getElementById("ecom-footer");
    let promotionsTopHeight = document.getElementById("promotions-top")?.getBoundingClientRect()?.height;
    let breadcrumbHeight = document.getElementById("ecom-breadcrumb")?.getBoundingClientRect()?.height;
    let siteHeaderHeight = document.getElementById("ecom-header")?.getBoundingClientRect()?.height;
    let breadcrumbWhiteSpace = document.getElementById("breadcrumb-white-space")?.getBoundingClientRect()?.height;
    let prodSectionHeight =  document.getElementById("product-section")?.getBoundingClientRect()?.height;
    if(aggregation_div && footer_div) {
      let footerTop = footer_div.getBoundingClientRect().top;
      let compareFooter = document.getElementById("compare_footer");
      let compareFooterHeight = compareFooter ? document.getElementById("compare_footer")?.getBoundingClientRect()?.height : 0;
      let fixHeader =  (breadcrumbHeight+promotionsTopHeight) <= window.pageYOffset;

      if (fixHeader && window.innerWidth > 992) {
        aggregation_div.style.top =  siteHeaderHeight + breadcrumbHeight + "px";
        aggregation_div.className = "fixed-aggregations mt-3";
        aggregation_div.style.maxHeight = Math.min(prodSectionHeight, footerTop - (siteHeaderHeight+breadcrumbWhiteSpace), (window.innerHeight - compareFooterHeight- siteHeaderHeight-breadcrumbWhiteSpace)).toString() + "px";
      } else if(window.innerWidth > 992) {
        aggregation_div.style.top = "";
        aggregation_div.className = "mb-3";
        aggregation_div.style.overflowY = "auto";
        aggregation_div.style.minHeight = "";
        let aggDivHeight =  Math.min(prodSectionHeight, footerTop - (siteHeaderHeight+breadcrumbWhiteSpace), (window.innerHeight - compareFooterHeight- siteHeaderHeight-breadcrumbWhiteSpace));
        let bodyContHeight = window.innerHeight - (siteHeaderHeight + breadcrumbHeight + compareFooterHeight);
        aggregation_div.style.maxHeight = (aggDivHeight < bodyContHeight && prodSectionHeight < bodyContHeight ? bodyContHeight  : aggDivHeight).toString() + "px";
      }
    }
  }

  useEffect(() => {
    if (searchRequest["searchTerms"]){
      setAppliedSearchTerms(`searchTerms=${encodeURIComponent(searchRequest["searchTerms"].join(','))}`);
    }else{
      setAppliedSearchTerms("")
    }
    if (window.innerWidth > 992) { handleAggregationScroll();}
  }, [searchRequest]);

  return (
    <>
      <div id="aggregation_div" className="overflow-auto">
      <div className="card mt-2 clearfix">
        <div className="card-header bg-primary text-white p-1 pl-2 pr-2">
          {label?.searchTerms}
        </div>
        <form>
          <div className="input-group ecom-searchbar ecom-searchbar-small ie-left-aggr-search">
            <input type="text" className="form-control ecom-search-input" id="searchTermInput" placeholder={label?.SearchWithin} autoComplete="off" onChange = {applyNewSearchTerm}/>
            <div className="input-group-append">
              <button className="btn ecom-button-search ecom-button-plain ecom-button-inn"
                      onClick={(e) => {e.preventDefault(); appendCategoryAndReplace(`${baseUrl}${appliedParametersForSearchTerms()}${appliedSearchTermsFun()}${freshSearchTerm}`)}} aria-label={labels?.search} type="submit">
                <i className="fas fa-search" aria-hidden="true"/>
              </button>
            </div>
          </div>
        </form>
        <div>
          {searchRequest && searchRequest.query ?
            <span className="aggr-span">
              <span>{searchRequest.query}</span>
              <span className="fas fa-times-circle text-danger ml-1" style={{cursor: "pointer"}}
    onClick={() => {
      appendCategoryAndReplace(`${baseUrl}${removeGlobalSearch()}`)
    }} />
            </span> : ''
          }
          {searchRequest && searchRequest.squery ?
            <span className="aggr-span">
              <span>{searchRequest.squery}</span>
              <span className="fas fa-times-circle text-danger ml-1" style={{cursor: "pointer"}}
                onClick={() => {
                  appendCategoryAndReplace(`${baseUrl}${removeGlobalSearch()}`)
                }} />
            </span> : ''
          }
          {
            searchRequest && searchRequest.searchTerms && searchRequest.searchTerms.map((search_value, index) => {
              return(
              <span className="aggr-span" key={index}>
                <span>{search_value}</span>
                <span className="fas fa-times-circle text-danger ml-1" style={{cursor: "pointer"}}
                      onClick={() => {appendCategoryAndReplace(`${baseUrl}${exceptCurrentAppliedSearch(searchRequest.searchTerms, search_value)}`)}} ></span>
              </span>
              )
            })
          }
        </div>
      </div>
      { !props.responseData.productList && !props.responseData.counterbook && props.responseData.categoryList && props.responseData.categoryList.length > 0 && props.responseData.categoryList[0].familyId &&
        <strong className="d-flex justify-content-center pt-2">
          <Link
            to={`${baseUrl.replace(window.baseUrl, "")}${appendQuerySeparator(searchRequest && constructBasicURLParam(searchRequest))}productListOnly=true${searchRequest?.categoryId ? "&categoryId="+searchRequest.categoryId : ""}`}>{label?.showAll} {props.responseData.paging.totalCount} {label?.results}</Link>
        </strong>
      }
      <div className="accordion mt-2 mb-3" id="leftAggr01">
        {aggregations && aggregations.filter(Boolean).map((aggregation, index) => {
          return (
            <div key={index} className="mt-1">
              <Aggregations responseData={props.responseData} aggregation={aggregation} aggregationIndex={index} appendCategoryAndReplace={ appendCategoryAndReplace }/>
            </div>
          )
        })}
      </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels,
    compareProducts: state.compareReducer.compareItems
  }
};

export default connect( mapStateToProps, null )(LeftFilter);