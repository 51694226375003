import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sortBy} from 'lodash';
import Pagination from "react-js-pagination";
import { imgSrc, setJessionId } from '../helpers/CommonScripts';
import { Link } from 'react-router-dom';
import cx from 'classnames';

function VendingMachineListView(props) {
    const label = props.labels;
    const vendingDetails = props.vendingDetails;
    const planogramCellsInfo= [].concat.apply([], vendingDetails.planogramCell && vendingDetails.planogramCell).filter(Boolean).map((k) => k.sku ? k : null).filter(Boolean);
    const [planogramCells, setPlanogramCells] = useState(planogramCellsInfo)
    const [activePage, setCurrentPage] = useState(1);
    const [itemsCountPerPage, setItemsCountPerPage] = useState(10);
    const [sortByName, setSortByName] = useState("");
    const [sortByKeyVal, setSortByKeyVal] = useState("");

    // Logic for displaying current Page
    const indexOfLastRecord  = activePage * itemsCountPerPage;
    const indexOfFirstRecord = indexOfLastRecord - itemsCountPerPage;
    const currentPlanogramCells = planogramCells && planogramCells.slice( indexOfFirstRecord, indexOfLastRecord );
    const planogramCellsCount = planogramCells && planogramCells !== undefined ? planogramCells.length : 0;
    const [columnSort,setColumnSort] = useState({
        thumbnailUrl: '',
        Sku: '',
        qoh: '',
        motorPosition: '',
        inventoryTurns: '',
        inventoryValue: '',
        minQty: '',
    })

    useEffect(() => {
       setPlanogramCells(planogramCells)
    }, [activePage, itemsCountPerPage])

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    function handleSelectChange(event) {
        setCurrentPage(1)
        let itemsPerPage = Number(event.target.value);
        setItemsCountPerPage(itemsPerPage);
    }

    const sortByHeaderKey = (event, key) => {
         const sortingBy = (sortByKeyVal === key) ? ["", "DESC"].includes(sortByName) ? "ASC" : "DESC" : "ASC";
        let sortedPlanogramCells = (key !== "sku") ? sortBy(planogramCells, [key]) : planogramCells.sort((a, b) => b[key] - a[key]);
        sortedPlanogramCells = sortingBy === "ASC" ? sortedPlanogramCells : sortedPlanogramCells.reverse();
        setSortByName(sortingBy);
        setPlanogramCells(sortedPlanogramCells);
        setSortByKeyVal(key)
    }
    
    return (
        <>
            <div className="d-flex ie-inline-block mb-2 ">
                <div className="d-flex align-items-center text-muted">
                    <div className="col pl-0 text-nowrap font-weight-bold">
                        Show
                        <select className="custom-select custom-select-sm feco-vending-select-60 feco-select ml-1 mr-1" defaultValue={itemsCountPerPage} onChange={handleSelectChange}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        Entries
                    </div>
                </div>
            </div>
            <table className="table table-bordered table-sm table-striped">
                <thead>
                    <tr>
                        <th scope="col" className="feco-header-table-planogram" onClick={(e) => sortByHeaderKey(e, "thumbnailUrl")}>
                            {label?.image} <i className={cx('fas feco-planogram-table-icon',{
                            ['fa-sort-up'] : columnSort['thumbnailUrl'] === 'DESC'  ,
                            ['fa-sort-down'] : !columnSort['thumbnailUrl'] === 'ASC',
                            ['fa-sort'] : !columnSort['thumbnailUrl'] === ''
                        })}></i>
                        </th>
                        <th scope="col" className="feco-header-table-planogram" onClick={(e) => sortByHeaderKey(e, "sku")}>
                            {label?.sku} <i className={cx('fas feco-planogram-table-icon',{
                            ['fa-sort-up'] : columnSort['sku'] === 'DESC'  ,
                            ['fa-sort-down'] : !columnSort['sku'] === 'ASC',
                            ['fa-sort'] : !columnSort['sku'] === ''
                        })}></i>
                        </th>
                        <th scope="col" className="feco-header-table-planogram" onClick={(e) => sortByHeaderKey(e, "skuDescription")}>
                            {label?.desc} <i className={cx('fas feco-planogram-table-icon',{
                            ['fa-sort-up'] : columnSort['skuDescription'] === 'DESC'  ,
                            ['fa-sort-down'] : !columnSort['skuDescription'] === 'ASC',
                            ['fa-sort'] : !columnSort['skuDescription'] === ''
                        })}></i>
                        </th>
                        <th scope="col" className="feco-header-table-planogram" onClick={(e) => sortByHeaderKey(e, "qoh")}>
                            QOH <i className={cx('fas feco-planogram-table-icon',{
                            ['fa-sort-up'] : columnSort['qoh'] === 'DESC'  ,
                            ['fa-sort-down'] : !columnSort['qoh'] === 'ASC',
                            ['fa-sort'] : !columnSort['qoh'] === ''
                        })}></i>
                        </th>
                        <th scope="col" className="feco-header-table-planogram" onClick={(e) => sortByHeaderKey(e, "motorPosition")}>
                            {label?.positions} <i className={cx('fas feco-planogram-table-icon',{
                            ['fa-sort-up'] : columnSort['motorPosition'] === 'DESC'  ,
                            ['fa-sort-down'] : !columnSort['motorPosition'] === 'ASC',
                            ['fa-sort'] : !columnSort['motorPosition'] === ''
                        })}></i>
                        </th>
                        <th scope="col" className="feco-header-table-planogram" onClick={(e) => sortByHeaderKey(e, "inventoryTurns")}>
                            {label?.inventoryTurns} <i className={cx('fas feco-planogram-table-icon',{
                            ['fa-sort-up'] : columnSort['inventoryTurns'] === 'DESC'  ,
                            ['fa-sort-down'] : !columnSort['inventoryTurns'] === 'ASC',
                            ['fa-sort'] : !columnSort['inventoryTurns'] === ''
                        })}></i>
                        </th>
                        <th scope="col" className="feco-header-table-planogram" onClick={(e) => sortByHeaderKey(e, "inventoryValue")}>
                            {label?.inventoryValue} <i className={cx('fas feco-planogram-table-icon',{
                            ['fa-sort-up'] : columnSort['inventoryValue'] === 'DESC'  ,
                            ['fa-sort-down'] : !columnSort['inventoryValue'] === 'ASC',
                            ['fa-sort'] : !columnSort['inventoryValue'] === ''
                        })}></i>
                        </th>
                        <th scope="col" className="feco-header-table-planogram" onClick={(e) => sortByHeaderKey(e, "minQty")}>
                            {label?.minOrMax} <i className={cx('fas feco-planogram-table-icon',{
                            ['fa-sort-up'] : columnSort['minQty'] === 'DESC'  ,
                            ['fa-sort-down'] : !columnSort['minQty'] === 'ASC',
                            ['fa-sort'] : !columnSort['minQty'] === ''
                        })}></i>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentPlanogramCells && currentPlanogramCells.map((cellInfo, cellIndex) => {
                            return (
                                <>
                                    { cellInfo &&
                                        <tr key={`cellInfo-${cellIndex}`}>
                                            <td className="text-center">
                                                {cellInfo && <img src={imgSrc(cellInfo.thumbnailUrl)} className="image-fluid img-thumbnail border-0" alt="..." width="64" height="64"/>}
                                            </td>
                                            <td>{cellInfo && <Link className="default-nav-link ml-1 font-weight-normal" to={setJessionId(`/product/details/${ (cellInfo && cellInfo.customerSku)?encodeURIComponent(cellInfo.customerSku):(cellInfo && encodeURIComponent(cellInfo.sku) )}`)}>{(cellInfo && cellInfo.customerSku)?cellInfo.customerSku:(cellInfo && cellInfo.sku ) }</Link>}</td>
                                            <td className="text-wrap w-25">{cellInfo && cellInfo.skuDescription}</td>
                                            <td className="text-center">{cellInfo && cellInfo.qoh}</td>
                                            <td>{cellInfo && cellInfo.motorPosition}</td>
                                            <td >{cellInfo && cellInfo.inventoryTurns}</td>
                                            <td>{cellInfo && parseFloat(cellInfo.inventoryValue).toFixed(1)}</td>
                                            <td>{(cellInfo && cellInfo.minQty) ? cellInfo.minQty : 0} / {(cellInfo && cellInfo.maxQty) ? cellInfo.maxQty : 0}</td>
                                        </tr>
                                    }
                                </>
                            )
                        })
                    }
                </tbody>
            </table>
            <div> {label?.showing} {indexOfFirstRecord} to {indexOfLastRecord > planogramCellsCount ? planogramCellsCount : indexOfLastRecord} of {planogramCellsCount} {label?.entries}</div>
            <div className="d-flex justify-content-center ecom-product-view-pagination mb-4 ie-pagination ie-width-full ie-float-right float-right" id={ "10" }>
                <Pagination
                activePage = { activePage }
                itemsCountPerPage = { itemsCountPerPage }
                totalItemsCount = { planogramCellsCount }
                pageRangeDisplayed = { 5 }
                onChange = {handlePageChange}
                linkClass = "page-link feco-vending-page-link border-0"
                itemClass = "page-item feco-page-item-active"
                prevPageText = "Previous"
                nextPageText = "Next"
                linkClassPrev="feco-vending-prev-button"
                linkClassNext="feco-vending-next-button"
                // itemClassPrev="disabled"
                hideFirstLastPages = {true}
                />
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};

export default connect( mapStateToProps, null )(VendingMachineListView);