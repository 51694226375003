import React, { useEffect, useState } from 'react';
import { STATIC_PAGE_URL } from '../constants/Constants';
import { getResponse, isPunchoutURL } from '../apiCalls/ApiCall';
import StaticBreadCrumb from "./StaticBreadCrumb";
import $ from "jquery";
import cx from 'classnames';

function Fast(props) {
  const [show, setShow] = useState(false);
  let fastPage = props.match.url;
  if (window.isPunchOutUser) {
    fastPage = fastPage.replace(window.baseUrl, "").split(";jsessionid")[0];
  }
  const pageName = fastPage.split("/landing").pop();
  useEffect(() => {
    props.showLoader(true);
    getResponse(STATIC_PAGE_URL+`${pageName}`, {}).then((res) => {
      $("#static-pages-container").html(res);
      if (window.isPunchOutUser) {
        const anchors = document.querySelectorAll('a, area');
        function hasClass(elem, cls) {
          return (' ' + elem.className + ' ').indexOf(' ' + cls + ' ') > -1;
        }
        anchors && anchors.forEach(anchor => {
          if (isPunchoutURL(anchor.href) && hasClass(anchor,"feco-jsessionid-none") === false) {
            if (anchor.href.indexOf("jsessionid") < 0) {
              anchor.style.setProperty("pointer-events", "none");
            }
          }
        });
        setTimeout(() => {
          props.showLoader(false);
          setShow(true)},1500)
      } else {
        props.showLoader(false);
        setShow(true);
      }
    })
    if(document.getElementsByClassName("view-as-notice")[0]){
      let container = document.getElementById("main-container");
      let paddingTop = document.getElementById("ecom-header").offsetHeight;
      container && (container.style.setProperty('padding-top', `${paddingTop}px`, 'important' ));
    }
  }, [pageName]);
  let pageTitle = document.getElementById("static-page-title")?.innerText;
  pageTitle && (document.title = pageTitle);
  return (
    <>
      <div className={cx("feco-container-fix feco-container-inner",{
          ['feco-p-mob-screen']:props.activeNavLink === 'search',
          ['no-search']:props.activeNavLink !== 'search'
      })} id="main-container">
      {show && <StaticBreadCrumb parameters={fastPage}/>}
      <div className="row">
        <div className="col-xs-12 col-sm-12 feco-container-fix flex-grow-1" id="static-pages-container">
        </div>
        </div>
      </div>
    </>
  )
};

export default Fast;