import React, { useEffect } from "react";
import { useLocation, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { imgSrc, sendSelectPromotionAnalytics } from '../helpers/CommonScripts';
import Exception from "./Exception";
import ProductDetail from "./ProductDetail";
import LeftFilter from "./LeftFilter";
import DidYouMean from "./DidYouMean";
import CategoryList from "./CategoryList";
import ProductList from "./ProductList";
import NoResult from "./NoResult";
import NonStaticRedirects from "./NonStaticRedirects";
import productList from "./ProductList";

const CatalogResponse = (props) => {
	const label = props.labels;
	const data = props.data;
	const resultType = data.resultType;
	const didYouMean = data.didYouMean;
	const currentProductFamily = data.currentProductFamily;
	const currentCategory = data.currentCategory;
	const productCount = data.paging ? data.paging.totalCount : null;
	const currentCategoryInfo = currentProductFamily ? currentProductFamily : currentCategory;
	const promotions = props.promotions;
	const nonStaticRedirects = props.nonStaticRedirects;
    const location = useLocation();
	const searchRequestQuery = data.searchRequest ? (data.searchRequest.query || data.searchRequest.squery ) : null;

	useEffect(() => {
		if(document.getElementsByClassName("view-as-notice")[0]){
			let container = document.getElementById("main-container");
			let paddingTop = document.getElementById("ecom-header").offsetHeight;
			container && (container.style.setProperty('padding-top', `${paddingTop}px`, 'important' ));
		}
	}, [location, promotions]);

	useEffect(() => {
		let resources = document.querySelectorAll(".required-js-css script");
		let resourceSpan = document.querySelector(".required-js-css");

		for (let i = 0; i < resources.length; i++) {
			if (!resources[i].getAttribute('data-loaded') && resources[i].type && resources[i].src) {
				let script = document.createElement('script');
				script.type = 'text/javascript';
				script.src = resources[i].src;
				script.setAttribute('data-loaded', true);
				resourceSpan.removeChild(resources[i]);
				resourceSpan.appendChild(script);
			}
		}
	}, [promotions]);

	useEffect(() => {
		if (promotions && promotions.GAResponse && promotions.GAResponse.length > 0) {
			let allPromotions = Array.from(document.getElementsByClassName("cms-promotion"));
			if (allPromotions) {
				JSON.parse(promotions.GAResponse).map((promo, i) => {
					allPromotions[i].setAttribute("PromotionName", promo["Promotion Name"]);
					allPromotions[i].setAttribute("PromotionID", promo["Promotion ID"]);
					allPromotions[i].setAttribute("CreativeSlot", promo["Creative Slot"]);
					allPromotions[i].setAttribute("CreativeName", promo["Creative Name"]);
					allPromotions[i].addEventListener('click', (e) => {
						sendSelectPromotionAnalytics(e, data);
					});
				})
			}
		}
	}, [promotions]);

	function itemCountDisplay() {
		if (productCount) {
			if (searchRequestQuery) {
				return <div className="row font-weight-600 feco-mx-sm-0 ie-float-left ie-width-full">{label?.showing} {productCount <= 9990 ? productCount.toLocaleString() : ""} {label?.resultsFor} {searchRequestQuery}</div>;
			} else if(resultType === "counterBook"){
				return <div className="row font-weight-600 feco-mx-sm-0">{productCount.toLocaleString()} {label?.items}</div>;
			}
		}
	}

	return (
		<>
			{(data.searchErrorMsg || data.status === 500) && <Exception />}
			{resultType === "noResults" && didYouMean && didYouMean.length === 1 ? <Redirect to={`/product?squery=${data.didYouMean[0].query}&fsi=1`}/> :
				resultType === "noResults" || data.notCountrySpecificItem ? <NoResult responseData={data} didYouMean={didYouMean} nonStaticRedirectsData={nonStaticRedirects}/> :
				<>
					{resultType === "productDetail" ? <ProductDetail responseData={data} /> :
						<>
							<div id="promotions-top">
								{promotions && promotions.top && <span dangerouslySetInnerHTML={{__html: promotions.top}}></span>}
							</div>
							<div className="row flex-nowrap flex-column flex-lg-row ie-width-full feco-mx-sm-0">
								<div className="col-xs-12 col-sm-12 col-md-12 feco-left-aggr ie-left-aggr feco-px-sm-0">
									{data.aggregations !== undefined && data.aggregations.length > 0 && <LeftFilter responseData={data} />}
								</div>
								<div className="col-xs-12 feco-right-container flex-grow-1 ie-right-container" id="product-section">
									{nonStaticRedirects && <NonStaticRedirects nonStaticRedirectsData={nonStaticRedirects}/>}
									{itemCountDisplay()}
									{resultType !== "noResults" && didYouMean && didYouMean.length > 0 && <DidYouMean didYouMean = {didYouMean} row = {true} />}
									{resultType !== "noResults" && currentCategoryInfo &&
										<div className= "row feco-mx-sm-0 feco-mx-md-0 ie-float-left ie-width-full">
											<div className="d-flex mt-2 ie-width-full ie-float-left ie-mb-10">
												<div className="d-flex align-self-start border p-2 feco-thumbnail-sm ie-float-left ie-img-space-1">
													<img src={imgSrc(currentCategoryInfo.thumbnail ? currentCategoryInfo.thumbnail :  currentCategoryInfo.thumbnailUrl)} className="rounded float-left ie-img-responsive" alt={currentCategoryInfo?.altText} title={currentCategoryInfo?.name}/>
												</div>
												<div className="pl-2 flex-grow-1 ie11-catalog-text category-description">
													<h1 dangerouslySetInnerHTML={{__html: currentCategoryInfo.name}}></h1>
													<p dangerouslySetInnerHTML={{__html: currentCategoryInfo.description}} ></p>
												</div>
											</div>
											<div className="clearfix"></div>
										</div>
									}
									{(data.categoryList !== undefined && data.categoryList.length > 0 && !(data.categoryList.length === 1 && data.categoryList[0].familyId)) && <CategoryList responseData={data} isSearch={searchRequestQuery?true:false} promotions={promotions} />}
									{((data.productList !== undefined && data.productList.length > 0) || (resultType === "counterBook")) && <ProductList responseData={data} promotions={promotions} />}
								</div>
							</div>
							<div id="promotions-bottom">
								{promotions && promotions.bottom && <span dangerouslySetInnerHTML={{__html: promotions.bottom}}></span>}
							</div>
						</>
					}
				</>
			}
		</>
	)
};

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};

export default connect( mapStateToProps, null )(CatalogResponse);