import React, { useState, useEffect,useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { getResponse, postRequest } from '../apiCalls/ApiCall';
import {
  HOME_PAGE_URL,
  NON_STATIC_REDIRECTS,
  PRODUCT_SEARCH_URL,
  PROMOTION
} from '../constants/Constants';
import { aggrigationHash, CATEGORY_LEVELS, searchTermArray, showAllArray } from "../helpers/Helpers";
import Home from "./Home";
import BreadCrumb from "./BreadCrumb";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CatalogResponse from "./CatalogResponse";
import cx from 'classnames';
import ServiceUnavailable from "./ServiceUnavailable";
import useLocationBlocker from "./LocationBlocker";
import {AddCustomCMSDataDynamically, getCMSContent} from "./GetCustomCMSContent";

function CatelogNavigator(props) {
  const label = props.labels;
  const isMobileView = props.isMobileView;
  const [data, setData] = useState({});
  const [searchQueryHasError, setSearchQueryHasError] = useState(false);
  const [nonStaticRedirects, setNonStaticRedirects] = useState("");
  const cache = useRef({});
  const URL = props.location.pathname + props.location.search;
  const location = useLocation();
  const [promotions, setPromotions] = useState({});
  const [customCMSContent, setCustomCMSContent] = useState({});
  useLocationBlocker();
  const params = props.match.params;
  let queryParams = {};
  let searchQuery = props.location.search;
  searchQuery = searchQuery.replace(/\?utm/g,'&utm');
  let parsedHash = queryString.parse(searchQuery);
  let query = parsedHash.query || "";
  const HideLoader = () => {
    const time = isMobileView ? 1500 : 3500;
    setTimeout(() => {
      props.showLoader(false);
      window.scrollTo(0, 0);
    }, time);
  };
  useEffect(() => {
    const categoryId = (["/product/all", "/product"].includes(window.location.pathname) && !searchQuery) ? "0000" : parsedHash.categoryId;
    if (params[0].includes("jsessionid")) {
      let parameters = params[0].split("product/")[1] || params[0].split("product")[1];
      parameters = parameters !== undefined ? "product/" + parameters : params[0];
      params[0] = parameters.replace(/\/$/, "").split(";").splice(0, 1).join(",");
    }
    setPromotions("");
    setCustomCMSContent("");
    if (Object.keys(params).length > 0) {
      if (params[0].includes("details")) {
        queryParams["sku"] = params[0].replace("product/details/", "").split("/")[0]
      } else if (!(params[0].includes("product/all") || params[0] === 'product' || params[0] === 'product/')) {
        params[0].replace("product/", "").split("/").filter(Boolean).map((val, index) => (
            queryParams[CATEGORY_LEVELS[index]] = val
        ));
      }
    }
    let agregation_hash = aggrigationHash(parsedHash);
    let searchTermVal = searchTermArray(parsedHash);
    let showAllVal = showAllArray(parsedHash);
    setSearchQueryHasError(false);
    if (searchTermVal.length > 0) {
      const searchTermError = (searchTermVal.join().trim().length <= 1 || searchTermVal.join().trim().length > 256) && !parsedHash.query && !parsedHash.squery;
      if (!searchTermError) {
        parsedHash["searchTerms"] = searchTermVal;
      } else {
        delete parsedHash.searchTerms;
        delete parsedHash.fsi;
      }
      setSearchQueryHasError(searchTermError);
    }
    if (parsedHash.query) {
      const queryError = parsedHash.query.length <= 1 || parsedHash.query.length > 256;
      if (queryError) {
        delete parsedHash.query;
        delete parsedHash.fsi;
      }
      setSearchQueryHasError(queryError);
    }
    const prefView = props.preferenceView[parsedHash.categoryId];
    if (cache.current[URL] && (prefView === undefined || cache.current[URL]?.productViewPreference === prefView)) {
      props.showLoader(true);
      const cacheData = cache.current[URL];
      cacheData["isCached"] = true;
      setData(cacheData);
      setTimeout(() => {
        props.showLoader(false);
      }, 1500);
      window.scrollTo(0, 0);
    } else if (location.pathname === "/" || location.pathname === "/home") {
      props.showLoader(true);
      if(sessionStorage.getItem(URL)) {
        setData(JSON.parse(sessionStorage.getItem(URL)));
        props.showLoader(false);
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 1000);
      } else{
        const fetchHomePageContent = async () => {
          getResponse(HOME_PAGE_URL, {}).then((response) => {
            setData(response);
            sessionStorage.setItem(URL, JSON.stringify(response));
            props.showLoader(false);
            window.scrollTo(0, 0);
          });
        }
        fetchHomePageContent();
      }
    } else {
      Object.keys(queryParams).forEach((key) => {
        queryParams[key] =((decodeURIComponent(queryParams[key].split(";")[0])));
      });
      if (queryParams.sku !== undefined) {
        queryParams.sku = queryParams.sku.split();
        queryParams.productDetails = true;
      }
      if (showAllVal) {
        parsedHash["showAll"] = showAllVal;
      }

      let bodyContent = Object.assign({}, queryParams, parsedHash, { "attributeFilters": agregation_hash });
      delete bodyContent[0];
      delete bodyContent.locale;
      bodyContent.pageUrl = location.pathname;
      const fetchData = async () => {
        props.showLoader(true);
        postRequest(PRODUCT_SEARCH_URL, bodyContent).then((res) => {
          res["isCached"] = false;
          fetchPromotions(res, categoryId);
          setData(res);
          if (URL.includes("productViewPreference") || !res.productViewPreference || parsedHash.pageSize || parsedHash.sortBy) {
            cache.current[URL] = res;
          }
          if (!URL.includes("productViewPreference") && res.productViewPreference){
            cache.current[URL+"&productViewPreference="+res.productViewPreference] = res;
          }
          props.showLoader(false);
          window.scrollTo(0, 0);
        });
      }
      fetchData();
    }
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      if(!window.isPunchOutUser && parsedHash.query.length >= 3 && parsedHash.query.length < 256) {
        const staticRedirects = await getResponse(`${NON_STATIC_REDIRECTS}?query=${parsedHash.query}`, {});
        setNonStaticRedirects(staticRedirects);
      } else {
        setNonStaticRedirects("");
      }
    }
    if(location.search.includes("fsi=1") && parsedHash.query !== undefined){
      fetchData();
    } else {
      setNonStaticRedirects("");
    }
    const reloadLoggedInfo = setTimeout(() => {
      if (window.userLoggedIn) {
        window.location.reload();
      }
    }, 900000);
    return () => {
      clearTimeout(reloadLoggedInfo);
    }
  }, [query, location]);

  const fetchPromotions = async (data, categoryId) => {
    const resultType = data.resultType;
    if (resultType !== undefined && ((!location.pathname.includes("details") && data.promotionAvailable) || (["/product/all", "/product"].includes(window.location.pathname))) || ((window.location.pathname.includes("/product/all") || window.location.pathname.includes("/product;")) && window.isPunchOutUser)) {
      let bodyContent = Object.assign({}, { "resultType": resultType }, { "categoryId": categoryId }, { "query": query }, { "attributeFilters": aggrigationHash(parsedHash) });
      if(cache.current[JSON.stringify(bodyContent)]){
        setPromotions(cache.current[JSON.stringify(bodyContent)]);
      }
      else{
        postRequest(PROMOTION, bodyContent).then((res) => {
          setPromotions(res);
          cache.current[JSON.stringify(bodyContent)] = res;
        });
      }
    };
  }

  useEffect(() => {
    !isMobileView && (getCMSContent(cache)?.then((cmsContent) => {
      setCustomCMSContent(cmsContent);
    }))
  }, [location])

  useEffect(() => {
    const dynamicExperienceClass = "dynamicExperience";
    let previousElements = Array.from(document.getElementsByClassName(dynamicExperienceClass));
    previousElements.forEach(item => item.remove());

    if( customCMSContent!= null && Object.keys(customCMSContent).length > 0){
      AddCustomCMSDataDynamically(customCMSContent);
    }
  }, [customCMSContent]);


  if (data && data.status === 401) {
    window.location.reload();
  }

  if(data && data.errorCode ) return <ServiceUnavailable/>

  const categoryPageInfo = data.currentProductFamily ? data.currentProductFamily : data.currentCategory;
  const productDetailInfo = data.exactCustomerSkuMatch ? data.exactCustomerSkuMatch : data.exactFastenalSkuMatch ? data.exactFastenalSkuMatch : data.productDetail;
  const parser = new DOMParser();
  let webTitle;
  let metaTagDescription;
  if(categoryPageInfo !== undefined){
    webTitle = categoryPageInfo.name;
  } else if (productDetailInfo !== undefined) {
    webTitle = productDetailInfo.description;
  }else if (data.resultType == "noResults") {
    webTitle = label?.noResults;
  }

  document.title = webTitle !== undefined ? parser.parseFromString(`${webTitle} | Fastenal`, 'text/html').body.textContent : "Fastenal Industrial Supplies, OEM Fasteners, Safety Products & More";

  if(categoryPageInfo !== undefined && categoryPageInfo.description !== undefined) {
    metaTagDescription = categoryPageInfo.description;
  } else if (productDetailInfo !== undefined && productDetailInfo.description !== undefined) {
    metaTagDescription = productDetailInfo.description;
  }
  document.getElementsByTagName('meta')["description"].content = metaTagDescription !== undefined ? metaTagDescription : "Fastenal is the largest fastener distributor in North America. Shop our huge selection of OEM, MRO, construction, industrial, and safety products.";
  document.getElementsByTagName("title")[1]?.remove();

  if ((["/", "/home"].includes(URL) || (data && data.resultType === undefined)) && !window.isPunchOutUser) return <Home homePageContent={data} setActiveNavLink={props.setActiveNavLink} />
  return (
      <div onMouseOver={() => (!isMobileView && document.getElementById("mail")?.value === '') ? props.setToggle(false) : ""} className={isMobileView
          ? "container-fluid"
          : "feco-container-fix feco-container-inner"}  style={isMobileView ? { paddingTop: "5.7rem", minHeight: "415px"} : {}}
           id={isMobileView ? "" : "main-container"}>
        <input type="hidden" name="pageType" value="Catalog Page" id='pageType'/>
        {Object.keys(data).length > 0 && <BreadCrumb breadCrumbsData={data.breadCrumbs} resultType={data.resultType} currentProductFamily={data.currentProductFamily} didYouMean={data.didYouMean} nonStaticRedirects={nonStaticRedirects} />}
        {searchQueryHasError &&
            <div className="alert alert-danger" role="alert">
              <i className="fas fa-exclamation-circle"></i> {label?.searchWarning}
            </div>
        }
        <ToastContainer />
        <CatalogResponse data={data} nonStaticRedirects={nonStaticRedirects} promotions={promotions}/>
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels,
    preferenceView: state.commonReducer.viewPreference
  }
};

export default connect( mapStateToProps, null )(CatelogNavigator);