import Popover from "react-bootstrap/Popover";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import Overlay from "react-bootstrap/Overlay";
import { showAddToTemplate } from '../../helpers/Helpers';
import AddToCartTemplate from '../AddToCartTemplate';
import { imgSrc, setJessionId } from '../../helpers/CommonScripts';
import ProductAvailability from '../ProductAvailability'
import React from "react";
import CustomCatalog from "../CustomCatalog";
import { onChangeInputQuantity } from '../OnchangeInputQuantity';
import {onkeydownInputQuantity} from "../OnkeydownInputQuantity";
import {FiRepeat} from "react-icons/fi";

function ProductPopover(props) {
	const productDetail = props.productDetail;
	const label = props.labels;
	
	const OverlayClose = () => {
		props.setShow(false)
	};

	return (
		<>
			{props.loading &&
				<Overlay show={props.show} target={props.target} placement="left" containerPadding={1}>
					<Popover id="popover-contained" className="product-popover">
						<Popover.Content>
							<div>{label?.Loading}...</div>
						</Popover.Content>
					</Popover>
				</Overlay>
			}
			{!props.loading &&
				<Overlay show={props.show} target={props.target} placement="left" onHide={OverlayClose} rootClose containerPadding={1}>
					<Popover id="popover-contained" className="feco-popover product-popover">
						<div className="popover-header bg-primary text-white feco-popover-header">
							<div className="d-flex">
								<span className="feco-popover-header">
									<span dangerouslySetInnerHTML={{ __html: productDetail.description }}></span>
								</span>
								<span className="ml-auto ie-float-right">
									<button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={(e) => {
										props.setShow(false)
									}}>
										<span aria-hidden="true">&times;</span>
									</button>
								</span>
							</div>
						</div>
						<Popover.Content>
							<div className="card">
								<div className="row">
									<div className="col-xs-12 col-md-3">
										{( productDetail.isExpress !== undefined && productDetail.isExpress === 'Y') &&
											<div className="row">
												<div className="col-xs-12">
												<img src={imgSrc("/static/images/express-logo-small.png")} className="ml-3" alt="..." style={{width: "75px"}}/>
												</div>
											</div>
										}
									<div className="border p-2 feco-thumbnail-sm d-flex mr-0 position-relative">
										<Link to={setJessionId(`/product/details/${ productDetail.sku }`)} className="ie-thumbnail">
											<img src={imgSrc(productDetail.thumbnail)} className="rounded card-img" alt="..." />
											{ productDetail.hasOwnProperty("previouslyPurchased") &&
												<div className="position-absolute l-0 b-0 d-inline-flex mt-5">
													<FiRepeat className=" p-1 border rounded-circle border-orange text-orange bg-white" style={{zIndex: 5}} size="1.65em"/>
													<div className="b-2 bg-orange text-white ml-n2 pl-2 pr-1" style={{zIndex: 4, marginTop: '0.1rem', fontSize:'12px'}}>{label?.reOrder}</div>
												</div>
											}
										</Link>
										</div>
										<div className="form-check mt-3">
											<input className="form-check-input" type="checkbox" value="" id={productDetail.sku}
															defaultChecked={props.comparelist_skus && props.comparelist_skus.includes(productDetail.sku) ? "checked" : ""}
															onClick={(e) => {
																props.onClickAddToCompare(e, productDetail.sku, imgSrc(productDetail.thumbnail))
															}}/>
										<label className="form-check-label" for={productDetail.sku}>{label?.compare}</label>
										</div>
									</div>
									<div className="col-xs-12 col-md-9">
										<div className="">
											<div className="">
												<ul className="list-unstyled">
													<li>
														<div className="font-weight-600">{productDetail.customerSku ? (label?.yourSku) : (label?.fastenalPartNo)}:
															<Link className="default-nav-link ml-1" to={setJessionId(`/product/details/${productDetail.sku}`)}>
																{ productDetail.customerSku !== undefined ? `${productDetail.customerSku}(${productDetail.sku})`: productDetail.sku}
															</Link>
															<CustomCatalog productDetail ={productDetail} displayText={false}/>
														</div>
													</li>
													<li className="mt-2">
														<div className="mt-1">
															<ProductAvailability productEda= {productDetail.productEda} />
														</div>
													</li>
													<li className="mt-2">
														<div className="mt-2">
															{productDetail?.priceDisplayData?.length > 0 ?
																productDetail?.priceDisplayData.map((price, index) => {
																	return (
																		<div key={index}>
																			<div className={`text-${price.label === "Wholesale:" ? "black" :(
																				price.label === "Unit Price:" && index - 1 >= 0 && productDetail?.priceDisplayData[index - 1].label === "Wholesale:" ? "black" : "primary"
																			)} ${index > 0 ? "mt-1" : ""}`}>
                                                                                <span className="font-weight-600">
																					{price.label} {price.price} {price.uom ? "/" : ""} {price.uom}
                                                                                </span><br/>
																			</div>
																		</div>
																	)
																})
																:
																<span><span className="font-weight-bold">{label?.price}: </span>{label?.noPriceAvailable}</span>
															}
														</div>
													</li>
													<li className="mt-2">
														<div className="ml-auto d-flex">
															<input type="number" min="0" max="99999"
																className="form-control p-1 ecom-input-sm mr-2 ie-float-left"
																defaultValue="1"
																id={`selected_quantity_${productDetail.sku}`} onChange={(e) => { onChangeInputQuantity(e) }} onKeyDown={(e) => { onkeydownInputQuantity(e) }} />
															<div className="btn-group">
																<button type="button" className="btn btn-primary" onClick={(e) => {props.onClickAddToCart(e,productDetail, props, "Catalog View")}}>
																	<i className="fa fa-shopping-cart" aria-hidden="true"></i>
																</button>
																{(window.userLoggedIn && showAddToTemplate()) &&
																<AddToCartTemplate sku={productDetail.sku} />}
															</div>
														</div>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12 table-responsive	">
										<table>
											<tbody>
											{
												productDetail.manufacturer &&
												<tr>
													<td className="text-right align-baseline"><span
														className="font-weight-600">{label?.manufacturer}:</span>&nbsp;</td>
													<td dangerouslySetInnerHTML={{ __html: productDetail.manufacturerLabel}}></td>
												</tr>
											}
											{
												productDetail.brandName &&
												<tr>
													<td className="text-right align-baseline"><span
														className="font-weight-600">{label?.brand}:</span>&nbsp;</td>
													<td dangerouslySetInnerHTML={{ __html: productDetail.brandLabel}}></td>
												</tr>
											}
											{
												productDetail.categoryAttributes && productDetail.categoryAttributes.map((categoryAttribute, i) => {
													return (
														<tr>
															<td className="text-right w-50 align-baseline">
																<span className="font-weight-600 mr-1">{categoryAttribute.name}:</span>
															</td>
															<td dangerouslySetInnerHTML={{ __html: categoryAttribute.value}}></td>
														</tr>
													)
												})
											}
											{ productDetail.compliances?.length > 0 &&
												<tr>
													<td className="text-right">
														<span className="font-weight-bolder ml-1">{label?.compliance}:</span>
													</td>
													<td>
														{
															productDetail.compliances && productDetail.compliances.map((compliance, i) => {
																if((compliance.complianceName === "Restricted Product" && (compliance["Restriction Areas"] !== undefined)) || (compliance.complianceName !== 'Restricted Product')) return (
																	<span key={i} className="ml-1">
																		<img src={imgSrc(compliance.Image)} title={compliance["complianceNameLabel"]} alt={compliance["Alt Text"]} className={compliance.Image ? "" : "img-20x20"} className="img-20x20"/>
																	</span>
																)
															})
														}
													</td>
												</tr>
											}
											</tbody>
										</table>
										<div className="cb-pop-details-link mt-2">
											<Link to={setJessionId(`/product/details/${productDetail.sku}`)} className="btn btn-primary float-left mb-2">
												{label?.viewDetails}
											</Link>
										</div>
									</div>
								</div>
							</div>
						</Popover.Content>
					</Popover>
				</Overlay>
			}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		labels: state.commonReducer.pageLabels
	}
};

export default connect( mapStateToProps, null )(ProductPopover);