import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { AddToShoppingCart, imgSrc } from '../helpers/CommonScripts';
import { addToCart } from '../actions/cartActions';
import { postRequest } from '../apiCalls/ApiCall';
import Loader from './Spinner';
import {PRODUCT_SEARCH_URL} from '../constants/Constants';
import AddToCartTemplate from './AddToCartTemplate';
import ProductAvailability from './ProductAvailability';
import { onChangeInputQuantity } from './OnchangeInputQuantity';
import {onkeydownInputQuantity} from "./OnkeydownInputQuantity";

function Compare(props) {
    const label = props.labels
    const [productList, setproductList] = useState([]);
    const [categoryAttributeKeys, setCategoryAttributeKeys] = useState(["Type", "Inner Diameter","Application"]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let bodyContent = Object.assign({ "sku": props.sku });
        let arr = [];

        setLoading(true);
        postRequest(PRODUCT_SEARCH_URL, bodyContent).then((res) => {
            //to maintain order.
            res.productList.sort(function(a, b){
                return res.searchRequest.sku.indexOf(a.sku) - res.searchRequest.sku.indexOf(b.sku);
            });
            setproductList(res.productList);
            setLoading(false);

            if (res.productList && res.productList.length > 0) {
                res.productList.forEach((product) => {
                    if(product.categoryAttributes && product.categoryAttributes.length > 0) {
                        var nameArray = product.categoryAttributes.map(function (el) { return el.name; });
                        arr.push(nameArray);
                    }
                });

                var flatArray = arr.flat(1);
                var uniqArray = flatArray.filter((item, pos) => flatArray.indexOf(item) === pos);
                setCategoryAttributeKeys(uniqArray);
            }
        })
    }, [props]);

    const isHighlightClass = (category_attibute_name) =>{
        var isHighlightClassApply = true;
        for (var i = 0; i < productList.length; i++) {
            if (productList[i+1] !== undefined){
                var prd1 = productList[i].categoryAttributes &&  productList[i].categoryAttributes.length > 0 ? getCategoryAttributeVal(productList[i].categoryAttributes, category_attibute_name) : "-";
                var prd2 = productList[i+1].categoryAttributes && productList[i+1].categoryAttributes.length > 0 ? getCategoryAttributeVal(productList[i+1].categoryAttributes, category_attibute_name) : "-";
                if (prd1 === "-" && prd2 === "-"){
                    isHighlightClassApply = true;
                    return isHighlightClassApply;
                }else if(prd1 !== prd2){
                    isHighlightClassApply = true;
                    return isHighlightClassApply;
                }else{
                    isHighlightClassApply = false;
                }
            }
        }
        return isHighlightClassApply;
    };

    const getCategoryAttributeVal = (categoryAttributes, mapKey) =>{
        for (var i = 0; i < categoryAttributes.length; i++) {
            if (categoryAttributes[i].name === mapKey) {
                return categoryAttributes[i].value;
            }
        }
        return "-"
    };

    if (loading) return <Loader />

    return (
        <div className="row product-compare pt-1">
            <div className="w-100">
                <table className="table table-borderless mt-n2">
                    <tbody>
                    <tr>
                        <th></th>
                        {
                            productList && productList.map((product, i) => {
                                return (
                                    <td key={`img_${i}`}>
                                        <span>
                                        {
                                            product.isExpress !== undefined && product.isExpress === 'Y' ? <img src={imgSrc("/static/images/express-logo-small.png")} className="rounded border" alt="..." width="100px" height="100px" />
                                            : ''
                                        }
                                        </span>
                                        <div className="p-2 feco-thumbnail-size-90 border">
                                            {product.thumbnail ? (<img src={product.thumbnail} className="img-fluid" alt="..." />)
                                                : (<span className="product-compare-imgwrap" ><img src={imgSrc("/static/images/no_image.png")} alt="Fiber Disc" /></span>
                                                )}
                                        </div>
                                    </td>
                                )
                            })
                        }
                    </tr>
                    {productList && JSON.stringify(productList).includes("description") &&
                        <tr>
                            <th className="border-bottom align-baseline">{label?.description}</th>
                            {productList.map((product, i) => {
                                return (
                                    <td key={`dec_${i}`} className="border-bottom align-baseline" dangerouslySetInnerHTML={{__html: product.description}}></td>
                                )
                            })}
                        </tr>
                    }
                    {productList && JSON.stringify(productList).includes("sku") &&
                        <tr>
                            <th className='align-baseline'>{label?.sku}</th>
                            {productList.map((product, i) => {
                                return (
                                    <td className='align-baseline' key={`sku_${i}`}>
                                        <a href={`/product/details/${product.sku}`} rel="noopener noreferrer">
                                            {product.customerSku !== undefined ? `${product.customerSku}(${product.sku})` : product.sku}
                                        </a>
                                    </td>
                                )
                            })}
                        </tr>
                    }
                    {productList && JSON.stringify(productList).includes("manufacturerPartNo") &&
                        <tr>
                            <th className='align-baseline'>{label?.manufacturerPartNo}</th>
                            {productList.map((product, i) => {
                                return (
                                    <td className='align-baseline' key={`manufacturerPartNo_${i}`}>
                                        <span>{product.manufacturerPartNo}</span>
                                    </td>
                                )
                            })}
                        </tr>
                    }
                    {productList && JSON.stringify(productList).includes("unspscCode") &&
                        <tr>
                            <th className='align-baseline'>UNSPSC</th>
                            {productList.map((product, i) => {
                                return (
                                    <td className='align-baseline' key={`unspscCode_${i}`}>{product.unspscCode}</td>
                                )
                            })}
                        </tr>
                    }
                    {productList && JSON.stringify(productList).includes("manufacturer") &&
                        <tr>
                            <th className='align-baseline'>{label?.manufacturer}</th>
                            {productList.map((product, i) => {
                                return (
                                    <td className='align-baseline' key={`manufacturer_${i}`}>
                                        <span dangerouslySetInnerHTML={{__html: product.manufacturerLabel}}></span>
                                    </td>
                                )
                            })}
                        </tr>
                    }
                    {productList && JSON.stringify(productList).includes("brandName") &&
                        <tr>
                            <th className='align-baseline'>{label?.brand}</th>
                            {productList.map((product, i) => {
                                return (
                                    <td className='align-baseline' key={`brand_${i}`}>
                                        <span dangerouslySetInnerHTML={{__html: product.brandLabel}}></span>
                                    </td>
                                )
                            })}
                        </tr>
                    }
                    {productList && JSON.stringify(productList).includes("priceDisplayData") &&
                        <tr>
                            <th className='align-baseline'>{label?.price}</th>
                            {productList.map((product, i) => {
                                return (
                                    <td className='align-baseline' key={`price_${i}`}>
                                        {product.priceDisplayData && product.priceDisplayData.length > 0 ?
                                            product.priceDisplayData.map((price, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div className={`text-${price.label === "Wholesale:" ? "black" :(
                                                            price.label === "Unit Price:" && index - 1 >= 0 && product.priceDisplayData[index - 1].label === "Wholesale:" ? "black" : "primary"
                                                        )} ${index > 0 ? "mt-1" : ""}`}>
                                                            <span className="font-weight-600">{price.label}</span><br/><br/>
                                                            <span className="font-weight-bold">{price.price} / {price.uom}</span>
                                                        </div>
                                                        <br/>
                                                    </div>
                                                )
                                            })
                                            :
                                            <span><span className="font-weight-bold">{label?.price}: </span>{label?.noPriceAvailable}</span>
                                        }
                                    </td>
                                )
                            })}
                        </tr>
                    }
                    {productList && JSON.stringify(productList).includes("productEda") &&
                        <tr>
                            <th className='align-baseline'>{label?.availability}</th>
                            {productList.map((product, i) => {
                                return (
                                    <td className='align-baseline' key={`availability_${i}`}>
                                        <ProductAvailability productEda={product.productEda} />
                                    </td>
                                )
                            })}
                        </tr>
                    }
                    <tr>
                        <th className='align-baseline'>QTY</th>
                        {productList && productList.map((product, i) => {
                            return (
                                <td className='align-baseline' key={`qty_${i}`}>
                                    <div className="row pl-3 feco-compare-popup-td-size">
                                        <div className="btn-group">
                                            <input type="number" min="0" max="99999" className="form-control p-1 ecom-input-sm mr-2" id={`selected_quantity_${product.sku}`} defaultValue="1" onChange={(e) => { onChangeInputQuantity(e) }} onKeyDown={(e) => { onkeydownInputQuantity(e) }} />
                                            <button type="button" className="btn btn-primary ecom-button ecom-button-highlight" onClick={(e) => { AddToShoppingCart(e, product, props, "Compare") }}>
                                                <i className="fa fa-shopping-cart" aria-hidden="true"/> {label?.add}
                                            </button>
                                            <AddToCartTemplate sku={product.sku}  />
                                        </div>
                                    </div>
                                </td>
                            )
                        })}
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className="category-attributes-overflow flex-fill" >
                <div className="w-100">
                    <table className="table">
                        <tbody>
                        {productList && productList.map(pd => pd.compliances.length > 0).includes(true) &&
                            <tr>
                                <th className='align-baseline' style={{width: "12%"}}>{label?.compliance}</th>
                                {
                                    productList.map((product, ind) => {
                                        return (
                                            <td className='align-baseline' key={`dec_${ind}`}>
                                                <div className="feco-compare-popup-td-size d-flex">
                                                    {
                                                        product.compliances && product.compliances.map((compliance, i) => {
                                                            return (
                                                                <>{((compliance.complianceName === "Restricted Product" && (compliance["Restriction Areas"] !== undefined)) || (compliance.complianceName !== 'Restricted Product')) &&
                                                                    <i className="d-flex align-items-center feco-icon-compliance-w-30 border p-1">
                                                                        <img src={imgSrc(compliance.Image)} alt={compliance["Alt Text"]} title={compliance["complianceNameLabel"]} className={`mr-1 qty_${compliance.Image ? "" : "img-20x20"}`} className="img-20x20"/>
                                                                    </i>}
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        }
                        {
                            categoryAttributeKeys.length > 0 && categoryAttributeKeys.map((attribute_key, i) => {
                                isHighlightClass(attribute_key);
                                return(
                                    <tr className={isHighlightClass(attribute_key) ? "highlight":""} key={`tr-${i}`}>
                                        <th className='align-baseline'>{attribute_key}</th>
                                        {
                                            productList && productList.map((product, i) => {
                                                return (
                                                    <td className='align-baseline' key={`categoryAttributes_${i}`} dangerouslySetInnerHTML={{__html:  product.categoryAttributes && product.categoryAttributes.length > 0 && getCategoryAttributeVal(product.categoryAttributes, attribute_key)}}>
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                        {productList && JSON.stringify(productList).includes("uom") &&
                            <tr>
                                <th className='align-baseline'>UOM</th>
                                {
                                    productList.map((product, ind) => {
                                        return (
                                            <td className='align-baseline' key={`dec_${ind}`} style={{width: "20%"}}>
                                                {
                                                    product.uom && product.uom.message ? product.uom.message : '-'
                                                }
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
  return {
    labels: state.commonReducer.pageLabels
  }
};
const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (shoppingCartSummary) => { dispatch(addToCart(shoppingCartSummary)) },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Compare);