import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setJessionId } from '../helpers/CommonScripts';
import { sendCategoryClickAnalytics } from '../helpers/CommonScripts';

function Menu(props) {
	const [categoryMenu, setCategoryMenu] = useState([])

	useEffect(() => {
		setCategoryMenu(props.categoryMenu);
	}, [props])
	const categoryMenuList = [] //we create it, then we'll fill it
	if (categoryMenu) {
		const length = categoryMenu.length;
		for (let i = 0; i < length; i = i+11) {
			const value = categoryMenu.slice(i, i + 11 > length ? length : i + 11 )
			if (!value) continue //avoid adding "undefined" values
			categoryMenuList.push(value)
		}
	}
	// const menuWidth = (categoryMenu ? (Math.ceil(categoryMenu.length/ 11))*221 : 0) + "px";
	
	return (
		<div className="feco-header-dropdown feco-shop-category mega-menu mega-menu-wrap js-mega-menu-wrap" id="productMenu"
				 style={{width : 'max-content'}}>
			<div className="row flex-column flex-md-row">
				{
					categoryMenuList.map((categoryList, index) => {
						return (
							<div className={`flex-wrap  pl-3  pr-3 category-menu-wrap ie-category-menu-wrap js-product-column ${index >= (categoryMenuList.length -1) ? "" : "border-right"}`} key={index}>
								<ul className="list-unstyled nav flex-column mega-menu-list" role="menu" aria-labelledby="dropdownMenu">
									{
										categoryList.map((category, i) => {
											return (
												<li className="category-menu-list nav-item" key={i}>
													<Link onClick={() => {sendCategoryClickAnalytics(category, (index*categoryList.length)+i+1,"Category Page")}} className="nav-link pt-1 pb-1" to={setJessionId(`/product/${category.categoryLevelOne}?fsi=1${category.categoryId !== undefined ? '&categoryId='+category.categoryId:""}`)}>{ category.categoryLabelOne}</Link>
												</li>
											)
										})
									}
								</ul>
							</div>
						)
					})
				}
			</div>
		</div>
	)
    
}

const mapStateToProps = (state) => {
	return {
    	categoryMenu: state.commonReducer.categoryMenuList
	}
};
export default connect( mapStateToProps, null )(Menu);