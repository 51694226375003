import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'classlist-polyfill';
import 'polyfill-array-includes';
import 'element-remove';
import React from 'react';
import ReactDOM from 'react-dom';
import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/css/ecom-styles.css';
import App from './App';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import cartReducer from './reducers/cartReducer';
import compareReducer from './reducers/compareReducer';
import commonReducer from './reducers/commonReducer';
import reportWebVitals from './reportWebVitals';
import * as $ from 'jquery';

window.jQuery = window.$ = $

const rootReducer = combineReducers({
  cartReducer,
  compareReducer,
  commonReducer
});
const store = createStore(rootReducer);
const jsession = window.location.pathname.indexOf("jsessionid") > 0 ? window.location.pathname.split(";").slice(-1)[0] : ""
if (jsession && jsession !== undefined) {
  window.sessionStorage.setItem("jsessionid", jsession);
}
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}><App /></Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();