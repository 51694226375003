import { ADD_TO_TEMPLATE_LIST, CATEGORY_MENU_LIST, DETECT_WINDOW_SIZE, PAGE_LABELS, VIEW_PREFERENCE, CLOSE_SUGGESTIONS } from './action-types/common-actions';

export const addToTemplateList = (templateList) => {
    return {
        type: ADD_TO_TEMPLATE_LIST,
        templateList
    }
}

export const categoryMenuList = (menuList) => {
    return {
        type: CATEGORY_MENU_LIST,
        menuList
    }
}

export const detectWindowSize = (isMobileView) => {
    return {
        type: DETECT_WINDOW_SIZE,
        isMobileView
    }
}

export const pageLabels = (labelList) => {
    return {
        type: PAGE_LABELS,
        labelList
    }
}

export const viewPreference = (categoryId, preferenceView) => {
    return {
        type: VIEW_PREFERENCE,
        categoryId,
        preferenceView
    }
}

export const closeSuggestions = (suggestionsClose) => {
    return {
        type: CLOSE_SUGGESTIONS,
        suggestionsClose,
    };
};