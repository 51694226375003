function ProductAvailability({ productEda, statusCode}) {
    const title = productEda && productEda.title;
    const availabilityMessage = productEda && productEda.availabilityMessage;
    const colorName = (productEda !== undefined && productEda.colorStatus) ?  ["0", "2"].includes(productEda.colorStatus) ? 'danger' : 'warning' : 'success'
    const iconType = colorName === 'success' ? 'check' : 'info'
    return (
        <>
            {availabilityMessage ?
                <>
                    {(statusCode === undefined) ?
                        <span title={title} className={`d-flex align-items-center mr-1 border item-availability  border-${colorName}`}>
                            <i className={`fas fa-${iconType}-circle item-availability-icon mr-1 bg-${colorName}  border-${colorName}`} aria-hidden="true"></i>
                            <span className={`flex-fill text-center text-${colorName === 'warning' ? "black" : colorName}`}>{availabilityMessage}</span>
                        </span>
                        :
                        <span title={title}><i className={`fas fa-circle pr-1 text-${colorName}`} aria-hidden="true"></i>
                            {availabilityMessage}
                        </span>
                    }
                </>
                : "Loading..."
            }
        </>
    )
}

export default ProductAvailability;
// Response From Backend
// "Out of Stock" = 0
// ["Available Inventory", "Ships from Manuf."] = 1
// "Contact Branch" = 2
