import { ADD_TO_COMPARE, REMOVE_PRODUCT_COMPARE, REMOVE_ALL_PRODUCTS_FROM_COMPARE } from '../actions/action-types/compare-actions';
import {getLocalStorageItem, setLocalStorageItem} from '../helpers/Helpers'

const initCompare = {
    compareItems: getLocalStorageItem('compareItems') ? getLocalStorageItem('compareItems')  : []
};
let  compareItems = initCompare.compareItems;

function compareReducer(state = initCompare, action) {
    if (action.type === ADD_TO_COMPARE) {
        const product = { sku: action.sku, image: action.image };
        compareItems.push(product);
        setLocalStorageItem('compareItems', compareItems, 3600000);
        return {
            ...state,
            compareItems: [...compareItems]
        }
    }

    if (action.type === REMOVE_PRODUCT_COMPARE) {
        let newProducts = state.compareItems.filter(x => x.sku !== action.sku)
        compareItems = newProducts;
        setLocalStorageItem('compareItems', compareItems, 3600000);
        return {
            ...state,
            compareItems: newProducts
        }
    }

    if (action.type === REMOVE_ALL_PRODUCTS_FROM_COMPARE) {
        compareItems = [];
        localStorage.removeItem('compareItems');
        return {
            ...state,
            compareItems: compareItems
        }
    }else {
        return state
    }
}

export default compareReducer;