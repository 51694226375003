import { useEffect, useRef, useState } from 'react'

export const useRect = () => {
	const rectRef = useRef();
	const [rect, setRect] = useState(null);
	const set = () =>
		setRect(rectRef && rectRef.current ? rectRef.current.getBoundingClientRect() : {});

	useEffect(() => {
		set();
		window.addEventListener('resize', set);
		return () => window.removeEventListener('resize', set);
	}, []);

	return {rect, rectRef};
};