import React, {useEffect} from "react";
import SubSection from "./SubSection";
import useStickyHeader from "./UseStickyHeader";
import {useRect} from "./useRect";

function Section(props) {
  const section = props.section;
  const{rect, rectRef} = useRect();
  const {sectionRef, isSticky, minTopRef, bottomDiffRef, headerRef, fixedHeaderTop,pageHeaderHeightRef} = useStickyHeader();

  function setBottomDiffRef(value) {
    bottomDiffRef.current = value;
  }

  useEffect(() => {
    headerRef.current = rectRef.current;
    minTopRef.current = pageHeaderHeightRef.current;
  }, [rectRef, pageHeaderHeightRef]);

  return (
    <div className="section-header" ref={sectionRef}>
      <div className={"row p-2" + (isSticky ? " frozen" : "")} ref={rectRef}
        style={rect ? { marginBottom: -rect.height, width: rect.width, top: fixedHeaderTop ? fixedHeaderTop : "" } : {}}>
        <div className="col-xl-12 pl-1">
          {
            section.rowHeader && section.rowHeader.map((header, headerIndex) => {
              return (
                <span key={headerIndex}>
                  <span className="cb-ui-label pr-1" dangerouslySetInnerHTML={{ __html: header.name }}></span>:
                  <span className="cb-ui-value pl-1" dangerouslySetInnerHTML={{ __html: header.value }}></span><br />
                </span>
              )
            })
          }
        </div>
      </div>
      <div style={rect ? { paddingTop: rect.height } : {}}></div>
      <div className="row sub-section-header">
        {
          section.cbSubsactions && section.cbSubsactions.map((subsection, subSectionIndex) => {
            return (
              <SubSection key={subSectionIndex} subsection={subsection}
                columnHeaders={props.columnHeaders}
                handleClickShowProductDetails={props.handleClickShowProductDetails}
                calculatedTop={rect ? pageHeaderHeightRef.current + rect.height : 0}
                setBottomDiffRef={setBottomDiffRef}/>
            )
          })
        }
      </div>
    </div>
  )
}

export default Section;