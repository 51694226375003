import $ from "jquery";
import { connect } from 'react-redux';
import { imgSrc, setJessionId } from '../helpers/CommonScripts';
import { Link } from 'react-router-dom';
import Language from "./Languages";
import {PAGE_LABELS as label} from "../constants/Labels";

function Header(props) {
    const cmsdata = JSON.parse(sessionStorage.getItem("cmsData"));
    const locales = props.data?.headerDisplayedLocales;
    const localeMessage = props.data?.displayName;
    const headerData = cmsdata?.header;
    const googleData = props.data?.googleData;
    const headerPageLinks = headerData?.pages;
    const logo = headerData?.logo;
    const accountLogo = headerData?.customerLogo;
    const landingPageUrl = window.landingPageUrl;
    const projectVersion = props.data?.projectVersion;
    const allowGoogleTagManager = googleData?.allowGoogleTagManager;
    const googleTagManagerContainerId = googleData?.googleTagManagerContainerId;
    const allowGoogleAnalytics = googleData?.allowGoogleAnalytics;
    const customerId = googleData?.customerId;
    const userId = googleData?.userId;
    const webAccountId = googleData?.webAccountId;
    const accountNumber = googleData?.accountNumber;
    const allowMonetateSynchronous = googleData?.allowMonetateSynchronous;
    const allowMonetateAsynchronous = googleData?.allowMonetateAsynchronous;
    const versionProject = `var PROJECT_VERSION = '${projectVersion}'`;
    const googleTagManager = `(function(w, d, s, l, i){
        w[l] = w[l] || [];
        w[l].push(
            {'gtm.start' : new Date().getTime(), event : 'gtm.js'}
        );
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l =' + l : '';
        j.async = true;
        j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j,f);

    })(window,document,'script','dataLayer','${googleTagManagerContainerId}')`;
    const googleAnalytics = `window.dataLayer = window.dataLayer || [];
        dataLayer.push({'event': 'customerIdEvent',
                        'customerId': '${customerId}'});`
    const googleAnalyticsForUserInfo = `window.dataLayer = window.dataLayer || [];
        dataLayer.push({
                        'uid': '${userId}',
                        'wid':'${webAccountId}',
                        'an':'${accountNumber}'
                        });`;
    const monetateSynchronous = `var monetateT = new Date().getTime();
        (function() {
            var p = document.location.protocol;
            if (p == "http:" || p == "https:") {
                var m = document.createElement("script"); m.type = "text/javascript"; m.src = (p == "https:" ? "https://s" : "http://") + "e.monetate.net/js/2/a-d83a5ecb/p/fastenal.com/entry.js";
                var e = document.createElement("div"); e.appendChild(m); document.write(e.innerHTML);
            }
        })();`;
    const monetateAsynchronous = `var monetateT = new Date().getTime();
        (function() {
            var p = document.location.protocol;
            if (p == "http:" || p == "https:") {
                var m = document.createElement('script'); m.type = 'text/javascript'; m.async = true; m.src = (p == "https:" ? "https://s" : "http://") + "b.monetate.net/js/1/a-d83a5ecb/p/fastenal.com/" + Math.floor((monetateT + 2504011) / 3600000) + "/g";
                var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(m, s);
            }
        })();`;
    const existingProjectVersionScript = document.getElementById('projectVersion');
    if (!existingProjectVersionScript && projectVersion) {
        const script = document.createElement('script');
        script.text = versionProject;
        script.id = 'projectVersion';
        document.getElementsByTagName('head')[0].appendChild(script);
    }
    const existingGoogleTagManagerScript = document.getElementById('googleTagManager');
    if (!existingGoogleTagManagerScript && allowGoogleTagManager) {
        const script = document.createElement('script');
        script.text = googleTagManager;
        script.id = 'googleTagManager';
        document.getElementsByTagName('head')[0].appendChild(script);
    }
    const existingGoogleAnalyticsScript = document.getElementById('googleAnalytics');
    if (!existingGoogleAnalyticsScript && allowGoogleAnalytics) {
        const script = document.createElement('script');
        script.text = googleAnalytics;
        script.id = 'googleAnalytics';
        document.getElementsByTagName('head')[0].appendChild(script);
    }
    const googleAnalyticsForUserInfoScript = document.getElementById('googleAnalyticsForUserInfo');
    if (!googleAnalyticsForUserInfoScript && allowGoogleAnalytics) {
        const script = document.createElement('script');
        script.text = googleAnalyticsForUserInfo;
        script.id = 'googleAnalyticsForUserInfo';
        document.getElementsByTagName('head')[0].appendChild(script);
    }
    const existingMonetateSynchronousScript = document.getElementById('monetateSynchronous');
    if (!existingMonetateSynchronousScript && allowMonetateSynchronous) {
        const script = document.createElement('script');
        script.text = monetateSynchronous;
        script.id = 'monetateSynchronous';
        document.getElementsByTagName('head')[0].appendChild(script);
    }
    const existingMonetateAsynchronousScript = document.getElementById('monetateAsynchronous');
    if (!existingMonetateAsynchronousScript && allowMonetateAsynchronous) {
        const script = document.createElement('script');
        script.text = monetateAsynchronous;
        script.id = 'monetateAsynchronous';
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    function defaultHeader() {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary justify-content-right">
                <div className="container-fluid flex-fill d-flex ie11-container-width">
                    <Link className="navbar-brand feco-logo ecom-logo" to={setJessionId(landingPageUrl)}>
                        <img src={imgSrc(logo)} className="img-fluid" alt="Fastenal Logo"/>
                    </Link>
                    <div className="collapse navbar-collapse mt-2 logo-header ie-topnav-no-collapse top-navbar-mobile"
                         style={props.activeNavLink == "headerMenu" ? {display: "block"} : {}} onClick={() => props.setActiveNavLink('')}>
                        <ul className="navbar-nav ml-auto ie-top-nav align-items-stretch align-items-md-stretch align-items-lg-center">
                            {!props.isMobileView && <Language locales={locales}/>}
                            {
                                headerPageLinks && headerPageLinks.map((pageLink, index) => {
                                    return (
                                        <li className="nav-item" key={index}>
                                            <a className="nav-link position-relative feco-focus-orange" id={`${pageLink.title}-${index}`} href={`${pageLink.link}`} target={pageLink.target}>{pageLink.title}</a>
                                        </li>
                                    )
                                })
                            }
                            {accountLogo && accountLogo !== undefined && <li id="fc-logo" className="left js-desktop">
                                <img className="border customer-logo d-none d-lg-block" src={setJessionId(imgSrc(accountLogo))} alt={label?.accountLogo}/>
                            </li>}
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }

    if(logo && logo !== undefined)
        return defaultHeader();
    else return ""
}

const mapStateToProps = (state) => {
  return {
	isMobileView: state.commonReducer.isMobileView
  }
};

export default connect( mapStateToProps, null )(Header);